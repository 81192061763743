import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addForumSchema } from "../../utils/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import S3FileUpload from "react-s3";
import { config } from "../../utils";
import { Progress } from "react-sweet-progress";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { addQuestion, getAllSkillList, getQuestionDetails } from "../slice";
import Loader from "../component/loader/Loader";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
function AddForum() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const loading = useSelector((store) => store.loading);
  const skill_list = useSelector((store) => store.getAllSkillList);
  const typeaheadRef = useRef(null);

  const {
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: yupResolver(addForumSchema),
    shouldFocusError: true,
  });

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const onSubmit = (data) => {
    console.log(data);

    let newArr = data?.tags?.map((item) => item.id.toString());

    dispatch(
      addQuestion({
        first_name: data?.first_name,
        last_name: data?.last_name,
        image: data?.user_details?.image,
        date: moment(data?.date).format("yyyy-mm-dd"),
        title: data?.title,
        description: data?.description,
        tagged_skills: JSON.stringify(newArr),
        link: data?.link,
        questionId: id ? id : "",
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        navigate("/manage-forum", { replace: true });
      } else {
        toast.success(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getAllSkillList());
  }, []);

  const handleTypeaheadValues = (arr) => {
    let list = getValues("tags") ? [...getValues("tags")] : [];

    let isIncluded = list.findIndex((item) => item?.id === arr[0].id);

    if (isIncluded === -1) {
      setValue("tags", [...list, ...arr]);
    }
  };

  const getDetails = useCallback(() => {
    dispatch(getQuestionDetails({ questionId: id })).then((res) => {
      if (res?.payload?.code === 1) {
        console.log(res);
        let data = res?.payload?.data?.question;
        reset({
          image: data?.user_details?.image,
          first_name: data?.user_details?.first_name,
          last_name: data?.user_details?.last_name,
          title: data?.title,
          date: moment(data?.createdAt).format("YYYY-MM-DD"),
          tags: data?.tagged_skills.map((item) => item.skill_details),
          description: data?.description,
        });
      }
    });
  }, [dispatch, id, reset]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>{id ? "Edit Forum" : "Add Forum"}</h4>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-sm-12">
            <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="form-group text-center">
                    <div className="upload-btn-wrapper">
                      <button className="btn">
                        {time > 0 && isActive ? (
                          <Progress type="circle" width={80} percent={time} />
                        ) : (
                          <img
                            src={
                              getValues("image")
                                ? getValues("image")
                                : "assets/images/upload-img.png"
                            }
                            alt=""
                          />
                        )}

                        {time > 0 && isActive ? null : (
                          <div className="camera-icon">
                            <i className="fa fa-camera"></i>
                          </div>
                        )}
                      </button>
                      <input
                        type="file"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            setIsActive(true);
                            S3FileUpload.uploadFile(e.target.files[0], config)
                              .then((data) => {
                                setValue("image", data.location);
                                setTime(100);
                                setIsActive(true);
                                setTimeout(() => {
                                  setTime(0);
                                  setIsActive(false);
                                }, 3000);
                              })
                              .catch((data) => console.log(data));
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="First Name"
                        {...register("first_name")}
                      />
                      <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {errors.first_name && (
                    <span className="text-danger">
                      {errors.first_name.message}
                    </span>
                  )}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Last Name"
                        {...register("last_name")}
                      />
                      <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {errors.last_name && (
                    <span className="text-danger">
                      {errors.last_name.message}
                    </span>
                  )}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Title"
                        {...register("title")}
                      />
                      <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {errors.title && (
                    <span className="text-danger">{errors.title.message}</span>
                  )}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="date"
                        className="form-control pl-5"
                        placeholder="Date"
                        {...register("date")}
                      />
                      {/* <Controller
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            className="form-control pl-5"
                            selected={value}
                            onChange={(date) => {
                              console.log(date);
                              onChange(date);
                            }}
                            placeholderText="Start Year"
                            dateFormat="yyyy-MM-dd"
                          />
                        )}
                        control={control}
                        value="selected"
                        name={`date`}
                        defaultValue={null}
                      />
                      <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      /> */}
                    </div>
                  </div>
                  {errors.date && (
                    <span className="text-danger">{errors.date.message}</span>
                  )}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <Typeahead
                        id="tags"
                        ref={typeaheadRef}
                        placeholder="Add Tags"
                        className="form-control pl-5 "
                        onChange={(selected) => {
                          console.log(selected);
                          if (selected.length > 0) {
                            typeaheadRef.current.clear();
                            handleTypeaheadValues(selected);
                          }
                        }}
                        labelKey={(option) => `${option.title}`}
                        options={skill_list || undefined}
                      />

                      <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {errors.date && (
                    <span className="text-danger">{errors.date.message}</span>
                  )}
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 mb-4 tagsCloseWrapper ">
                  {watch("tags")?.length > 0 &&
                    watch("tags")?.map((item, i) => {
                      return (
                        <span className="checkbox position-relative" key={i}>
                          {item.title}
                          <img
                            src="assets/images/closeIcon.png"
                            alt=""
                            className="tagsCloseIcon"
                            onClick={() => {
                              let arr = [...watch("tags")];
                              arr.splice(i, 1);
                              setValue("tags", arr);
                            }}
                          />
                        </span>
                      );
                    })}
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <textarea
                        className="form-control pl-5 pt-3 textArea"
                        placeholder="Description"
                        {...register("description")}
                      ></textarea>
                      <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {
                    <span className="text-danger">
                      {errors?.description?.message}
                    </span>
                  }
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5 "
                        placeholder="Link"
                        {...register("link")}
                      ></input>
                      <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {<span className="text-danger">{errors?.link?.message}</span>}
                </div>

                <div className="col-sm-12 col-md-12 text-center">
                  <button type="submit" className="btn btn-save">
                    {loading ? <Loader /> : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddForum;
