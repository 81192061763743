import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Loader from "../component/loader/Loader";
import {
  activateDeactivateQuestion,
  getAllCollaborationList,
  removeData,
} from "../slice";
import Paginations from "../component/pagination/Pagination";

function ManageCollaborations() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabName, setTabName] = useState("active");
  const [searchValue, setSearchValue] = useState("");
  const list = useSelector((store) => store.collaborationsLists);
  const total_active_collaborations = useSelector(
    (store) => store.total_active_collaborations
  );
  const total_inactive_collaborations = useSelector(
    (store) => store.total_inactive_collaborations
  );
  // const personasDetail = useSelector((store) => store.personasDetail);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(1);
  const [show, setShow] = useState(false);

  const getList = useCallback(() => {
    setLoading(true);

    dispatch(
        getAllCollaborationList({page: page, limit: 10, status, search: searchValue})
    ).then((res) => {
      // if(res?.payload?.code === 1) {
      //   toast.success(res?.payload?.message);
      // } else {
      //   toast.error(res?.payload?.message);
      // }
      setLoading(false);
    });
  }, [dispatch, page, status, searchValue]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleToggle = (data) => {
    dispatch(
      activateDeactivateQuestion({
        data: data,
        params: {
          page: page,
          limit: 10,
          status,
          search: searchValue,
        },
      })
    ).then((res) => {
      if(res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        getList();
      } else {
        toast.success(res?.payload?.message);
      }
    });
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Manage Collaborations </h4>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Student Name and Forum Title.."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </form>
                {/* <Link to="/add-forum" className="btn add-new">
                  +
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-7">
                {/* <ul
                  className="nav nav-pills group-nav mb-3 mt-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "active" ? "nav-link active" : "nav-link"
                      }
                      id="pills-active-skills-tab"
                      type="button"
                      role="tab"
                      aria-controls="pills-active-skills"
                      aria-selected="true"
                      onClick={() => {
                        setStatus(1);
                        setPage(1);
                        setTabName("active");
                      }}
                    >
                      Active
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "inactive" ? "nav-link active" : "nav-link"
                      }
                      id="pills-inactive-skills-tab"
                      onClick={() => {
                        setStatus(0);
                        setPage(1);
                        setTabName("inactive");
                      }}
                      type="button"
                      role="tab"
                      aria-controls="pills-inactive-skills"
                      aria-selected="false"
                    >
                      Inactive
                    </button>
                  </li>
                </ul> */}
              </div>
              <div className="col-sm-12">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={
                      tabName === "active"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-active-skills"
                    role="tabpanel"
                    aria-labelledby="pills-active-skills-tab"
                  >
                    <div className="table-responsive text-center">
                      <table className="table storetable">
                        <thead className="text-center">
                          <tr>
                            <th scope="col">Image</th>
                            <th scope="col">Title</th>
                            <th scope="col">Actions</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>

                        <tbody>
                          {list && list?.length > 0 ? (
                            list?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={
                                      item?.image?.includes(
                                        "http"
                                      )
                                        ? item?.image
                                        : item?.image
                                          ? item?.image
                                          : "assets/images/profile1.png"
                                    }
                                    alt=""
                                    className="user-image"
                                  />
                                </td>
                                <td>
                                  <span className="ellips-2">
                                    {item?.title || "NA"}
                                  </span>
                                </td>
                                

                                <td>
                                  <div
                                    className="d-flex justify-content-center action"
                                    style={{
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={item?.isActive}
                                        onChange={() => {
                                          handleToggle({
                                            itemId: item.id,
                                            status: !item.isActive,
                                          });
                                        }}
                                      />
                                      <span className="slider round"></span>
                                    </label> */}
                                    <Link
                                      to={`/edit-collaboration/${item?.id}`}
                                      className="btn EditApointment"
                                    >
                                      <img
                                        src="assets/images/edit.png"
                                        alt=""
                                        width="20px"
                                      />
                                    </Link>
                                    {/* <button
                                      className="btn"
                                      onClick={() => {
                                        dispatch(
                                          removeData({
                                            key: "questionDetail",
                                          })
                                        );
                                        navigate(`/forum/${item?.id}`);
                                      }}
                                    >
                                      <img
                                        src="assets/images/view.png"
                                        // className="sha-up"
                                        alt=""
                                      />
                                    </button> */}

                                    {/* <button
                                        className="btn"
                                        onClick={() => {
                                          //   setDelId(item.id);
                                          //   setShow(!show);
                                          //   setDelName(item?.first_name);
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                        />
                                      </button> */}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10}>No Data Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {list?.length > 0 && searchValue === "" && (
                        <div className="col-sm-12">
                          <Paginations
                            page={page}
                            total={
                                list.length > 0? list.length:0
                                // total_active_collaborations && total_active_collaborations
                            }
                            limit={10}
                            handlePage={(val) => {
                              setPage(val);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      tabName === "inactive"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-inactive-skills"
                    role="tabpanel"
                    aria-labelledby="pills-inactive-skills-tab"
                  >
                    <div className="table-responsive text-center">
                      <table className="table storetable">
                        <thead className="text-center">
                          <tr>
                            <th scope="col">Images</th>
                            <th scope="col">Title</th>
                            <th scope="col">Actions</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>

                        <tbody>
                          {list && list?.length > 0 ? (
                            list?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={
                                      item?.image?.includes(
                                        "http"
                                      )
                                        ? item?.image
                                        : item?.image
                                          ? item?.image
                                          : "assets/images/profile1.png"
                                    }
                                    alt=""
                                    className="user-image"
                                  />
                                </td>
                               
                                <td>
                                  <span className="ellips-2">
                                    {item?.title || "NA"}
                                  </span>
                                </td>

                                <td>
                                  <div
                                    className="d-flex justify-content-center action"
                                    style={{
                                      alignItems: "baseline",
                                    }}
                                  >
                                    {/* <button className="btn">
                              <img
                                src="assets/images/application-rejected.png"
                                alt=""
                              />
                            </button> */}
                                    <span>
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          checked={item?.isActive}
                                          onChange={() => {
                                            handleToggle({
                                                itemId: item.id,
                                              status: !item.isActive,
                                            });
                                          }}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </span>
                                    <Link
                                      to={`/edit-forum/${item?.id}`}
                                      className="btn EditApointment"
                                    >
                                      <img
                                        src="assets/images/edit.png"
                                        alt=""
                                        width="20px"
                                      />
                                    </Link>
                                    {/* <button
                                      className="btn"
                                      onClick={() => {
                                        dispatch(
                                          removeData({
                                            key: "questionDetail",
                                          })
                                        );
                                        navigate(`/forum/${item?.id}`);
                                      }}
                                    >
                                      <img
                                        src="assets/images/view.png"
                                        // className="sha-up"
                                        alt=""
                                      />
                                    </button> */}

                                    {/* <button
                                        className="btn"
                                        onClick={() => {
                                          //   setDelId(item.id);
                                          //   setShow(!show);
                                          //   setDelName(item?.first_name);
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                        />
                                      </button> */}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10}>No Data Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {list?.length > 0 && searchValue === "" && (
                        <div className="col-sm-12">
                          <Paginations
                            page={page}
                            total={
                                total_inactive_collaborations && total_inactive_collaborations
                            }
                            limit={10}
                            handlePage={(val) => {
                              setPage(val);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default ManageCollaborations;
