import React from "react";
import { Modal } from "react-bootstrap";

function DeleteItems({ show, handleShow, handleDelete }) {
  return (
    <Modal show={show} onHide={handleShow}>
      <Modal.Header>Delete</Modal.Header>
      <Modal.Body>Are you sure you want to delete this?</Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => {
            handleDelete();
            handleShow();
          }}
          className="btn btn-primary"
        >
          Delete
        </button>
        <button onClick={handleShow} className="btn btn-danger">
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteItems;
