import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Paginations from "../component/pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../component/loader/Loader";
import { toast } from "react-toastify";
import { getCertificationDetails } from "../slice";

function ViewCertification() {
  const dispatch = useDispatch();
  const { id } = useParams();
  console.log("id ", id);
  const [activePage, setActivePage] = useState(1);
  // const total = useSelector((store) => store.total_skill);
  // const total_active_skill = useSelector((store) => store.total_active_skill);
  // const total_inactive_skill = useSelector(
  //   (store) => store.total_inactive_skill
  // );
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [certName, setCertName] = useState("");
  const [loading, setLoading] = useState(false);


  const getlist = useCallback(async () => {
    setLoading(true);

    dispatch(getCertificationDetails({ certificateId: id })).then((res) => {
        console.log("response" ,res);
        if(res?.payload?.code === 1){
          setLoading(false)
          let data = res?.payload?.data?.certification_details;
          console.log("data ** ", data);
          setCertName(data?.title)
          console.log("data *222* ", data?.certification_user_log);
          setData([...data?.certification_user_log])
        }
      });
  }, [dispatch, searchValue, page]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlist();
    }
    return () => {
      mounted = false;
    };
  }, [getlist]);

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Certification Details</h4>
            </div>
          </div>
        </div>
        <div className="row">

          <div className="col-sm-12">
            {loading ? (
              <Loader />
            ) : (
              <div className="table-responsive text-center">
                    <table className="table storetable">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">S.No.</th>
                          <th scope="col">Certification Name</th>
                          <th scope="col">User Name</th>
                          <th scope="col">Visit Count</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>

                      <tbody>
                        {data && data?.length > 0 ? (
                          data?.map((item, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{certName}</td>
                              <td>{item?.userDetails?.first_name} {item?.userDetails?.last_name}</td>
                              <td>{item?.visit_count}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {/* {data?.length > 0 && searchValue.length === 0 ? (
                      <div className="col-sm-12">
                        <Paginations
                          page={page}
                          total={total_active_skill}
                          handlePage={(val) => {
                            setPage(val);
                          }}
                          limit={10}
                        />
                      </div>
                    ) : null} */}
                  </div>
            )}
          </div>
        </div>
      </div>
    </article>
  );
}

export default ViewCertification;
