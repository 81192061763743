import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../component/loader/Loader";
import Paginations from "../component/pagination/Pagination";
import { moment } from "moment";
import { changeSubAdminStatus, deleteSubAdmin, subAdminList } from "../slice";
import { toast } from "react-toastify";
import DeleteModal from "../component/modal/DeleteModal";

function ManageSubAdmin() {
  const navigate = useNavigate();
  const [delName, setDelName] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState("active");
  const [searchValue, setSearchValue] = useState("");
  const list = useSelector((store) => store.sub_admin_list);
  const total_active_sub_admin = useSelector(
    (store) => store.total_active_sub_admin
  );
  const total_inactive_sub_admin = useSelector(
    (store) => store.total_inactive_sub_admin
  );
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(1);
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(
      subAdminList({
        page: page,
        search: searchValue,
        limit: 10,
        status: status,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, page, searchValue, status]);

  const confirmDelete = () => {
    dispatch(
      deleteSubAdmin({
        id: delId,
        params: { page: page, search: searchValue, limit: 10, status: status },
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const handleToggle = (id, stat) => {
    dispatch(
      changeSubAdminStatus({
        data: { sub_admin_id: id, status: stat },
        params: {
          page: page,
          search: searchValue,
          limit: 10,
          status: status,
        },
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Manage Sub-Admins</h4>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Sub admin Name.."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </form>
                <Link to="/add-subadmin" className="btn add-new">
                  +
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-9">
                <ul
                  className="nav nav-pills group-nav mb-3 mt-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "active" ? "nav-link active" : "nav-link"
                      }
                      id="pills-active-skills-tab"
                      type="button"
                      role="tab"
                      aria-controls="pills-active-skills"
                      aria-selected="true"
                      onClick={() => {
                        setStatus(1);
                        setPage(1);
                        setTabName("active");
                      }}
                    >
                      active
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "inactive" ? "nav-link active" : "nav-link"
                      }
                      id="pills-inactive-skills-tab"
                      onClick={() => {
                        setStatus(0);
                        setPage(1);
                        setTabName("inactive");
                      }}
                      type="button"
                      role="tab"
                      aria-controls="pills-inactive-skills"
                      aria-selected="false"
                    >
                      inactive
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            {loading ? (
              <Loader />
            ) : (
              <div className="tab-content" id="pills-tabContent">
                <div
                  className={
                    tabName === "active"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="pills-active-skills"
                  role="tabpanel"
                  aria-labelledby="pills-active-skills-tab"
                >
                  <div className="table-responsive text-center">
                    <table className="table storetable">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">Profile</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>

                          <th scope="col">Actions</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>

                      <tbody>
                        {list && list?.length > 0 ? (
                          list?.map((item, i) => (
                            <tr key={i}>
                              <td>
                                <img
                                  src={
                                    item?.image?.includes("http")
                                      ? item?.image
                                      : "assets/images/profile1.png"
                                  }
                                  alt=""
                                  className="user-image"
                                />
                              </td>
                              <td>
                                <b>{item?.name ? item?.name : "NA"}</b>
                              </td>
                              <td
                                style={{
                                  textTransform: "none",
                                }}
                              >
                                {item?.email || "NA"}
                              </td>

                              <td>
                                <div
                                  className="d-flex action"
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "baseline",
                                  }}
                                >
                                  <label className="switch ">
                                    <input
                                      type="checkbox"
                                      checked={item?.isActive}
                                      onChange={() => {
                                        handleToggle(item.id, !item.isActive);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>

                                  <Link
                                    to={`/subadmin/${item?.id}`}
                                    className="btn EditApointment"
                                  >
                                    <img
                                      src="assets/images/edit.png"
                                      alt=""
                                      width="20px"
                                    />
                                  </Link>
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      navigate(`/view/subadmin/${item.id}`);
                                    }}
                                  >
                                    <img
                                      src="assets/images/view.png"
                                      className="sha-up"
                                      alt=""
                                    />
                                  </button>
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      setDelId(item.id);
                                      setShow(!show);
                                      setDelName(item?.name);
                                      // setShowFor("delete");
                                    }}
                                  >
                                    <img
                                      src="assets/images/delete-sm.png"
                                      alt=""
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {list?.length > 0 && (
                      <div className="col-sm-12">
                        <Paginations
                          page={page}
                          total={
                            total_active_sub_admin && total_active_sub_admin
                          }
                          handlePage={(val) => {
                            setPage(val);
                          }}
                          limit={10}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    tabName === "inactive"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="pills-inactive-skills"
                  role="tabpanel"
                  aria-labelledby="pills-inactive-skills-tab"
                >
                  <div className="table-responsive text-center">
                    <table className="table storetable">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">Profile</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>

                          <th scope="col">Actions</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>

                      <tbody>
                        {list && list?.length > 0 ? (
                          list?.map((item, i) => (
                            <tr key={i}>
                              <td>
                                <img
                                  src={
                                    item?.image?.includes("http")
                                      ? item?.image
                                      : "assets/images/profile1.png"
                                  }
                                  alt=""
                                  className="user-image"
                                />
                              </td>
                              <td>
                                <b>{item?.name ? item?.name : "NA"}</b>
                              </td>
                              <td
                                style={{
                                  textTransform: "none",
                                }}
                              >
                                {item?.email || "NA"}
                              </td>

                              <td>
                                <div
                                  className="d-flex action"
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "baseline",
                                  }}
                                >
                                  <label className="switch ">
                                    <input
                                      type="checkbox"
                                      checked={item?.isActive}
                                      onChange={() => {
                                        handleToggle(item.id, !item.isActive);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>

                                  <Link
                                    to={`/subadmin/${item?.id}`}
                                    className="btn EditApointment"
                                  >
                                    <img
                                      src="assets/images/edit.png"
                                      alt=""
                                      width="20px"
                                    />
                                  </Link>
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      navigate(`/view/subadmin/${item.id}`);
                                    }}
                                  >
                                    <img
                                      src="assets/images/view.png"
                                      className="sha-up"
                                      alt=""
                                    />
                                  </button>
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      setDelId(item.id);
                                      setShow(!show);
                                      setDelName(item?.name);
                                      // setShowFor("delete");
                                    }}
                                  >
                                    <img
                                      src="assets/images/delete-sm.png"
                                      alt=""
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {list?.length > 0 && (
                      <div className="col-sm-12">
                        <Paginations
                          page={page}
                          total={
                            total_inactive_sub_admin && total_inactive_sub_admin
                          }
                          handlePage={(val) => {
                            setPage(val);
                          }}
                          limit={10}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {delName && (
        <DeleteModal
          show={show}
          handleShow={() => {
            setShow(!show);
            setDelId("");
            setDelName("");
          }}
          DelSkillName={delName}
          confirmDelete={confirmDelete}
        />
      )}
    </article>
  );
}

export default ManageSubAdmin;
