import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Paginations from "../component/pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "./../component/modal/DeleteModal";
import {
  changeSkillStatus,
  deleteSkill,
  getAllCareerList,
  getAllSkillList,
} from "../slice";
import Loader from "../component/loader/Loader";
import { toast } from "react-toastify";

function ManageSkills() {
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState("active");
  const [activePage, setActivePage] = useState(1);
  const total = useSelector((store) => store.total_skill);
  const total_active_skill = useSelector((store) => store.total_active_skill);
  const total_inactive_skill = useSelector(
    (store) => store.total_inactive_skill
  );
  const [delShow, setDelShow] = useState(false);
  const [status, setStatus] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [DelSkillName, setDelSkillName] = useState("");
  const skills = useSelector((store) => store.getAllSkillList);
  const [loading, setLoading] = useState(false);

  const [delId, setDelId] = useState("");
  const [careerIdSelected, setCareerIdSelected] = useState("");
  const [career_list, setCareer_list] = useState([]);

  const handleDelete = () => {
    setDelShow(!delShow);
  };

  const getlist = useCallback(async () => {
    setLoading(true);

    dispatch(
      getAllSkillList({
        search: searchValue,
        page: page,
        limit: 10,
        status: status,
        career_id: careerIdSelected,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, searchValue, page, status, careerIdSelected]);

  const toggleSkill = (id, stat) => {
    dispatch(
      changeSkillStatus({
        data: { skillId: id, status: stat },
        params: {
          search: searchValue,
          page: page,
          limit: 10,
          status: status,
          career_id: careerIdSelected,
        },
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const confirmDelete = () => {
    dispatch(
      deleteSkill({
        data: { skillId: delId },
        params: {
          search: searchValue,
          page: page,
          limit: 10,
          status: status,
          career_id: careerIdSelected,
        },
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const getCareerList = () => {
    dispatch(getAllCareerList({})).then((res) => {
      if (res?.payload?.code === 1) {
        console.log(res);
        setCareer_list(res?.payload?.data?.career_list);
      }
    });
  };

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlist();
      getCareerList();
    }
    return () => {
      mounted = false;
    };
  }, [getlist]);

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Skills</h4>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Skill Name.."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </form>
                <Link to="/add-skill" className="btn add-new">
                  +
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-9">
                <ul
                  className="nav nav-pills group-nav mb-3 mt-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "active" ? "nav-link active" : "nav-link"
                      }
                      id="pills-active-skills-tab"
                      type="button"
                      role="tab"
                      aria-controls="pills-active-skills"
                      aria-selected="true"
                      onClick={() => {
                        setStatus(1);
                        setPage(1);
                        setTabName("active");
                      }}
                    >
                      active
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "inactive" ? "nav-link active" : "nav-link"
                      }
                      id="pills-inactive-skills-tab"
                      onClick={() => {
                        setStatus(0);
                        setPage(1);
                        setTabName("inactive");
                      }}
                      type="button"
                      role="tab"
                      aria-controls="pills-inactive-skills"
                      aria-selected="false"
                    >
                      inactive
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3 position-relative">
                <select
                  className="form-control selectOption"
                  onChange={(e) => {
                    setCareerIdSelected(e.target.value);
                  }}
                >
                  <option value="">Filter by Career Id</option>
                  {career_list?.map((item, i) => {
                    return <option value={item.id}>{item?.title}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            {loading ? (
              <Loader />
            ) : (
              <div className="tab-content" id="pills-tabContent">
                <div
                  className={
                    tabName === "active"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="pills-active-skills"
                  role="tabpanel"
                  aria-labelledby="pills-active-skills-tab"
                >
                  <div className="table-responsive text-center">
                    <table className="table storetable">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">S.No.</th>
                          <th scope="col">Skill Name</th>
                          <th scope="col">action</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>

                      <tbody>
                        {skills && skills?.length > 0 ? (
                          skills?.map((item, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{item?.title}</td>
                              <td>
                                <div
                                  className="d-flex justify-content-center action"
                                  style={{
                                    alignItems: "baseline",
                                  }}
                                >
                                  {/* <button className="btn">
                                  <img
                                    src="assets/images/application-rejected.png"
                                    alt=""
                                  />
                                </button> */}
                                  <span>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={item?.isActive}
                                        onChange={() => {
                                          toggleSkill(item.id, !item.isActive);
                                        }}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </span>
                                  <Link
                                    to={`/edit-skill/${item?.id}`}
                                    className="btn EditApointment"
                                  >
                                    <img
                                      src="assets/images/edit.png"
                                      alt=""
                                      width="20px"
                                    />
                                  </Link>
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      setDelId(item.id);
                                      handleDelete();
                                      setDelSkillName(item?.title);
                                    }}
                                  >
                                    <img
                                      src="assets/images/delete-sm.png"
                                      alt=""
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {skills?.length > 0 && searchValue.length === 0 ? (
                      <div className="col-sm-12">
                        <Paginations
                          page={page}
                          total={total_active_skill}
                          handlePage={(val) => {
                            setPage(val);
                          }}
                          limit={10}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className={
                    tabName === "inactive"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="pills-inactive-skills"
                  role="tabpanel"
                  aria-labelledby="pills-inactive-skills-tab"
                >
                  <div className="table-responsive text-center">
                    <table className="table storetable">
                      <thead className="text-center">
                        <tr>
                          <th scope="col">S.No.</th>
                          <th scope="col">Skill Name</th>
                          <th scope="col">action</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {skills && skills?.length > 0 ? (
                          skills?.map((item, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{item?.title}</td>
                              <td>
                                <div
                                  className="d-flex justify-content-center action"
                                  style={{
                                    alignItems: "baseline",
                                  }}
                                >
                                  {/* <button className="btn">
                                    <img
                                      src="assets/images/application-viewed.png"
                                      alt=""
                                    />
                                  </button> */}
                                  <span>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={item?.isActive}
                                        onChange={() => {
                                          toggleSkill(item.id, !item.isActive);
                                        }}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </span>
                                  <Link
                                    to={`/edit-skill/${item?.id}`}
                                    className="btn EditApointment"
                                  >
                                    <img
                                      src="assets/images/edit.png"
                                      alt=""
                                      width="20px"
                                    />
                                  </Link>
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      setDelId(item.id);
                                      handleDelete();
                                      setDelSkillName(item?.title);
                                    }}
                                  >
                                    <img
                                      src="assets/images/delete-sm.png"
                                      alt=""
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {skills?.length > 0 && searchValue.length === 0 ? (
                      <div className="col-sm-12">
                        <Paginations
                          page={page}
                          total={total_inactive_skill}
                          handlePage={(val) => {
                            setPage(val);
                          }}
                          limit={10}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        show={delShow}
        handleShow={handleDelete}
        DelSkillName={DelSkillName}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default ManageSkills;
