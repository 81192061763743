export const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEY,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
};

export const toolbarOptions = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: { matchVisual: false },
};

export const formats = [
  "background",
  "bold",
  "color",

  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
];

export const customStyles = {
  control: (styles) => ({ ...styles, borderWidth: "0", marginTop: "5px" }),
  menu: (styles) => ({ ...styles, width: "90%" }),
  valueContainer: (styles) => ({
    ...styles,
    height: "35px",
    overflowY: "scroll",
  }),
  placeholder: (styles) => ({ ...styles, marginTop: "5px" }),
};

export function getParameterByName() {
  console.log(window.location);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
}

export const customStylesForCareerPathSelect = {
  control: (styles) => ({ ...styles, borderWidth: "0" }),
  menu: (styles) => ({ ...styles, width: "90%" }),
};
