import React, { Component, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import parsePhoneNumber from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { config } from "../../utils";
import S3FileUpload from "react-s3";
import { Progress } from "react-sweet-progress";
import Loader from "../component/loader/Loader";
import { addSubadminSchema } from "../../utils/schema";
import { addSubAdminReducer, getAdminDetails } from "../slice";
function AddSubadmin() {
  const { id } = useParams();
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((store) => store.loading);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);
  const {
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: yupResolver(addSubadminSchema),
    shouldFocusError: true,
  });
  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      addSubAdminReducer({
        ...data,
        manage_setting: true,
        subAdminId: id ? id : "",
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        navigate("/manage-subadmin", { replace: true });
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const getDetails = useCallback(() => {
    dispatch(getAdminDetails({ id: id })).then((res) => {
      console.log(res);
      let data = res?.payload?.data?.admin_details;

      reset({
        id: data?.id,
        name: data?.name,
        email: data?.email,
        image: data?.image,
        manage_career_path: data?.assigned_roles?.manage_career_path,
        manage_speciality: data?.assigned_roles?.manage_speciality,
        manage_skill: data?.assigned_roles?.manage_skill,
        manage_student: data?.assigned_roles?.manage_student,
        manage_counseller: data?.assigned_roles?.manage_counseller,
        manage_mentor: data?.assigned_roles?.manage_mentor,
        manage_request: data?.assigned_roles?.manage_request,
        manage_forum: data?.assigned_roles?.manage_forum,
        manage_withdrawal: data?.assigned_roles?.manage_withdrawal,
      });
    });
  }, []);

  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, [id, getDetails]);

  console.log(errors);

  const watchId = watch("id");
  console.log(watchId);

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>{id ? "Edit Sub-Admin" : "Add Sub-Admin"}</h4>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-sm-12">
            <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="form-group text-center">
                    <div className="upload-btn-wrapper">
                      <button className="btn">
                        {time > 0 && isActive ? (
                          <Progress type="circle" width={80} percent={time} />
                        ) : (
                          <img
                            src={
                              getValues("image")
                                ? getValues("image")
                                : "assets/images/upload-img.png"
                            }
                            alt=""
                          />
                        )}

                        {time > 0 && isActive ? null : (
                          <div className="camera-icon">
                            <i className="fa fa-camera"></i>
                          </div>
                        )}
                      </button>
                      <input
                        type="file"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            setIsActive(true);
                            S3FileUpload.uploadFile(e.target.files[0], config)
                              .then((data) => {
                                setValue("image", data.location);
                                setTime(100);
                                setIsActive(true);
                                setTimeout(() => {
                                  setTime(0);
                                  setIsActive(false);
                                }, 3000);
                              })
                              .catch((data) => console.log(data));
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-4">
                  <label>Name</label>
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Name"
                        {...register("name")}
                      />
                      <img
                        src="assets/images/user.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {errors.name && (
                    <span className="text-danger">{errors.name.message}</span>
                  )}
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="form-group">
                    <label>Email</label>
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Enter Primary Email"
                        {...register(`email`)}
                      />
                      <img
                        src="assets/images/email.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {errors.email && (
                    <span className="text-danger">{errors.email.message}</span>
                  )}
                </div>

                {!id && (
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="form-group">
                      <label>Password</label>
                      <div className="input-container">
                        <input
                          type="password"
                          className="form-control pl-5"
                          placeholder="Password"
                          {...register(`password`)}
                        />

                        <img
                          src="assets/images/user.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.password && (
                      <span className="text-danger">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                )}

                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <h3>Permission & Privileges</h3>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="table-responsive">
                    <table className="table storetable">
                      <thead className="bg-transparent">
                        <tr>
                          <th scope="col" className="bg-transparent">
                            Modules
                          </th>
                          <th scope="col" className="text-end bg-transparent">
                            action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="ps-3">Career Path</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_career_path"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_career_path")}
                                      onChange={(e) => {
                                        onChange(e.target.checked);
                                      }}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Speciality</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_speciality"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_speciality")}
                                      onChange={(e) => {
                                        onChange(e.target.checked);
                                      }}
                                    />
                                  )}
                                />

                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Skill</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_skill"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_skill")}
                                      onChange={(e) => {
                                        onChange(e.target.checked);
                                      }}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Student</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_student"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_student")}
                                      onChange={(e) => {
                                        onChange(e.target.checked);
                                      }}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Counseller</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_counseller"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_counseller")}
                                      onChange={(e) => {
                                        onChange(e.target.checked);
                                      }}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Mentor</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_mentor"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_mentor")}
                                      onChange={(e) => {
                                        onChange(e.target.checked);
                                      }}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Request</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_request"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_request")}
                                      onChange={(e) => {
                                        onChange(e.target.checked);
                                      }}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Forum</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_forum"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_forum")}
                                      onChange={(e) => {
                                        onChange(e.target.checked);
                                      }}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Withdrawal</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_withdrawal"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_withdrawal")}
                                      onChange={(e) => {
                                        onChange(e.target.checked);
                                      }}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="form-group">
                    <div className="input-container">
                      <button className="btn add-btn">
                        {loading ? <Loader /> : "Add"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddSubadmin;
