import React, { Component, useCallback, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Progress } from "react-sweet-progress";
import { Link, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addCounsellerSchema,
  editCounsellerSchema,
  schema,
} from "../../utils/schema";
import S3FileUpload from "react-s3";
import { config, customStyles, getParameterByName } from "../../utils";
import {
  addCounseller,
  getAllCareerList,
  getAllCityList,
  getAllCountryList,
  getAllServiceList,
  getAllStateList,
  getUserDetails,
} from "../slice";
import { toast } from "react-toastify";
import parsePhoneNumber from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import Loader from "./../component/loader/Loader";

function AddCounseller() {
  const { id } = useParams();
  console.log("userId addCounsellor ==> ", id);
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const country = useSelector((state) => state.country);
  const loading = useSelector((store) => store.loading);
  const [career_paths, setCareer_paths] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const {
    register,
    watch,
    reset,
    unregister,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setError,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: yupResolver(id ? editCounsellerSchema : addCounsellerSchema),
    shouldFocusError: true,
    defaultValues: {
      service_data: [{ service_id: [], fee: "" }],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control: control,
    name: "service_data",
  });

  const onSubmit = (data) => {
    console.log(data);
    const serviceDatas = data?.service_data?.map((item) => {
      return { service_id: item?.service_id?.id, fee: item?.fee.toString() };
    });
    const careerDatas = data?.career_data?.map((item) => {
      return { career_id: item?.id };
    });

    dispatch(
      addCounseller({
        username: data?.username,
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        gender: data?.gender,
        mobile: data?.mobile,
        countryCode: data?.countryCode,
        secondary_mobile: data?.secondary_mobile ? data?.secondary_mobile : "",
        secondary_countryCode: data?.secondary_countryCode
          ? data?.secondary_countryCode
          : "",
        designation: data?.designation,
        company_name: data?.company_name,
        secondary_email: data?.secondary_email,
        about: data?.about,
        country: data?.country,
        state: data?.state,
        city: data?.city,
        image: data?.image,
        experience: data?.experience,
        career_data: JSON.stringify(careerDatas),
        service_data: JSON.stringify(serviceDatas),
        state_isocode: data?.state_code ? data?.state_code : "",
        city_isocode: data?.city_isocode ? data?.city_isocode : "",
        password: data?.password ? data?.password : "",
        userId: id ? id : "",
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        navigate("/manage-counseller", { replace: true });
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getAllCareerList({})).then((res) => {
      if (res?.payload?.code === 1) {
        const newArr = res?.payload?.data?.career_list?.map((item) => {
          return { id: item?.id, title: item?.title };
        });

        setCareer_paths(newArr);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getAllCountryList({}));
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getUserDetails({ userId: id, user_type: "counseller" })).then(
        (res) => {
          if (res?.payload?.code === 1) {
            console.log(res);
            let data = res?.payload?.data?.user_details;
            reset({
              username: data?.username,
              image: data?.image,
              first_name: data?.first_name,
              last_name: data?.last_name,
              email: data?.email,
              secondary_email: data?.user_other_details?.secondary_email,
              gender: data?.gender,
              designation: data?.user_other_details?.designation,
              company_name: data?.user_other_details?.company_name,
              experience: data?.user_other_details?.experience,
              about: data?.about,
              mobile: data?.user_other_details?.mobile,
              countryCode: data?.user_other_details?.countryCode,
              country: data?.country,
              state: data?.state,
              city: data?.city,
              career_data: data?.career_list?.map((item) => {
                return {
                  id: item?.career_id,
                  title: item?.career_details?.title,
                };
              }),
              service_data: data?.service_list?.map((item) => {
                return {
                  service_id: {
                    id: item?.service_details?.id,
                    title: item?.service_details?.title,
                  },
                  fee: item?.fee,
                };
              }),
            });
            getState(
              data?.city_isocode ? data?.city_isocode : country?.isoCode
            );
          }
        }
      );
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getAllServiceList({ user_type: "counseller" })).then((res) => {
      if (res?.payload?.code === 1) {
        setServiceList(
          res?.payload?.data?.service_list?.map((it) => {
            return { id: it.id, title: it?.title };
          })
        );
      }
    });
  }, [dispatch]);
  const handleCountry = (name, value) => {
    setValue(name, value);
  };
  const getState = useCallback(
    (val) => {
      dispatch(getAllStateList({ countryIsoCode: val })).then((res) => {
        if (res?.payload?.code === 1) {
          setStates(res?.payload?.data?.state_list);
          let newArr = res?.payload?.data?.state_list.find(
            (item) => item?.name === getValues("state")
          );
          console.log(newArr);
          getCity(newArr.isoCode);
        }
      });
    },
    [dispatch]
  );

  const getCity = useCallback(
    (val) => {
      dispatch(getAllCityList({ state_code: val })).then((res) => {
        if (res?.payload?.code === 1) {
          setCity(res?.payload?.data?.city_list);
        }
      });
    },
    [dispatch]
  );

  const data = watch(`first_name`);
  const serviceData = watch(`service_data`);

  const filterServiceList = (i) => {
    let arr = serviceList?.filter(
      (item) => serviceData[i].service_id?.id !== item?.id
    );
    setServiceList(arr);
  };
  console.log(errors);

  if (id && !data) {
    return <Loader />;
  } else {
    return (
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="bg-white pt-3 px-3 rad">
            <div className="row align-items-center col-rever">
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <h4>{id ? "Edit" : "Add"} Counseller</h4>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-sm-12">
              <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="form-group text-center">
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          {time > 0 && isActive ? (
                            <Progress type="circle" width={80} percent={time} />
                          ) : (
                            <img
                              src={
                                getValues("image")
                                  ? getValues("image")
                                  : "assets/images/upload-img.png"
                              }
                              alt=""
                            />
                          )}

                          {time > 0 && isActive ? null : (
                            <div className="camera-icon">
                              <i className="fa fa-camera"></i>
                            </div>
                          )}
                        </button>
                        <input
                          type="file"
                          onChange={(e) => {
                            if (e.target.files.length > 0) {
                              setIsActive(true);
                              S3FileUpload.uploadFile(e.target.files[0], config)
                                .then((data) => {
                                  setValue("image", data.location);
                                  setTime(100);
                                  setIsActive(true);
                                  setTimeout(() => {
                                    setTime(0);
                                    setIsActive(false);
                                  }, 3000);
                                })
                                .catch((data) => console.log(data));
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="First Name"
                          {...register("first_name")}
                        />
                        <img
                          src="assets/images/user.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.first_name && (
                      <span className="text-danger">
                        {errors.first_name.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Last Name"
                          {...register("last_name")}
                        />
                        <img
                          src="assets/images/user.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.last_name && (
                      <span className="text-danger">
                        {errors.last_name.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <div className="form-group">
                      <div className="input-container">
                        <label className="mb-2">Gender</label>

                        <div className="Other-radio">
                          <span className="checkbox">
                            <input
                              type="radio"
                              id="Male"
                              name="gender"
                              value="Male"
                              {...register(`gender`)}
                            />
                            <label htmlFor="Male">Male</label>
                          </span>
                          <span className="checkbox">
                            <input
                              type="radio"
                              id="Female"
                              name="gender"
                              value="Female"
                              {...register(`gender`)}
                            />
                            <label htmlFor="Female">Female</label>
                          </span>
                          <span className="checkbox">
                            <input
                              type="radio"
                              id="Other"
                              name="gender"
                              value="Other"
                              {...register(`gender`)}
                            />
                            <label htmlFor="Other">Other</label>
                          </span>
                        </div>
                        <span className="text-danger">
                          {errors && errors?.gender?.message}
                        </span>
                      </div>
                    </div>
                    {errors.title && (
                      <span className="text-danger">
                        {errors.title.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Designation"
                          {...register("designation")}
                        />
                        <img
                          src="assets/images/designation.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.designation && (
                      <span className="text-danger">
                        {errors.designation.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Company Name"
                          {...register("company_name")}
                        />
                        <img
                          src="assets/images/company_name.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.company_name && (
                      <span className="text-danger">
                        {errors.company_name.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                    <div className="form-group ">
                      <label>Country</label>
                      <div className="input-container">
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <select
                              className="form-control pl-5"
                              name="country"
                              onChange={(e) => {
                                // onChange(e);
                                if (e.target.value) {
                                  let arr = e.target.value.split(",");
                                  let val = arr[0];
                                  handleCountry(e.target.name, val);
                                  getState(arr[1]);
                                }
                              }}
                            >
                              <option value="">Select Country</option>
                              <option
                                value={country?.name + "," + country?.isoCode}
                                selected={
                                  getValues("country") === country?.name
                                }
                              >
                                {country?.name}
                              </option>
                            </select>
                          )}
                          name="country"
                          value={getValues("country")}
                          control={control}
                          defaultValue={null}
                        />

                        <img
                          src="assets/images/map.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>

                    <span className="text-danger">
                      {errors && errors?.country?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                    <label>State</label>
                    <div className="form-group">
                      <div className="input-container">
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="State"
                        /> */}
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <select
                              className="form-control pl-5"
                              name="state"
                              onChange={(e) => {
                                const arr = e.target.value.split(",");
                                const val = arr[0];
                                setValue("state_isocode", arr[1]);
                                handleCountry(e.target.name, val);
                                getCity(arr[1]);
                                // setState_isocode(arr[1]);
                              }}
                            >
                              <option value="">Select state</option>

                              {states?.map((item) => {
                                return (
                                  <option
                                    value={item.name + "," + item.isoCode}
                                    selected={getValues("state") === item?.name}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                          name="state"
                          control={control}
                          defaultValue={null}
                          value={getValues("state")}
                        />
                        <img
                          src="assets/images/map.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    <span className="text-danger">
                      {errors && errors?.state?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                    <label>City</label>
                    <div className="form-group">
                      <div className="input-container">
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="City"
                        /> */}
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <select
                              className="form-control pl-5"
                              name="city"
                              onChange={(e) => {
                                const val = e.target.value.split(",");
                                handleCountry(e.target.name, val[0]);
                                // setCity_isocode(val[1]);
                                setValue("city_isocode", val[1]);
                              }}
                            >
                              <option value="">Select city</option>

                              {city?.map((item) => {
                                return (
                                  <option
                                    value={item.name + "," + item.countryCode}
                                    selected={getValues("city") === item?.name}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                          name="city"
                          control={control}
                          value={getValues("city")}
                          defaultValue={null}
                        />
                        <img
                          src="assets/images/map.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    <span className="text-danger">
                      {errors.city && errors?.city?.message}
                    </span>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <textarea
                          className="form-control h-auto "
                          rows="5"
                          placeholder="About yourself..."
                          {...register(`about`)}
                        ></textarea>
                      </div>
                    </div>
                    {errors.about && (
                      <span className="text-danger">
                        {errors.about.message}
                      </span>
                    )}
                  </div>
                  <div className=" col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="form-group">
                    <label>Primary Phone Number</label>
                    <div className="input-container">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                        }) => (
                          <PhoneInput
                            country={"in"}
                            value={
                              getValues("mobile")
                                ? getValues("countryCode") + getValues("mobile")
                                : undefined
                            }
                            countryCodeEditable={false}
                            containerClass={"form-control pl-5"}
                            inputClass={"inputClass"}
                            onChange={(phone, country, e, formattedValue) => {
                              if (phone.length > 0) {
                                const raw = phone.slice(
                                  country?.dialCode?.length
                                );
                                const phoneNumber = parsePhoneNumber(
                                  formattedValue,
                                  country.countryCode
                                );

                                if (phoneNumber?.isValid()) {
                                  setValue("mobile", raw);
                                  setValue(
                                    "countryCode",
                                    formattedValue?.split(" ")[0]
                                  );
                                } else {
                                  setValue("mobile", "");
                                  setValue("countryCode", "");
                                }
                              }
                            }}
                          />
                        )}
                        name="mobile"
                        control={control}
                        value={getValues("countryCode") + getValues("mobile")}
                        defaultValue={null}
                      />
                      <img
                        src="assets/images/Phone.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors.mobile && errors?.mobile?.message}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="form-group">
                    <label>Secondary Phone Number</label>
                    <div className="input-container">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                        }) => (
                          <PhoneInput
                            country={"in"}
                            containerClass={"form-control pl-5"}
                            inputClass={"inputClass"}
                            onChange={(phone, country, e, formattedValue) => {
                              if (phone.length > 0) {
                                const raw = phone.slice(
                                  country?.dialCode?.length
                                );
                                const phoneNumber = parsePhoneNumber(
                                  formattedValue,
                                  country.countryCode
                                );

                                if (phoneNumber?.isValid()) {
                                  setValue("secondary_mobile", raw);
                                  setValue(
                                    "secoundary_countryCode",
                                    formattedValue?.split(" ")[0]
                                  );
                                } else {
                                  setValue("secondary_mobile", "");
                                  setValue("secoundary_countryCode", "");
                                }
                              }
                            }}
                          />
                        )}
                        name="secondary_mobile"
                        control={control}
                        value={
                          getValues("secoundary_countryCode") +
                          getValues("secondary_mobile")
                        }
                        defaultValue={
                          getValues("secoundary_countryCode") +
                          getValues("secondary_mobile")
                        }
                      />
                      <img
                        src="assets/images/Phone.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors.secondary_mobile &&
                        errors?.secondary_mobile?.message}
                    </span>
                  </div>
                </div>
                  {/* <div className=" col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="form-group">
                      <label>Primary Phone Number</label>
                      <div className="input-container">
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <PhoneInput
                              country={"in"}
                              value={
                                getValues("mobile")
                                  ? getValues("mobile") +
                                    getValues("countryCode")
                                  : undefined
                              }
                              containerClass={"form-control pl-5"}
                              inputClass={"inputClass"}
                              onChange={(phone, country, e, formattedValue) => {
                                if (phone.length > 0) {
                                  const raw = phone.slice(
                                    country?.dialCode?.length
                                  );
                                  const phoneNumber = parsePhoneNumber(
                                    formattedValue,
                                    country.countryCode
                                  );

                                  if (phoneNumber?.isValid()) {
                                    setValue("mobile", raw);
                                    setValue(
                                      "countryCode",
                                      formattedValue?.split(" ")[0]
                                    );
                                  } else {
                                    setValue("mobile", "");
                                    setValue("countryCode", "");
                                  }
                                }
                              }}
                            />
                          )}
                          name="mobile"
                          control={control}
                          value={getValues("countryCode") + getValues("mobile")}
                          defaultValue={null}
                        />
                        <img
                          src="assets/images/Phone.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      <span className="text-danger">
                        {errors.mobile && errors?.mobile?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="form-group">
                      <label>Secondary Phone Number</label>
                      <div className="input-container">
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <PhoneInput
                              country={"in"}
                              value={
                                getValues("secondary_mobile")
                                  ? getValues("secondary_mobile") +
                                    getValues("countryCode")
                                  : undefined
                              }
                              countryCodeEditable
                              containerClass={"form-control pl-5"}
                              inputClass={"inputClass"}
                              onChange={(phone, country, e, formattedValue) => {
                                if (phone.length > 0) {
                                  const raw = phone.slice(
                                    country?.dialCode?.length
                                  );
                                  const phoneNumber = parsePhoneNumber(
                                    formattedValue,
                                    country.countryCode
                                  );

                                  if (phoneNumber?.isValid()) {
                                    setValue("secondary_mobile", raw);
                                    setValue(
                                      "secoundary_countryCode",
                                      formattedValue?.split(" ")[0]
                                    );
                                  } else {
                                    setValue("secondary_mobile", "");
                                    setValue("secoundary_countryCode", "");
                                  }
                                }
                              }}
                            />
                          )}
                          name="secondary_mobile"
                          control={control}
                          value={
                            getValues("secoundary_countryCode") +
                            getValues("secondary_mobile")
                          }
                          defaultValue={
                            getValues("secoundary_countryCode") +
                            getValues("secondary_mobile")
                          }
                        />
                        <img
                          src="assets/images/Phone.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      <span className="text-danger">
                        {errors.secondary_mobile &&
                          errors?.secondary_mobile?.message}
                      </span>
                    </div>
                  </div> */}
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="form-group">
                      <label>Primary Email</label>
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Enter Primary Email"
                          {...register(`email`)}
                        />
                        <img
                          src="assets/images/email.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="form-group">
                      <label>Secondary Email</label>
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Enter Secondary Email"
                          {...register(`secondary_email`)}
                        />
                        <img
                          src="assets/images/email.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.secondary_email && (
                      <span className="text-danger">
                        {errors.secondary_email.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="form-group">
                      <label>Career Path</label>
                      <div className="input-container reactSelectContainer">
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Last Name"
                          {...register(`lastName`)}
                        /> */}
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <Select
                              className="form-control pl-5"
                              onChange={(e) => {
                                onChange(e);
                              }}
                              value={value}
                              getOptionLabel={(e) => e.title}
                              getOptionValue={(e) => e.id}
                              options={career_paths ? career_paths : undefined}
                              styles={customStyles}
                              isMulti
                            ></Select>
                          )}
                          name={`career_data`}
                          control={control}
                          defaultValue={null}
                        />
                        <img
                          src="assets/images/user.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      <span className="text-danger">
                        {errors && errors?.career_data?.message}
                      </span>
                    </div>
                  </div>
                  {fields?.map((item, i) => {
                    return (
                      <>
                        <div
                          className="form-group col-sm-12 col-md-6 col-lg-6 mb-4 "
                          key={i}
                        >
                          <div className="form-group">
                            <label>Services Offered</label>
                            <div className="input-container reactSelectContainer">
                              {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Last Name"
                            {...register(`lastName`)}
                          /> */}
                              <Controller
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                  fieldState: {
                                    invalid,
                                    isTouched,
                                    isDirty,
                                    error,
                                  },
                                }) => (
                                  <Select
                                    className="form-control pl-5"
                                    onChange={(e) => {
                                      onChange(e);
                                      filterServiceList(i);
                                    }}
                                    value={value}
                                    getOptionLabel={(e) => e.title}
                                    getOptionValue={(e) => e.id}
                                    options={
                                      serviceList ? serviceList : undefined
                                    }
                                    styles={customStyles}
                                  ></Select>
                                )}
                                name={`service_data[${i}].service_id`}
                                value={`service_data[${i}].service_id`}
                                control={control}
                              />
                              <img
                                src="assets/images/user.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                            <span className="text-danger">
                              {errors?.service_data &&
                                errors?.service_data[i]?.service_id?.message}
                            </span>
                          </div>
                        </div>

                        <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                          <div className="form-group">
                            <label>Fee</label>
                            <div className="input-container">
                              <input
                                type="number"
                                className="form-control pl-5"
                                placeholder="Fee"
                                {...register(`service_data[${i}].fee`)}
                              />

                              <img
                                src="assets/images/user.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                            <span className="text-danger">
                              {errors?.service_data &&
                                errors?.service_data[i]?.fee?.message}
                            </span>
                          </div>
                        </div>

                        {fields?.length > 1 && (
                          <div className="form-group col-sm-12 col-md-12 col-lg-12">
                            <span
                              id="remove_2"
                              className="remove"
                              onClick={() => {
                                remove(i);
                              }}
                            >
                              <img src="assets/images/menu_close.png" alt="" />
                            </span>
                          </div>
                        )}
                      </>
                    );
                  })}
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="form-group">
                      <span
                        className="AddtoList btn"
                        onClick={() => {
                          append({
                            fee: "",
                            service_id: "",
                          });
                        }}
                      >
                        Add More Services
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="form-group">
                      <label>Experience</label>
                      <div className="input-container">
                        <input
                          type="number"
                          className="form-control pl-5"
                          placeholder="Experience"
                          {...register(`experience`)}
                        />

                        <img
                          src="assets/images/user.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.experience && (
                      <span className="text-danger">
                        {errors.experience.message}
                      </span>
                    )}
                  </div>{" "}
                  {!id && (
                    <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                      <div className="form-group">
                        <label>Password</label>
                        <div className="input-container">
                          <input
                            type="password"
                            className="form-control pl-5"
                            placeholder="Password"
                            {...register(`password`)}
                          />

                          <img
                            src="assets/images/user.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {errors.password && (
                        <span className="text-danger">
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                  )}
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                    <div className="form-group">
                      <div className="input-container">
                        <button className="btn add-btn">
                          {loading ? <Loader /> : "Add"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default AddCounseller;
