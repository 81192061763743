import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../component/loader/Loader";
import DeleteModal from "../component/modal/DeleteModal";
import { changeUserStatus, deleteUser, userList, userListExcelDownload } from "../slice";
import Paginations from "./../component/pagination/Pagination";

function ManageStudent() {
  const dispatch = useDispatch();
  const urlGetAllUserInExcel="https://api.xaphal.com/api/admin/getAllUserInExcel";
  // const urlGetAllUserInExcel="http://localhost:4000/api/admin/getAllUserInExcel";
  console.log("url ", urlGetAllUserInExcel);
  const list = useSelector((store) => store.otherUserList);
  const activeUser = useSelector((store) => store.activeUser);
  const inactiveUser = useSelector((store) => store.inactiveUser);
  // const loading = useSelector((store) => store.loading);
  const [tabName, setTabName] = useState("active");
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(1);
  const [delId, setDelId] = useState("");
  const [show, setShow] = useState("");
  const [delName, setDelName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleShow = useCallback(() => {
    setDelId(delId);
    setShow(!show);
    setDelName(delName);
  }, [show, delId, delName]);

  const getList = useCallback(() => {
    setLoading(true);

    dispatch(
      userList({
        page: page,
        search: searchValue,
        limit: 10,
        user_type: "student",
        status: status,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, searchValue, page, status]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleToggle = (id, stat) => {
    dispatch(
      changeUserStatus({
        data: { userId: id, status: stat === true ? 1 : 0 },
        params: {
          page: page,
          search: searchValue,
          limit: 10,
          user_type: "student",
          status: status,
        },
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const confirmDelete = () => {
    dispatch(
      deleteUser({
        data: { userId: delId, user_type: "student" },
        params: {
          page: page,
          search: searchValue,
          limit: 10,
          user_type: "student",
          status: status,
        },
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  console.log("list of user", list);
  console.log("searchValue", searchValue);
  console.log("inactiveUser", inactiveUser);
  console.log("activeUser", activeUser);
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Manage Student</h4>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Student Name.."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </form>
                <Link to="/add-student-basic-info" className="btn add-new">
                  +
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-10">
                <ul
                  className="nav nav-pills group-nav mb-3 mt-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "active" ? "nav-link active" : "nav-link"
                      }
                      id="pills-active-skills-tab"
                      type="button"
                      role="tab"
                      aria-controls="pills-active-skills"
                      aria-selected="true"
                      onClick={() => {
                        setStatus(1);

                        setPage(1);
                        setTabName("active");
                      }}
                    >
                      active
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "inactive" ? "nav-link active" : "nav-link"
                      }
                      id="pills-inactive-skills-tab"
                      onClick={() => {
                        setStatus(0);
                        setPage(1);
                        setTabName("inactive");
                      }}
                      type="button"
                      role="tab"
                      aria-controls="pills-inactive-skills"
                      aria-selected="false"
                    >
                      inactive
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-2 d-flex align-items-center justify-content-center">
                {/* <button 
                  onClick={()=>{
                    console.log("downloaded");
                    dispatch(userListExcelDownload({}))
                  }}
                  className="mt-4 mb-4 user_list_download_at_manage_std cursor-pointer" >
                  Download
                </button> */}
                <a href={urlGetAllUserInExcel} rel="noreferrer" style={{textDecoration:'none'}} className="mt-4 mb-4 user_list_download_at_manage_std cursor-pointer">
                    Download Xls
                </a>
              </div>
              <div className="col-sm-12">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className={
                        tabName === "active"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="pills-active-skills"
                      role="tabpanel"
                      aria-labelledby="pills-active-skills-tab"
                    >
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">Profile</th>
                              <th scope="col">Name</th>
                              <th scope="col">Gender</th>
                              {/* <th scope="col">Phone Number</th> */}
                              <th scope="col">State</th>
                              <th scope="col">City</th>
                              <th scope="col">Career Path</th>
                              <th scope="col">Specialisations</th>
                              <th scope="col">Skills</th>
                              <th scope="col">Actions</th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>

                          <tbody>
                            {list && list?.length > 0 ? (
                              list?.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/images/profile1.png"
                                      }
                                      alt=""
                                      className="user-image"
                                    />
                                  </td>
                                  <td>
                                    <b>
                                      {item?.first_name} {item?.last_name}
                                    </b>
                                  </td>
                                  <td>{item?.gender || "NA"}</td>
                                  {/* <td>
                                    {item?.user_other_details?.mobile
                                      ? `${item?.user_other_details?.countryCode}${item?.user_other_details?.mobile}`
                                      : "NA"}
                                  </td> */}
                                  <td>{item?.state ? item?.state : "NA"}</td>
                                  <td>{item?.city ? item?.city : "NA"}</td>
                                  <td>
                                    <span className="ellips-2">
                                      {" "}
                                      {item?.student_career_details?.length > 0
                                        ? item?.student_career_details
                                            ?.map(
                                              (it) => it?.career_details?.title
                                            )
                                            .join(", ")
                                        : "NA"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="ellips-2">
                                      {" "}
                                      {item?.student_career_details?.length > 0
                                        ? item?.student_career_details
                                            ?.filter(
                                              (it) =>
                                                it?.specialization_list
                                                  ?.length > 0
                                            )
                                            .map((item) => {
                                              return item?.specialization_list?.map(
                                                (it) =>
                                                  it?.specialization_details
                                                    ?.title
                                              );
                                            })
                                            .join(", ")
                                        : "NA"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="ellips-2">
                                      {item?.student_skill_details?.length > 0
                                        ? item?.student_skill_details
                                            ?.map(
                                              (item) =>
                                                item?.skill_details?.title
                                            )
                                            .join(", ")
                                        : "NA"}
                                    </span>
                                  </td>

                                  <td>
                                    <div
                                      className="d-flex justify-content-center action"
                                      style={{
                                        alignItems: "baseline",
                                      }}
                                    >
                                      {/* <button className="btn">
                                  <img
                                    src="assets/images/application-rejected.png"
                                    alt=""
                                  />
                                </button> */}
                                      <span>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={item?.isActive}
                                            onChange={() => {
                                              handleToggle(
                                                item.id,
                                                !item.isActive
                                              );
                                            }}
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      </span>
                                      <Link
                                        to={`/add-student-basic-info/${item?.id}`}
                                        className="btn EditApointment"
                                      >
                                        <img
                                          src="assets/images/edit.png"
                                          alt=""
                                          width="20px"
                                        />
                                      </Link>
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          setDelId(item.id);
                                          setShow(!show);
                                          setDelName(item?.first_name);
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={9}>No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {list?.length > 0 && (
                          <div className="col-sm-12">
                            <Paginations
                              page={page}
                              total={activeUser && activeUser}
                              limit={10}
                              handlePage={(val) => {
                                setPage(val);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        tabName === "inactive"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="pills-inactive-skills"
                      role="tabpanel"
                      aria-labelledby="pills-inactive-skills-tab"
                    >
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">Profile</th>
                              <th scope="col">Name</th>
                              <th scope="col">Gender</th>
                              {/* <th scope="col">Phone Number</th> */}
                              <th scope="col">State</th>
                              <th scope="col">City</th>
                              <th scope="col">Career Path</th>
                              <th scope="col">Specialisations</th>
                              <th scope="col">Skills</th>
                              <th scope="col">Actions</th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>

                          <tbody>
                            {list && list?.length > 0 ? (
                              list?.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/images/profile1.png"
                                      }
                                      alt=""
                                      className="user-image"
                                    />
                                  </td>
                                  <td>
                                    <b>
                                      {item?.first_name} {item?.last_name}
                                    </b>
                                  </td>
                                  <td>{item?.gender || "NA"}</td>
                                  {/* <td>
                                    {item?.user_other_details?.mobile
                                      ? `${item?.user_other_details?.countryCode}${item?.user_other_details?.mobile}`
                                      : "NA"}
                                  </td> */}
                                  <td>{item?.state ? item?.state : "NA"}</td>
                                  <td>{item?.city ? item?.city : "NA"}</td>
                                  <td>
                                    <span className="ellips-2">
                                      {" "}
                                      {item?.student_career_details?.length > 0
                                        ? item?.student_career_details
                                            ?.map(
                                              (it) => it?.career_details?.title
                                            )
                                            .join(", ")
                                        : "NA"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="ellips-2">
                                      {" "}
                                      {item?.student_career_details?.length > 0
                                        ? item?.student_career_details
                                            ?.filter(
                                              (it) =>
                                                it?.specialization_list
                                                  ?.length > 0
                                            )
                                            .map((item) => {
                                              return item?.specialization_list?.map(
                                                (it) =>
                                                  it?.specialization_details
                                                    ?.title
                                              );
                                            })
                                            .join(", ")
                                        : "NA"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="ellips-2">
                                      {item?.student_skill_details?.length > 0
                                        ? item?.student_skill_details
                                            ?.map(
                                              (item) =>
                                                item?.skill_details?.title
                                            )
                                            .join(", ")
                                        : "NA"}
                                    </span>
                                  </td>

                                  <td>
                                    <div
                                      className="d-flex justify-content-center action"
                                      style={{
                                        alignItems: "baseline",
                                      }}
                                    >
                                      {/* <button className="btn">
                                  <img
                                    src="assets/images/application-rejected.png"
                                    alt=""
                                  />
                                </button> */}
                                      <span>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={item?.isActive}
                                            onChange={() => {
                                              handleToggle(
                                                item.id,
                                                !item.isActive
                                              );
                                            }}
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      </span>
                                      <Link
                                        to={`/add-student-basic-info/${item?.id}`}
                                        className="btn EditApointment"
                                      >
                                        <img
                                          src="assets/images/edit.png"
                                          alt=""
                                          width="20px"
                                        />
                                      </Link>
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          setDelId(item.id);
                                          setShow(!show);
                                          setDelName(item?.first_name);
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={9}>No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {list?.length > 0 && (
                          <div className="col-sm-12">
                            <Paginations
                              page={page}
                              total={inactiveUser && inactiveUser}
                              handlePage={(val) => {
                                setPage(val);
                              }}
                              limit={10}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={show}
        handleShow={handleShow}
        DelSkillName={delName}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default ManageStudent;
