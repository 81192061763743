import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { addStudentCareerPathSchema } from "../../utils/schema";
import {
  addStudentCareerInfo,
  getAllCareerList,
  getAllSpecialityList,
  getUserDetails,
} from "../slice";
import { customStyles, customStylesForCareerPathSelect } from "../../utils";
import Loader from "../component/loader/Loader";
import DeleteItems from "../component/modal/DeleteItems";
function AddStudentCareerPath() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [delIndex, setDelIndex] = useState("");
  // const specilaityList = useSelector((store) => store.specilaityList);
  const [specilaityList, setSpecilaityList] = useState([]);
  const loading = useSelector((state) => state.loading);
  const careerPath = useSelector((store) => store.getAllCareerList);
  const [careerId, setCareerId] = useState("");
  const handleShow = () => {
    setShow(!show);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onTouched",
    shouldFocusError: "true",
    reValidateMode: "onSubmit",
    resolver: yupResolver(addStudentCareerPathSchema),
  });
  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      // reset form with user data
      dispatch(getUserDetails({ userId: id, user_type: "student" })).then(
        (res) => {
          if (res?.payload?.code === 1) {
            let us = res?.payload?.data?.user_details;
            let newArr = us?.student_career_details?.map((item, i) => {
              let specsi = item?.specialization_list?.map((it) => {
                return {
                  id: it?.specialization_id,
                  career_id: it?.specialization_details?.career_id,
                  name: it?.specialization_details?.title,
                };
              });
              handleSpeciality(item?.career_id);
              return {
                careerPath: item.career_id,
                specializations: specsi,
              };
            });
            console.log(newArr);
            reset({ career: newArr });
          }
        }
      );
    }

    return () => {
      isRendered = false;
    };
  }, [dispatch]);
  const { append, fields, remove } = useFieldArray({
    control: control,
    name: "career",
  });
  const onSubmit = (data) => {
    // navigate("/student/edit-profile/4");
    console.log(data);
    let arr = [...getValues("career")];
    let newArr = arr.map((item) => {
      let spec = [];

      console.log(item);
      if (
        typeof item?.specializations !== "string" &&
        item?.specializations?.length > 0
      ) {
        let specs = item?.specializations?.map((item) => {
          return `${item.id}`;
        });
        spec = [...spec, ...specs];
      }
      console.log(spec, "Specializations");
      return {
        career_id: item.careerPath,
        specializations: spec,
      };
    });
    dispatch(addStudentCareerInfo({ career_data: newArr, userId: id })).then(
      (res) => {
        if (res?.payload?.code === 1) {
          toast.success(res?.payload?.message);
          navigate(`/add-student-skills/${id}`);
        } else {
          toast.error(res?.payload?.message);
        }
      }
    );
  };
  const handleChange = (ind, e) => {
    let arr = [...getValues("career")];
    let { name, value } = e.target;
    console.log(name, value);

    arr[ind][name] = value;
    setValue("career", arr);
  };

  const handleDelete = () => {
    remove(delIndex);
  };

  useEffect(() => {
    dispatch(getAllCareerList({}));
  }, [dispatch]);

  const handleSpeciality = useCallback(
    (val) => {
      dispatch(getAllSpecialityList({ career_id: val })).then((res) => {
        let spec = res?.payload?.data?.speciality_list;
        let newArr = spec?.map((item) => {
          return {
            id: item.id,
            name: item.title,
          };
        });
        setSpecilaityList(newArr);
      });
    },
    [careerId, dispatch]
  );

  useEffect(() => {
    handleSpeciality();
  }, [handleSpeciality]);
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Career Paths</h4>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-12">
            <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
              {fields?.map((item, index) => (
                <div className="row align-items-center">
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <label>Choose Career Paths</label>
                    <div className="position-relative">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                        }) => (
                          <select
                            className="form-control pl-5"
                            name="careerPath"
                            onChange={(e) => {
                              setCareerId(e.target.value);
                              // handleChange(index, e);
                              onChange(e);
                              handleSpeciality(e.target.value);
                            }}
                            value={value}
                          >
                            <option value="">Select Career Paths</option>
                            {careerPath?.map((item, i) => {
                              return (
                                <option
                                  value={item.id}
                                  key={i}
                                  // selected={
                                  //   item?.id ===
                                  //   getValues(`career[${index}].careerPath`)
                                  // }
                                >
                                  {item?.title}
                                </option>
                              );
                            })}
                          </select>
                        )}
                        name={`career[${index}].careerPath`}
                        control={control}
                        // defaultValue={getValues(`career[${index}].careerPath`)}
                        // value={getValues(`career[${index}].careerPath`)}
                      />
                      <img
                        src="assets/images/Careerpath.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors.career &&
                        errors?.career[index]?.careerPath?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <label>Specialization</label>
                    <div className="position-relative">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                        }) => (
                          <Select
                            className="form-control pl-5"
                            onChange={(e) => {
                              onChange(e);
                            }}
                            onFocus={(e) => {
                              e.preventDefault();
                              setSpecilaityList([]);
                              handleSpeciality(
                                getValues(`career[${index}].careerPath`)
                              );
                            }}
                            isLoading={loading}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            options={specilaityList || undefined}
                            styles={customStyles}
                            isMulti
                            value={value}
                          ></Select>
                        )}
                        name={`career[${index}].specializations`}
                        control={control}
                        // defaultValue={`career[${index}].specializations`}
                        // value={"value"}
                      />
                      <img
                        src="assets/images/Specialisations.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    <span className="text-danger">
                      {errors.career &&
                        errors?.career[index]?.specializations?.message}
                    </span>
                  </div>

                  {fields?.length > 1 && (
                    <div className="form-group col-sm-12 col-md-12 col-lg-12">
                      <span
                        id="remove_2"
                        className="remove"
                        onClick={() => {
                          handleShow();
                          setDelIndex(index);
                        }}
                      >
                        <img src="assets/images/menu_close.png" alt="" />
                      </span>
                    </div>
                  )}
                  {fields?.length > 1 && (
                    <div className="form-group col-sm-12 col-md-12 col-lg-12">
                      <hr />
                    </div>
                  )}
                </div>
              ))}
              <div className="row align-items-center justify-content-center">
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <span
                    className="AddtoList btn"
                    onClick={() => {
                      append({
                        careerPath: "",
                        specialisation: "",
                      });
                    }}
                  >
                    Add More
                  </span>
                </div>
                {/* <div className="form-group col-sm-12 col-md-12 col-lg-6 mb-5">
                <div className="position-relative">
                  <ProgressBar now={75} className="animated-progress" />
                  <span className="progressTooltip">
                    Get free career “COUNSELLING”
                  </span>
                </div>
              </div> */}
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5 mt-4 text-center">
                  <button type="submit" className="btn add-btn sign-btn">
                    {loading ? <Loader /> : "Next"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <DeleteItems
        show={show}
        handleShow={handleShow}
        handleDelete={handleDelete}
      />
    </article>
  );
}

export default AddStudentCareerPath;
