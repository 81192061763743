import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "./../component/loader/Loader";
import OtpInput from "react-otp-input";
import { ForgotPassword, verifyOtp } from "../slice";
import { toast } from "react-toastify";

const padTime = (time) => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};

const format = (time) => {
  // Convert seconds into minutes and take the whole part
  const minutes = Math.floor(time / 60);

  // Get the seconds left after converting minutes
  const seconds = time % 60;

  //Return combined values as string in format mm:ss
  return `${minutes}:${padTime(seconds)}`;
};

function Otp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [counter, setCounter] = React.useState(1);
  const loading = useSelector((state) => state.loading);
  const userEmail = useSelector((state) => state.userEmail);
  const userId = useSelector((state) => state.userId);

  const schema = yup.object({
    otpValues: yup
      .string()
      .required("Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Kindly enter valid otp.")
      .max(6, "Kindly enter valid otp."),
  });

  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  React.useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1), 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  let Localemail = localStorage.getItem("email");

  const onSubmit = (data) => {
    dispatch(
      verifyOtp({
        code: data.otpValues,
        email: Localemail,
      })
    ).then((data) => {
      console.log(data);
      if (data?.payload?.code === 1) {
        toast.success(data?.payload?.message);
        navigate("/auth/reset-password", {
          replace: true,
        });
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };
  return (
    <section className="">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-7 col-lg-6">
            <div className="">
              <img
                src="assets/images/logo.png"
                className="img-fluid logo-admin"
                alt="logo"
              />
            </div>
            <div className="form-bg">
              <form
                className="row form-style"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="col-sm-12 text-center">
                  <img
                    src="assets/images/otp.png"
                    className="img-fluid my-3"
                    alt=""
                  />
                  <h2 className="mdl-ttl">OTP Verification</h2>
                  <p className="mdl-sbttl">
                    Please enter 6 digit code, which has been sent on your
                    registered email address
                  </p>
                </div>
                <div className="form-group col-sm-12">
                  <Controller
                    name="otpValues"
                    control={control}
                    render={() => {
                      return (
                        <OtpInput
                          containerStyle={"passcode-wrapper"}
                          value={getValues("otpValues")}
                          onChange={(val) => {
                            setValue("otpValues", val);
                          }}
                          inputStyle="otpInputs"
                          numInputs={6}
                          separator={<span> </span>}
                        />
                      );
                    }}
                  />
                  <span className="text-danger">
                    {errors?.otpValues?.message}
                  </span>
                </div>
                <div className="col-sm-12 mt-4 text-center">
                  <button type="submit" className="btn btn-save">
                    {loading ? <Loader /> : "Verify"}
                  </button>
                </div>
                <div className="col-sm-12 text-center mt-4">
                  <div>
                    {counter === 0 ? (
                      <span
                        className="forgot-pass p-0"
                        onClick={() => {
                          dispatch(
                            ForgotPassword({
                              email: Localemail,
                            })
                          ).then(async (data) => {
                            console.log(data);
                            if (data?.payload?.code === 1) {
                              toast.success(data?.payload?.message);

                              // navigate("/auth/otp", {
                              //   replace: true,
                              // });
                            } else {
                              toast.error(data?.payload?.message);
                            }
                          });
                        }}
                      >
                        Resend Code
                      </span>
                    ) : (
                      <div>Resend code in {format(counter)}</div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-6">
            <div className="logo-bg text-center bg-white">
              <div className="lft-img">
                <img
                  src="assets/images/OTPImg.png"
                  className="img-fluid"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Otp;
