import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

function PrivateRoute({ props }) {
  const isAuthorized = localStorage.getItem("token");
  // const isAuthorized = true;
  if (isAuthorized) {
    return <Outlet />;
  } else {
    return <Navigate to="/" replace />;
  }
}

export default PrivateRoute;
