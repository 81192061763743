import { Drawer } from "antd";
import React from "react";
import moment from "moment";
import "antd/dist/antd.css";

function RequestDetail({ show, handleShow, data }) {
  console.log(data);
  return (
    <>
      <div className="site-drawer-render-in-current-wrapper">
        <Drawer
          title="Request Detail"
          placement="right"
          onClose={handleShow}
          visible={show}
        >
          <div className="ps-3">
            <img
              src={
                data?.studentDetails?.image?.includes("http")
                  ? data?.studentDetails?.image
                  : "assets/images/profile1.png"
              }
              className="detailImgUser"
              alt=""
            />
            <span className="text-capitalize">
              {" "}
              {data
                ? `${data?.studentDetails?.first_name} ${data?.studentDetails?.last_name}`
                : ""}
            </span>
          </div>
          <hr />
          <div className="position-relative ps-4">
            <img
              src="assets/images/title_regular_icon.png"
              className="detailIcontitle"
              alt=""
            />
            <p className="mb-0 text-capitalize">Service Type</p>
            <p className="mb-0 text-capitalize fw-bold">
              {data?.serviceDetails?.title}
            </p>
          </div>
          <hr />
          <div className="position-relative ps-4">
            <img
              src="assets/images/priceIcon.png"
              className="detailIcontitle"
              alt=""
            />
            <p className="mb-0 text-capitalize">price</p>
            <p className="mb-0 text-capitalize fw-bold">{data?.price}</p>
          </div>
          <hr />
          <div className="position-relative ps-4">
            <img
              src="assets/images/dateDetail.png"
              className="detailIcontitle"
              alt=""
            />
            <p className="mb-0 text-capitalize">date</p>
            <p className="mb-0 text-capitalize fw-bold">
              {data?.date ? moment(data?.date).format("DD MMM YYYY") : "NA"}
            </p>
          </div>
        </Drawer>
      </div>
    </>
  );
}

export default RequestDetail;
