import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AddSkill from "../../skills/AddSkill";
import { toast } from "react-toastify";
import { addSpeciality, getAllCareerList } from "../../slice";
import Loader from "../loader/Loader";

function AddSpeciality() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((store) => store.loading);
  const data = useSelector((state) => state.getAllCareerList);
  console.log(data);
  const schema = yup.object({
    speciality_name: yup.string().required("Required"),
    careerId: yup.string().required("Required"),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      addSpeciality({
        title: data.speciality_name,
        career_id: data.careerId,
      })
    ).then(async (data) => {
      console.log(data);
      if (data?.payload?.code === 1) {
        toast.success(data?.payload?.message);

        navigate("/manage-speciality", {
          replace: true,
        });
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };

  const getlist = useCallback(async () => {
    dispatch(
      getAllCareerList({
        limit: 100,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlist();
    }
    return () => {
      mounted = false;
    };
  }, [getlist]);

  if (data.length === 0) {
    return <Loader />;
  } else {
    return (
      <>
        <article className="col-lg-9">
          <div className="article-body store-bd-gy">
            <div className="bg-white pt-3 px-3 rad">
              <div className="row align-items-center col-rever">
                <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                  <h4>Add New Speciality</h4>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-sm-12">
                <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Speciality Name"
                            {...register("speciality_name")}
                          />
                          <img
                            src="assets/images/skills.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {errors.speciality_name && (
                        <span className="text-danger">
                          {errors.speciality_name.message}
                        </span>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group">
                        <div className="input-container">
                          <select
                            className="form-control pl-5 form-select"
                            {...register("careerId")}
                          >
                            <option value="">Career path</option>
                            {data?.map((item, i) => (
                              <option value={item?.id} key={i}>
                                {item?.title}
                              </option>
                            ))}
                          </select>
                          <img
                            src="assets/images/skills.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                      {errors.careerId && (
                        <span className="text-danger">
                          {errors.careerId.message}
                        </span>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-12 text-center">
                      <button type="submit" className="btn btn-save">
                        {loading ? <Loader /> : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </article>
      </>
    );
  }
}

export default AddSpeciality;
