import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import Loader from "../component/loader/Loader";
import {
  addAdminResponse,
  joinMeeting,
  removeRequest,
  requestDetails,
  requestList,
} from "../slice";
import Paginations from "./../component/pagination/Pagination";
import moment from "moment";

function ManageMeeting() {
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState("active");
  const [searchValue, setSearchValue] = useState("");
  const list = useSelector((store) => store.requestLists);
  const total_request = useSelector((store) => store.total_request);
  const adminDetail = useSelector((store) => store.adminDetail);
  const requestDetail = useSelector((store) => store.requestDetail);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);


  const [delId, setDelId] = useState("");

  const confirmDelete = () => {
    dispatch(removeRequest({request_id: delId})).then((res) => {
      if(res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(requestList({
      page: page,
      limit: 10,
    })).then((res) => {
      setLoading(false);
    });
  }, [dispatch, page]);

  useEffect(() => {
    getList();
  }, [getList]);



  //console.log(list, "list ", adminDetail);
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Manage Meetings</h4>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="row">

              <div className="col-sm-12">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className={
                        tabName === "active"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="pills-active-skills"
                      role="tabpanel"
                      aria-labelledby="pills-active-skills-tab"
                    >
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">Conducted By</th>
                              <th scope="col">Joined By</th>
                              {/* <th scope="col">Duration</th> */}
                              <th scope="col">Date</th>
                              <th scope="col">Time</th>
                              <th scope="col">Status</th>
                              <th scope="col">Actions</th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>

                          <tbody>
                            {list && list?.length > 0 ? (
                              list?.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    {item?.userDetails
                                      ? `${item?.userDetails?.first_name} ${item?.userDetails?.last_name}`
                                      : "NA"}
                                  </td>
                                  <td>
                                    <span>
                                      {item?.studentDetails
                                        ? `${item?.studentDetails?.first_name} ${item?.studentDetails?.last_name}`
                                        : "NA"}
                                    </span>
                                  </td>
                                  {/* <td>1H</td> */}
                                  <td>{item?.selectedTimeSlot?.date}
                                    {/* {item?.meetingDetails?.isMeetingActive
                                        item?.selectedTimeSlot ? moment(item?.selectedTimeSlot?.date,'YYYY-MM-DD') :" "
                                    } */}
                                  </td>
                                  <td>
                                    {
                                      item?.selectedTimeSlot ? moment(item?.selectedTimeSlot?.time, 'hh:mm:ss').format('hh:mm A') : " "
                                    }
                                  </td>
                                  <td>
                                    {
                                      item?.status === "0" ? null : item?.status === "2" && item?.meetingDetails?.isMeetingActive ? "Running" : item?.status === "4" ? "Completed" : item?.status === "3" ? "Cancelled" : "Availability"
                                    }
                                  </td>
                                  <td>
                                    {
                                      // item?.staus == "2"
                                      // item?.meetingDetails &&(
                                      <div
                                        className="join_now_btn"
                                        style={{
                                          backgroundColor: item?.meetingDetails?.isMeetingActive ? '#032F3C' : '#808080',
                                          // backgroundColor: moment(item?.selectedTimeSlot?.time, 'hh:mm:ss').format("hh:mm") === currentTime? '#032F3C': '#808080',
                                        }}
                                        onClick={() => {
                                          console.log("meee", moment(item?.selectedTimeSlot?.time, 'hh:mm:ss').format("hh:mm"));
                                          if(item?.meetingDetails?.isMeetingActive) {
                                            let data = {
                                              meetingId: item?.meetingDetails?.meetingId,
                                              attendeeName: `${adminDetail?.name}`,
                                              attendeePW: item?.meetingDetails?.studentPW,
                                            }
                                            dispatch(joinMeeting(data)).then((resp) => {
                                              console.log("response ", resp?.payload?.data);
                                              window.open(resp?.payload?.data?.meeting_link, "_blank")
                                            })
                                          } else {
                                            toast.error("your meeting will start soon");
                                          }
                                        }}
                                      >
                                        <span >Join Meeting</span>
                                      </div>
                                      // )
                                    }
                                  </td>


                                  {/* <td>
                                    <div
                                      className="d-flex action"
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "baseline",
                                      }}
                                    >
                                      
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          getDetails(item?.id);
                                        }}
                                      >
                                        <img
                                          src="assets/images/view.png"
                                          className="sha-up"
                                          alt=""
                                        />
                                      </button>
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          setDelId(item.id);
                                          setShow(!show);
                                          setDelName(
                                            item?.studentDetails?.first_name +
                                              " " +
                                              item?.studentDetails?.last_name
                                          );
                                          setShowFor("delete");
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </td> */}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={3}>No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {list?.length > 0 && (
                          <div className="col-sm-12">
                            <Paginations
                              page={page}
                              total={total_request && total_request}
                              limit={10}
                              handlePage={(val) => {
                                setPage(val);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {delName && showFor === "delete" && (
        <DeleteModal
          show={show}
          handleShow={() => {
            setShow(!show);
            setDelId("");
            setDelName("");
            setShowFor("");
          }}
          DelSkillName={delName}
          confirmDelete={confirmDelete}
        />
      )}
      {showFor === "detail" && (
        <RequestDetail
          show={show}
          handleShow={() => {
            setShow(!show);
          }}
          data={requestDetail}
        />
      )} */}
    </article>
  );
}

export default ManageMeeting;
