import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { afterRoutes } from "../../routes";
import { useSelector } from "react-redux";

function Sidebar() {
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const { pathname } = location;
  // this need to come from sub-admin detail
  const permission = useSelector((state) => state.adminDetail);
  const [show, setShow] = useState(false);

  const hasAccess = permission?.assigned_roles;

  const navigate = useNavigate();

  async function logout(e) {
    await localStorage.clear();
    navigate("/");
  }

  return (
    <div className="col-lg-3 store-sidebar admin-sidebar">
      <div className="shadow-part">
        <span className="navbar-brand bg-none px-3 mx-lg-auto d-lg-block">
          <img src="assets/images/logo.png" className="img-fluid" alt="img" />
        </span>
        <button
          className="btn btn-blue float-end menu-toggle d-lg-none"
          onClick={() => {
            setShow(!show);
          }}
        >
          <i className="fa fa-bars"></i>
        </button>
        <nav
          className={show ? "collapse d-lg-block show" : "collapse d-lg-block"}
          id="sidebar"
        >
          <ul className="list-unstyled mb-0">
            {afterRoutes?.map((props, i) => {
              if (props.hidden) {
                return false;
              } else {
                if (permission?.admin_type === "super-admin") {
                  return (
                    <li key={i}>
                      <Link
                        to={props.path}
                        className={
                          location.pathname.includes(props.subName)
                            ? "active"
                            : ""
                        }
                      >
                        <div>
                          <img
                            src={`${props.imgInactive}` || undefined}
                            className="img-fluid sidebar-icon inactive-icon"
                            alt=""
                          />
                          <img
                            src={props.imgActive}
                            className="img-fluid sidebar-icon active-icon"
                            alt=""
                          />
                        </div>
                        <span>{props.name}</span>
                      </Link>
                    </li>
                  );
                } else {
                  for (const key in hasAccess) {
                    if (Object.hasOwnProperty.call(hasAccess, key)) {
                      if (
                        key.includes(props.subName) &&
                        hasAccess[key] === true
                      ) {
                        return (
                          <li key={i}>
                            <Link
                              to={props.path}
                              className={
                                location.pathname.includes(props.subName)
                                  ? "active"
                                  : ""
                              }
                            >
                              <div>
                                <img
                                  src={`${props.imgInactive}` || undefined}
                                  className="img-fluid sidebar-icon inactive-icon"
                                  alt=""
                                />
                                <img
                                  src={props.imgActive}
                                  className="img-fluid sidebar-icon active-icon"
                                  alt=""
                                />
                              </div>
                              <span>{props.name}</span>
                            </Link>
                          </li>
                        );
                      }
                    }
                  }
                }
              }
            })}

            <li
              className={pathname.includes("change-password") ? "sb-act" : ""}
            >
              <a
                href={() => {
                  return "false";
                }}
                className={
                  pathname.includes("change-password")
                    ? "show-collape-border  cursor-pointer active"
                    : "show-collape-border collapsed  cursor-pointer"
                }
                aria-expanded="false"
                aria-controls="setting"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <div>
                  <img
                    src="assets/images/Settings.png"
                    className="img-fluid sidebar-icon inactive-icon"
                    alt=""
                  />
                  <img
                    src="assets/images/Settings-selected.png"
                    className="img-fluid sidebar-icon active-icon"
                    alt=""
                  />
                </div>
                <span>setting </span>
              </a>
              <div
                className={
                  toggle
                    ? "collapse collapse-border show"
                    : "collapse collapse-border"
                }
                id="setting"
              >
                <Link
                  className={
                    pathname.includes("change-password")
                      ? "dropdown-item active"
                      : "dropdown-item "
                  }
                  to="/change-password"
                >
                  <span>change password</span>
                </Link>
                <Link
                  className="dropdown-item"
                  to={() => {
                    return false;
                  }}
                  onClick={(e) => {
                    logout(e);
                  }}
                >
                  <span>
                    logout
                    <img
                      src="assets/images/logout-selected.png"
                      className="img-fluid sidebar-icon ms-2"
                      alt=""
                    />
                  </span>
                </Link>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
