import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "./../component/loader/Loader";
import { login } from "../slice";
import { toast } from "react-toastify";
import { afterRoutes, authRoutes } from "../../routes";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const loading = useSelector((store) => store.loading);
  const handleToggle = () => {
    setToggle(!toggle);
  };

  const schema = yup.object({
    email: yup
      .string()
      .email("Kindly enter a valid email address.")
      .required("Required"),
    password: yup.string().min(8).required("Required"),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      login({
        email: data.email,
        password: data.password,
      })
    ).then((data) => {
      if (data?.payload?.code === 1) {
        toast.success(data?.payload?.message);
        if (data?.payload?.data?.admin_type === "sub-admin") {
          let roles = data?.payload?.data?.assigned_roles;

          for (const key in roles) {
            if (Object.hasOwnProperty.call(roles, key)) {
              if (roles[key] === true) {
                const slicedKey = key.replaceAll("_", "-");
                const firstMatchedRole = afterRoutes.find((item) => {
                  return (
                    item.hidden === false &&
                    slicedKey === item.path.replace("/", "")
                  );
                });
                navigate(firstMatchedRole.path, { replace: true });
                break;
              }
            }
          }
        } else {
          navigate("/manage-career-path", {
            replace: true,
          });
        }
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };
  return (
    <section className="">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-7 col-lg-6">
            <div className="">
              <img
                src="assets/images/logo.png"
                className="img-fluid logo-admin"
                alt="logo"
              />
            </div>
            <div className="form-bg">
              <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-12 col-md-12 text-center mb-5">
                    <h2 className="mdl-ttl">Log In</h2>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="email"
                          className="form-control pl-5"
                          placeholder="Email ID"
                          {...register("email")}
                        />
                        <img
                          src="assets/images/Email-Address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          id="password-field"
                          type={toggle ? "text" : "password"}
                          className="form-control pl-5"
                          placeholder="Password"
                          {...register("password")}
                        />
                        <img
                          src="assets/images/password.png"
                          className="input-img"
                          alt=""
                        />
                        <span
                          onClick={() => {
                            handleToggle();
                          }}
                          className={
                            toggle
                              ? "pass-hide field-icon toggle-password"
                              : "pass-view field-icon toggle-password"
                          }
                        ></span>
                      </div>
                      {errors.password && (
                        <span className="text-danger">
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 text-center mb-4">
                    <Link to="/auth/forget-password" className="forgot-pass">
                      Forgotten Password?
                    </Link>
                  </div>
                  <div className="col-sm-12 col-md-12 text-center">
                    <button type="submit" className="btn btn-save">
                      {loading ? <Loader /> : "Login"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-6">
            <div className="logo-bg text-center bg-white">
              <div className="lft-img">
                <img
                  src="assets/images/AdminLoginImg.png"
                  className="img-fluid"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
