import * as yup from "yup";

export const schema = yup.object({
  title: yup.string().required("Required"),
  description: yup.string().required("Required"),
  skill_description: yup.string().required("Required"),
  image: yup.string().required("Required"),
  effective_tools_description: yup.string().required("Required"),
  certifications: yup.string().required("Required"),
  career_options: yup.string().required("Required"),
  core_skills: yup
    .array()
    .of(yup.object().shape({ skill_id: yup.string(), title: yup.string() }))
    .min(1, "Required")
    .typeError("Required"),
  booster_skills: yup
    .array()
    .of(yup.object().shape({ skill_id: yup.string(), title: yup.string() }))
    .nullable()
    .notRequired(),
  effective_tools: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().nullable().notRequired(),
        value: yup.string().nullable().notRequired(),
        __isNew__: yup.boolean(),
      })
    )
    .min(1, "Required")
    .typeError("Required"),
});

export const addCounsellerSchema = yup.object().shape({
  image: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  email: yup
    .string()
    .email("Kiundly enter a valid email.")
    .required("Required"),
  secondary_email: yup
    .string()
    .email("Kiundly enter a valid email.")
    .nullable()
    .notRequired(),
  gender: yup
    .string()
    .typeError("Kindly select a gender.")
    .required("Required"),
  designation: yup.string().required("Required"),
  company_name: yup.string().required("Required"),
  password: yup.string().required("Required"),

  about: yup.string().required("Required"),
  city: yup.string().typeError("Required").required("Required"),
  state: yup.string().typeError("Required").required("Required"),
  country: yup.string().typeError("Required").required("Required"),
  experience: yup
    .number()
    .positive()
    .integer()
    .typeError("Kindly enter a valid number.")
    .required("Required"),
  mobile: yup
    .number()
    .positive()
    .integer()
    .typeError("Kindly enter a valid phone number.")
    .required("Required"),
  countryCode: yup
    .number()
    .positive()
    .integer()
    .typeError("Kindly enter a valid phone number."),
  secondary_mobile: yup
    .number()
    .positive()
    .integer()
    .typeError("Kindly enter a valid phone number.")
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null))
    .notRequired(),
  secoundary_countryCode: yup
    .number()
    .positive()
    .integer()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null))
    .notRequired(),
  city_isocode: yup.string(),
  state_isocode: yup.string(),

  career_data: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        title: yup.string(),
      })
    )
    .min(1, "Required")
    .typeError("Required"),
  service_data: yup
    .array()
    .of(
      yup.object().shape({
        service_id: yup
          .object()
          .shape({
            id: yup.string(),
            title: yup.string(),
          })
          .required("Required")
          .typeError("Required"),
        fee: yup
          .number()
          .integer()
          .positive()
          .required("Required")
          .typeError("Kindly enter a valid number."),
      })
    )
    .min(1, "Required")
    .typeError("Required"),
});
export const editCounsellerSchema = yup.object().shape({
  image: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  email: yup
    .string()
    .email("Kiundly enter a valid email.")
    .required("Required"),
  secondary_email: yup
    .string()
    .email("Kiundly enter a valid email.")
    .nullable()
    .notRequired(),
  gender: yup
    .string()
    .typeError("Kindly select a gender.")
    .required("Required"),
  designation: yup.string().required("Required"),
  company_name: yup.string().required("Required"),
  about: yup.string().required("Required"),
  city: yup.string().typeError("Required").required("Required"),
  state: yup.string().typeError("Required").required("Required"),
  country: yup.string().typeError("Required").required("Required"),
  experience: yup
    .number()
    .positive()
    .integer()
    .typeError("Kindly enter a valid number.")
    .required("Required"),
  mobile: yup
    .number()
    .positive()
    .integer()
    .typeError("Kindly enter a valid phone number.")
    .required("Required"),
  countryCode: yup
    .number()
    .positive()
    .integer()
    .typeError("Kindly enter a valid phone number."),
  secondary_mobile: yup
    .number()
    .positive()
    .integer()
    .typeError("Kindly enter a valid phone number.")
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null))
    .notRequired(),
  secoundary_countryCode: yup
    .number()
    .positive()
    .integer()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null))
    .notRequired(),
  city_isocode: yup.string(),
  state_isocode: yup.string(),

  career_data: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        title: yup.string(),
      })
    )
    .min(1, "Required")
    .typeError("Required"),
  service_data: yup
    .array()
    .of(
      yup.object().shape({
        service_id: yup
          .object()
          .shape({
            id: yup.string(),
            title: yup.string(),
          })
          .required("Required")
          .typeError("Required"),
        fee: yup
          .number()
          .integer()
          .positive()
          .required("Required")
          .typeError("Kindly enter a valid number."),
      })
    )
    .min(1, "Required")
    .typeError("Required"),
});

export const studentBasicInfoSchema = yup.object().shape({
  first_name: yup.string().typeError("Required").required("Required"),
  last_name: yup.string().typeError("Required").required("Required"),
  city: yup.string().typeError("Required").required("Required"),
  state: yup.string().typeError("Required").required("Required"),
  country: yup.string().typeError("Required").required("Required"),
  image: yup.string().typeError("Required").required("Required"),
  about: yup.string().typeError("Required").required("Required"),
  password: yup.string().typeError("Required").required("Required"),
  email: yup
    .string()
    .email("Kindly enter a valid email address.")
    .required("Required"),
  city_isocode: yup.string().nullable(),
  state_isocode: yup.string().nullable(),
});

export const addStudentDegreeSchema = yup.object().shape({
  degree: yup.array().of(
    yup.object().shape({
      college_name: yup.string().required("Required"),
      degree: yup.string().required("Required"),
      field_of_study: yup.string().required("Required"),
      start_year: yup.string().required("Required").typeError("Required"),
      end_year: yup.string().required("Required").typeError("Required"),
      college_city: yup.string().typeError("Required").required("Required"),
      college_state: yup.string().typeError("Required").required("Required"),
      college_country: yup.string().typeError("Required").required("Required"),
    })
  ),
});

export const addStudentCareerPathSchema = yup.object().shape({
  career: yup.array().of(
    yup.object().shape({
      careerPath: yup.string().required("Required"),
      specializations: yup
        .array()

        .of(
          yup.object().shape({
            id: yup.string(),
            career_id: yup.string(),
            name: yup.string(),
          })
        )
        .nullable()
        .notRequired(),
    })
  ),
});

export const addStudentSkillsSchema = yup.object().shape({
  skills: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Required"),
      star: yup.number().positive().required("Required").typeError("Required"),
      id: yup.string(),
      skill_type: yup.string(),
    })
  ),
});

export const addForumSchema = yup.object().shape({
  image: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  title: yup.string().required("Required"),
  date: yup.string().required("Required"),
  tags: yup.array().required("Required"),
  description: yup.string().required("Required"),
  link: yup.string().notRequired().nullable(),
});

export const addOpportunitySchema = yup.object().shape({
  image: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  title: yup.string().required("Required"),
  personaTag: yup.string().required("Required"),
  collaborationTag: yup.string().required("Required"),
  date: yup.string().required("Required"),
  description: yup.string().required("Required"),
});

export const addSubadminSchema = yup.object().shape({
  id: yup.string(),
  image: yup.string().required("Required"),
  name: yup.string().required("Required"),
  email: yup.string().required("Required"),
  password: yup.string().when("id", {
    is: (item) => item?.length < 0,
    then: yup.string().required("Field is required"),
  }),
  manage_career_path: yup.boolean(),
  manage_speciality: yup.boolean(),
  manage_skill: yup.boolean(),
  manage_student: yup.boolean(),
  manage_counseller: yup.boolean(),
  manage_mentor: yup.boolean(),
  manage_request: yup.boolean(),
  manage_forum: yup.boolean(),
  manage_withdrawal: yup.boolean(),
});

// subAdminId
