import React, { useState } from "react";
import Pagination from "react-js-pagination";
import "./pagination.css";
function Paginations({ handlePage, page, total, limit }) {
  console.log("total pagination count ", total, page);
  const [counter, setCounter] = useState(page);

  const onChangeHandler = (value) => {
    console.log("value of handle paGE", value);
    setCounter(value);
    handlePage(value);
  };
  // let limit = 10;
  console.log(counter, "counter", limit)
  return (
    <>
      <div className="d-flex justify-content-end">
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          itemsCountPerPage={limit}
          activePage={Number(counter)}
          totalItemsCount={Number(total)}
          pageRangeDisplayed={5}
          onChange={onChangeHandler}
        />
      </div>
    </>
  );
}

export default Paginations;
