import React, { useState } from "react";
import { changeMeetingLink } from "../slice";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";

const ChangeMeetingLinks = ({showChangeLinkPopup, showChangeLinkId, handleMeetingPopupShow, handleMeetingPopupClose, getList}) => {
    const dispatch = useDispatch();
    const [meetingLink, setMeetingLink] = useState("");

    const handleSubmition = (e) => {
		e.preventDefault()
		
		dispatch(changeMeetingLink({
			meeting_link: meetingLink,
            meetingId:showChangeLinkId
		})).then(res => {
			getList();
			console.log(res, "submit festival list")
            setMeetingLink("")
		}).catch(err => {
			console.log(err)
		})

        handleMeetingPopupClose();

	}
  return (
    <>
      <Modal show={showChangeLinkPopup} onHide={handleMeetingPopupClose} centered>
        <Modal.Body className="modal-container p-4">
          <h3 style={{ borderBottom: " 1px solid black" }}>Change Meeting Link</h3>
          <form onSubmit={handleSubmition}>
            <label htmlFor="speakerName" className="festival-name-label">
              Enter Meeting Link :{" "}
            </label>
            <br />
            <input
              type="text"
              name="meetingLink"
              id="speakerName"
              value={meetingLink}
              onChange={(event) => {
                setMeetingLink(event.target.value)
              }}
              required
            />
            <br />
            

            <div className="festival-add-btn">
              <button type="submit">Add</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangeMeetingLinks;
