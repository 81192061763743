import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "./../component/loader/Loader";
import { changePassword, resetPassword } from "../slice";
import { toast } from "react-toastify";

function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [toogleFor, setToogleFor] = useState("");
  const loading = useSelector((store) => store.loading);
  const handleToggle = () => {
    setToggle(!toggle);
  };

  const schema = yup.object({
    CurrentPassword: yup.string().required("Required"),
    newPassword: yup
      .string()
      .min(8, "Password must be at least 8 character.")
      .required("Required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Password do not match.")
      .required("Required"),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      changePassword({
        current_password: data.CurrentPassword,
        new_password: data.newPassword,
      })
    ).then(async (data) => {
      console.log(data);
      if (data?.payload?.code === 1) {
        toast.success(data?.payload?.message);

        reset();
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };
  console.log(errors);
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="bg-white pt-3 px-3 rad">
            <div className="row align-items-center col-rever">
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <h4>Setting</h4>
              </div>
            </div>
          </div>
          <div className="form-style mt-5  addFormMw">
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                <div className="">
                  <img
                    src="assets/images/change-password.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                <h2 className="mdl-ttl">Change Password</h2>
                <p>Please change your password here</p>
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                <div className="input-container">
                  <input
                    className="form-control pl-5"
                    placeholder="Current Password"
                    type={
                      toggle && toogleFor === "CurrentPassword"
                        ? "text"
                        : "password"
                    }
                    {...register("CurrentPassword")}
                  />
                  <img
                    src="assets/images/password.png"
                    className="input-img"
                    alt=""
                  />
                  <span
                    onClick={() => {
                      setToogleFor("CurrentPassword");
                      handleToggle();
                    }}
                    className={
                      toggle && toogleFor === "CurrentPassword"
                        ? "pass-hide field-icon toggle-password"
                        : "pass-view field-icon toggle-password"
                    }
                  ></span>
                </div>
                {errors.CurrentPassword && (
                  <span className="text-danger">
                    {errors.CurrentPassword.message}
                  </span>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                <div className="input-container">
                  <p>
                    <Link
                      to="/auth/forget-password"
                      className="theme-color fw-bold"
                    >
                      Forgot Password?
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="form-group">
                  <div className="input-container">
                    <input
                      id="password-field"
                      type={
                        toggle && toogleFor === "newPassword"
                          ? "text"
                          : "password"
                      }
                      className="form-control pl-5"
                      placeholder="New Password"
                      {...register("newPassword")}
                    />
                    <img
                      src="assets/images/password.png"
                      className="input-img"
                      alt=""
                    />
                    <span
                      onClick={() => {
                        setToogleFor("newPassword");
                        handleToggle();
                      }}
                      className={
                        toggle && toogleFor === "newPassword"
                          ? "pass-hide field-icon toggle-password"
                          : "pass-view field-icon toggle-password"
                      }
                    ></span>
                  </div>
                </div>
                {errors.newPassword && (
                  <span className="text-danger">
                    {errors.newPassword.message}
                  </span>
                )}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="form-group">
                  <div className="input-container">
                    <input
                      type={
                        toggle && toogleFor === "confirmPassword"
                          ? "text"
                          : "password"
                      }
                      className="form-control pl-5"
                      placeholder="Confirm New Password"
                      {...register("confirmPassword")}
                    />
                    <img
                      src="assets/images/password.png"
                      className="input-img"
                      alt=""
                    />
                    <span
                      onClick={() => {
                        setToogleFor("confirmPassword");
                        handleToggle();
                      }}
                      className={
                        toggle && toogleFor === "confirmPassword"
                          ? "pass-hide field-icon toggle-password"
                          : "pass-view field-icon toggle-password"
                      }
                    ></span>
                  </div>
                </div>
                {errors.confirmPassword && (
                  <span className="text-danger">
                    {errors.confirmPassword.message}
                  </span>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center">
                <button type="submit" className="btn btn-save">
                  {loading ? <Loader /> : "Done"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </article>
    </>
  );
}

export default ChangePassword;
