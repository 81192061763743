import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  activateDeactivateOpportunity,
  opportunityList,
  removeData,
  removeOpportunity,
  removeOpportunityComment,
} from "../slice";
import Paginations from "./../component/pagination/Pagination";
import moment from "moment";
import DeleteModal from "../component/modal/DeleteModal";

function Forum() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabName, setTabName] = useState("active");
  const [searchValue, setSearchValue] = useState("");
  const list = useSelector((store) => store.opportunityListsData);
  const total_active_opportunity = useSelector(
    (store) => store.total_active_opportunity
  );
  const total_inactive_opportunity = useSelector(
    (store) => store.total_inactive_opportunity
  );
  const opportunityDetail = useSelector((store) => store.opportunityDetail);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(1);
  const [show, setShow] = useState(false);
  const [delOpportunityId, setDdelOpportunityId] = useState("");
  const [delName, setDelName] = useState("");


  const getList = useCallback(() => {
    setLoading(true);

    dispatch(opportunityList({ page: page, limit: 10, status })).then(() => {
      setLoading(false);
    });
  }, [dispatch, page, status]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleToggle = (data) => {
    dispatch(
      activateDeactivateOpportunity({
        data: data,
        params: {
          page: page,
          limit: 10,
          status,
          //   search: searchValue,
        },
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        getList();
      } else {
        toast.success(res?.payload?.message);
      }
    });
  };

  const confirmDelete = () => {
    dispatch(
      removeOpportunity({
        objec: { opportunityId : delOpportunityId },
        // param: {
        //   search: searchValue,
        //   page: page,
        //   limit: 12,
        //   status: status,
        // },
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        setDdelOpportunityId("")
        getList();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Opportunities</h4>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Student Name and Opportunity Title.."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </form>
                <Link to="/add-opportunity" className="btn add-new">
                  +
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-9">
                <ul
                  className="nav nav-pills group-nav mb-3 mt-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "active" ? "nav-link active" : "nav-link"
                      }
                      id="pills-active-skills-tab"
                      type="button"
                      role="tab"
                      aria-controls="pills-active-skills"
                      aria-selected="true"
                      onClick={() => {
                        setStatus(1);
                        setPage(1);
                        setTabName("active");
                      }}
                    >
                      active
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "inactive" ? "nav-link active" : "nav-link"
                      }
                      id="pills-inactive-skills-tab"
                      onClick={() => {
                        setStatus(0);
                        setPage(1);
                        setTabName("inactive");
                      }}
                      type="button"
                      role="tab"
                      aria-controls="pills-inactive-skills"
                      aria-selected="false"
                    >
                      inactive
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-12">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={
                      tabName === "active"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-active-skills"
                    role="tabpanel"
                    aria-labelledby="pills-active-skills-tab"
                  >
                    <div className="table-responsive text-center">
                      <table className="table storetable">
                        <thead className="text-center">
                          <tr>
                            <th scope="col">Profile</th>
                            <th scope="col">Title</th>
                            {/* <th scope="col">Phone Number</th> */}
                            <th scope="col">Description</th>
                            <th scope="col">Collaboration Tag</th>
                            <th scope="col">Persona Tag</th>
                            <th scope="col">Replies</th>
                            <th scope="col">Views</th>
                            <th scope="col">Upvotes</th>
                            <th scope="col">Date</th>
                            <th scope="col">Actions</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>

                        <tbody>
                          {list && list?.length > 0 ? (
                            list?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={
                                      item?.userDetails?.image
                                        ? item.userDetails.image
                                        : "assets/images/profile1.png"
                                    }
                                    alt=""
                                    className="user-image"
                                  />
                                  <div style={{fontWeight:"600"}}>{item?.userDetails?.first_name}</div>
                                  <div style={{fontWeight:"600"}}>{item?.userDetails?.last_name}</div>
                                </td>
                                <td>
                                  <span className="ellips-2">
                                    {item?.title || "NA"}
                                  </span>
                                </td>
                                {/* <td>
                                {item?.user_other_details?.mobile
                                  ? `${item?.user_other_details?.countryCode}${item?.user_other_details?.mobile}`
                                  : "NA"}
                              </td> */}
                                <td>
                                  {item?.description
                                    ? item?.description.slice(0, 50) + "..."
                                    : "NA"}
                                </td>

                                <td>
                                  <span className="ellips-2">
                                    {" "}
                                    {item?.collaborationTag
                                      ? item.collaborationTag
                                      : "NA"}
                                  </span>
                                </td>

                                <td>
                                  <span className="ellips-2">
                                    {" "}
                                    {item?.personaTag ? item.personaTag : "NA"}
                                  </span>
                                </td>
                                <td>{item?.total_comment || "0"}</td>
                                <td>{item?.total_like || "0"}</td>
                                <td>{item?.total_share || "0"}</td>
                                <td>
                                  {item?.createdAt
                                    ? moment(item?.createdAt).format(
                                        "DD MMM YYYY"
                                      )
                                    : "0"}
                                </td>

                                <td>
                                  <div
                                    className="d-flex justify-content-center action"
                                    style={{
                                      alignItems: "center",
                                    }}
                                  >
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={item?.isActive}
                                        onChange={() => {
                                          handleToggle({
                                            opportunityId: item.id,
                                            status: !item.isActive,
                                          });
                                        }}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <Link
                                      to={`/edit-opportunity/${item?.id}`}
                                      className="btn EditApointment"
                                    >
                                      <img
                                        src="assets/images/edit.png"
                                        alt=""
                                        width="20px"
                                      />
                                    </Link>
                                    <button
                                      className="btn"
                                      onClick={() => {
                                        dispatch(
                                          removeData({
                                            key: "opportunityDetail",
                                          })
                                        );
                                        navigate(`/opportunity/${item?.id}`);
                                      }}
                                    >
                                      <img
                                        src="assets/images/view.png"
                                        // className="sha-up"
                                        alt=""
                                      />
                                    </button>

                                    <button
                                        className="btn"
                                        onClick={() => {
                                            setDdelOpportunityId(item.id);
                                            setShow(!show);
                                            setDelName(item?.title);
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                        />
                                      </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10}>No Data Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {list?.length > 0 && searchValue === "" && (
                        <div className="col-sm-12">
                          <Paginations
                            page={page}
                            total={
                              total_active_opportunity &&
                              total_active_opportunity
                            }
                            limit={10}
                            handlePage={(val) => {
                              setPage(val);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      tabName === "inactive"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-inactive-skills"
                    role="tabpanel"
                    aria-labelledby="pills-inactive-skills-tab"
                  >
                    <div className="table-responsive text-center">
                      <table className="table storetable">
                        <thead className="text-center">
                          <tr>
                            <th scope="col">Profile</th>
                            <th scope="col">Title</th>
                            {/* <th scope="col">Phone Number</th> */}
                            <th scope="col">Description</th>
                            <th scope="col">Collaboration Tag</th>
                            <th scope="col">Persona Tag</th>
                            <th scope="col">Replies</th>
                            <th scope="col">Views</th>
                            <th scope="col">Upvotes</th>
                            <th scope="col">Date</th>
                            <th scope="col">Actions</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>

                        <tbody>
                          {list && list?.length > 0 ? (
                            list?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={
                                      item?.userDetails?.image
                                        ? item.userDetails.image
                                        : "assets/images/profile1.png"
                                    }
                                    alt=""
                                    className="user-image"
                                  />
                                </td>
                                <td>
                                  <span className="ellips-2">
                                    {item?.title || "NA"}
                                  </span>
                                </td>
                                {/* <td>
                                {item?.user_other_details?.mobile
                                  ? `${item?.user_other_details?.countryCode}${item?.user_other_details?.mobile}`
                                  : "NA"}
                              </td> */}
                                <td>
                                  {item?.description
                                    ? item?.description.slice(0, 20)
                                    : "NA"}
                                </td>

                                <td>
                                  <span className="ellips-2">
                                    {" "}
                                    {item?.collaborationTag
                                      ? item.collaborationTag
                                      : "NA"}
                                  </span>
                                </td>

                                <td>
                                  <span className="ellips-2">
                                    {" "}
                                    {item?.personaTag ? item.personaTag : "NA"}
                                  </span>
                                </td>
                                <td>{item?.total_comment || "0"}</td>
                                <td>{item?.total_like || "0"}</td>
                                <td>{item?.total_share || "0"}</td>
                                <td>
                                  {item?.createdAt
                                    ? moment(item?.createdAt).format(
                                        "DD MMM YYYY"
                                      )
                                    : "0"}
                                </td>

                                <td>
                                  <div
                                    className="d-flex justify-content-center action"
                                    style={{
                                      alignItems: "baseline",
                                    }}
                                  >
                                    {/* <button className="btn">
                              <img
                                src="assets/images/application-rejected.png"
                                alt=""
                              />
                            </button> */}
                                    <span>
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          checked={item?.isActive}
                                          onChange={() => {
                                            handleToggle({
                                              opportunityId: item.id,
                                              status: !item.isActive,
                                            });
                                          }}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </span>
                                    <Link
                                      to={`/edit-opportunity/${item?.id}`}
                                      className="btn EditApointment"
                                    >
                                      <img
                                        src="assets/images/edit.png"
                                        alt=""
                                        width="20px"
                                      />
                                    </Link>
                                    <button
                                      className="btn"
                                      onClick={() => {
                                        dispatch(
                                          removeData({
                                            key: "opportunityDetail",
                                          })
                                        );
                                        navigate(`/opportunity/${item?.id}`);
                                      }}
                                    >
                                      <img
                                        src="assets/images/view.png"
                                        // className="sha-up"
                                        alt=""
                                      />
                                    </button>

                                    <button
                                        className="btn"
                                        onClick={() => {
                                            setDdelOpportunityId(item.id);
                                            setShow(!show);
                                            setDelName(item?.title);
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                        />
                                      </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10}>No Data Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {list?.length > 0 && searchValue === "" && (
                        <div className="col-sm-12">
                          <Paginations
                            page={page}
                            total={
                              total_inactive_opportunity &&
                              total_inactive_opportunity
                            }
                            limit={10}
                            handlePage={(val) => {
                              setPage(val);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={show}
        handleShow={() => {
          setShow(!show);
          setDelName("");
        }}
        DelSkillName={delName}
        confirmDelete={confirmDelete}
      />
    </article>
  );
}

export default Forum;
