import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "./../component/loader/Loader";
import { toast } from "react-toastify";
import {
  addAndUpdateCertifications,
  getAllCareerList,
  getAllPersonaList,
} from "../slice";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import S3FileUpload from "react-s3";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { config, formats, toolbarOptions } from "./../../utils/index";
import { FaStar } from "react-icons/fa";
import "./helper/StarRating.css";
window.Buffer = window.Buffer || require("buffer").Buffer;

function AddCertification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((store) => store.loading);
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [personaList, setPersonaList] = useState([]);
  const [careerPath, setCareerPath] = useState([]);
  const [visibiltyRole, setVisibiltyRole] = useState([]);
  const [isDollar, setIsDollar] = useState(false);
  const [priceType, setPriceType] = useState("₹");

  console.log(priceType);

  // const data = useSelector((state) => state.getAllCareerList);

  const [checkboxesRole, setCheckboxesRole] = useState({
    student: false,
    counsellor: false,
    mentor: false,
  });

  const handleVisibiltyRoleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxesRole((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: checked,
    }));
    
  };

  console.log("checkbox role", checkboxesRole);

  const schema = yup.object({
    title: yup.string().required("Required"),
    career: yup.string().required("Required"),
  });

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    console.log("hello submit");
    let newRoleArray = [];
    if(checkboxesRole?.student){
      newRoleArray.push("student")
    }
    if(checkboxesRole?.counsellor){
      newRoleArray.push("counsellor")
    }
    if(checkboxesRole?.mentor){
      newRoleArray.push("mentor")
    }
    console.log("visibility Role ", visibiltyRole);
    console.log("visibility Role 2", newRoleArray, typeof newRoleArray);
    dispatch(
      addAndUpdateCertifications({
        image: data?.image,
        title: data?.title,
        pre_header: data?.pre_header,
        visibilty_role: "student",
        visibiltyRole: JSON.stringify(newRoleArray),
        product_type: data?.product_type,
        description: data?.description,
        organization_name: data?.organization_name,
        organization_logo: data?.organization_logo,
        certification_level: data?.certification_level,
        career: data?.career,
        persona: data?.persona,
        rating: rating,
        review_count: data?.review_count,
        price: data?.price,
        price_type: priceType,
        affiliate_link: data?.affiliate_link,
        est_completion_time: data?.est_completion_time,
      })
    ).then(async (data) => {
      console.log(data);
      if (data?.payload?.code === 1) {
        toast.success(data?.payload?.message);

        navigate("/manage-certifications", {
          replace: true,
        });
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };


  useEffect(() => {
    dispatch(getAllPersonaList({})).then((res) => {
      if (res?.payload?.code === 1) {
        // toast.success(res?.payload?.message);
        setPersonaList([...res?.payload?.data?.career_personas_list]);
      } else {
        setPersonaList([]);
        // toast.error(res?.payload?.message);
      }
    });
    dispatch(getAllCareerList({})).then((res) => {
      if (res?.payload?.code === 1) {
        // toast.success(res?.payload?.message);
        setCareerPath([...res?.payload?.data?.career_list]);
      } else {
        setCareerPath([]);
        // toast.error(res?.payload?.message);
      }
    });
  }, []);

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Add New Certification</h4>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-12">
            <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group text-center">
                    <div className="upload-btn-wrapper">
                      <button className="btn">
                        {time > 0 && isActive ? (
                          <Progress type="circle" width={80} percent={time} />
                        ) : (
                          <img
                            src={
                              getValues("image")
                                ? getValues("image")
                                : "assets/images/upload-img.png"
                            }
                            alt=""
                          />
                        )}

                        {time > 0 && isActive ? null : (
                          <div className="camera-icon">
                            <i className="fa fa-camera"></i>
                          </div>
                        )}
                      </button>
                      <input
                        type="file"
                        // {...register("image")}
                        onChange={(e) => {
                          console.log("hello");
                          if (e.target.files.length > 0) {
                            setIsActive(true);
                            S3FileUpload.uploadFile(e.target.files[0], config)
                              .then((data) => {
                                console.log(data.location);
                                setValue("image", data.location);
                                setTime(100);
                                setIsActive(true);
                                setTimeout(() => {
                                  setTime(0);
                                  setIsActive(false);
                                }, 3000);
                              })
                              .catch((data) => console.log(data));
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Certification Title"
                        {...register("title")}
                      />
                      <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {errors.title && (
                    <span className="text-danger">{errors.title?.message}</span>
                  )}
                </div>
                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Type of Product"
                        {...register("product_type")}
                      />
                      <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {errors.product_type && (
                    <span className="text-danger">
                      {errors.product_type?.message}
                    </span>
                  )}
                </div>
                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Pre Header"
                        {...register("pre_header")}
                      />
                      <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* {errors.title && (
                    <span className="text-danger">{errors.title?.message}</span>
                  )} */}
                </div>
                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group form-control border-0 shadow quillCOntainer">
                    <h6 className="my-3">Description</h6>
                    {/* <div className="input-container"> */}
                    {/* <textarea
                        className="textarea-control rad form-control ps-3 shadow"
                        rows="7"
                        placeholder="Certifications"
                        {...register("certifications")}
                      ></textarea> */}

                    {/* </div> */}
                    <Controller
                      name="description"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ReactQuill
                          value={value}
                          style={{
                            height: "250px",
                          }}
                          modules={toolbarOptions}
                          formats={formats}
                          onChange={(content, delta, source, editor) => {
                            onChange(content);
                          }}
                          theme="snow"
                        />
                      )}
                    />
                    {errors.description && (
                      <span className="text-danger">
                        {errors.description?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group form-control h-100 border-0 shadow">
                    <div className="d-flex justify-content-between">
                      <div className="col-sm-12 col-md-8 pe-3">
                        <h6 className="my-3">Organization Offering</h6>
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Org. name"
                            {...register("organization_name")}
                          />
                          {/* <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      /> */}
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-3 pe-5">
                        <h6 className="my-3">Upload Logo</h6>
                        <div
                          className="input-container  form-control"
                          style={{ textAlign: "center" }}
                        >
                          <label className="hand-cursor">
                            <button
                              className="btn py-0"
                              style={{ position: "relative", bottom: "2px" }}
                            >
                              {time > 0 && isActive ? (
                                <Progress
                                  type="circle"
                                  width={50}
                                  percent={time}
                                />
                              ) : (
                                <img
                                  src={
                                    getValues("organization_logo")
                                      ? getValues("organization_logo")
                                      : "assets/images/upload-logo.svg"
                                  }
                                  className="rounded"
                                  width={"45"}
                                  height={"50"}
                                  alt=""
                                />
                              )}

                              {/* {time > 0 && isActive ? null : (
                                <div className="">
                                  <img src="assets/images/upload-logo.svg" className="pb-1" style={{cursor:"pointer", width:"49px"}}/>
                                </div>
                              )} */}
                            </button>
                            <input
                              type="file"
                              style={{
                                opacity: "0",
                                width: "100%",
                                position: "absolute",
                                right: "0",
                                height: "50px",
                                cursor: "pointer",
                              }}
                              // {...register("organization_logo")}
                              onChange={(e) => {
                                console.log("organization_logo");
                                if (e.target.files.length > 0) {
                                  setIsActive(true);
                                  S3FileUpload.uploadFile(
                                    e.target.files[0],
                                    config
                                  )
                                    .then((data) => {
                                      console.log(data.location);
                                      setValue(
                                        "organization_logo",
                                        data.location
                                      );
                                      setTime(100);
                                      setIsActive(true);
                                      setTimeout(() => {
                                        setTime(0);
                                        setIsActive(false);
                                      }, 3000);
                                    })
                                    .catch((data) => console.log(data));
                                }
                              }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* {
                      <span className="text-danger">
                        {errors?.skill_description?.message}
                      </span>
                    } */}
                  </div>
                  {errors.organization_name && (
                    <span className="text-danger">
                      {errors.organization_name?.message}
                    </span>
                  )}
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Affliate Link"
                        {...register("affiliate_link")}
                      />
                      <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* {errors.title && (
                    <span className="text-danger">{errors.title?.message}</span>
                  )} */}
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-container d-flex">
                      <span
                        style={{
                          position: "absolute",
                          top: "17px",
                          left: "28px",
                          fontSize: "1rem",
                        }}
                      >
                        {priceType}
                      </span>

                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Price"
                        {...register("price")}
                      />

                      <div
                        className="d-flex"
                        style={{
                          position: "absolute",
                          right: "25px",
                          borderRadius: "8px",
                          top: "12px",
                          background: "#EFEFEF",
                        }}
                      >
                        <span
                          onClick={() => {
                            setIsDollar(true);
                            setPriceType("$");
                          }}
                          className="text-center"
                          style={{
                            width: "61px",
                            fontSize: "24px",
                            borderRadius: "8px",
                            fontWeight: "500",
                            cursor:"pointer",
                            background: isDollar ? "#D3E6FF" : "#EFEFEF",
                            boxShadow: isDollar
                              ? "0px 5px 5px rgba(0, 0, 0, 0.1)"
                              : "none",
                          }}
                        >
                          $
                        </span>
                        <span
                          onClick={() => {
                            setIsDollar(false);
                            setPriceType("₹");
                          }}
                          className="text-center"
                          style={{
                            width: "61px",
                            fontSize: "24px",
                            borderRadius: "8px",
                            fontWeight: "500",
                            cursor:"pointer",
                            background: isDollar ? "#EFEFEF" : "#D3E6FF",
                            boxShadow: isDollar
                              ? "none"
                              : "3px 5px 5px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          ₹
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* {errors.title && (
                    <span className="text-danger">{errors.title?.message}</span>
                  )} */}
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group form-control h-100 border-0 shadow">
                    <div className="d-flex justify-content-between">
                      <div className="col-sm-12 col-md-4 pe-3">
                        <h6 className="my-3">Manual Ratings</h6>
                        <div
                          className="input-container form-control pt-2"
                          style={{ paddingLeft: "20px" }}
                        >
                          {[...Array(5)].map((star, index) => {
                            const currentRating = index + 1;
                            return (
                              <label>
                                <input
                                  type="radio"
                                  name="rating"
                                  value={currentRating}
                                  onClick={() => setRating(currentRating)}
                                />
                                <FaStar
                                  className="star"
                                  size={40}
                                  color={
                                    currentRating <= (hover || rating)
                                      ? "#ffc107"
                                      : "#e4e5e9"
                                  }
                                  onMouseEnter={() => setHover(currentRating)}
                                  onMouseLeave={() => setHover(null)}
                                />
                              </label>
                            );
                          })}

                          {/* <p className="mb-0 mt-3">Your rating is {rating}</p> */}
                          {/* <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      /> */}
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <h6 className="my-3">Review Count</h6>
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Enter review count"
                            {...register("review_count")}
                          />
                          {/* <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      /> */}
                        </div>
                      </div>
                    </div>
                    {/* {
                      <span className="text-danger">
                        {errors?.skill_description?.message}
                      </span>
                    } */}
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group form-control h-100 border-0 shadow">
                    <div className="d-flex justify-content-between">
                      <div className="col-sm-12 col-md-6 pe-3">
                        <h6 className="my-3">Career Path</h6>
                        <div className="input-container">
                          <select
                            type="text"
                            className="form-control pl-5"
                            placeholder="Org. name"
                            style={{ appearance: "auto" }}
                            {...register("career")}
                          >
                            {careerPath?.map((item, i) => (
                              <option value={item?.title} key={i}>
                                {item?.title}
                              </option>
                            ))}
                          </select>
                          {/* <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      /> */}
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <h6 className="my-3">Estimated Time Completion</h6>
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Enter Estimated Time"
                            {...register("est_completion_time")}
                          />
                          {/* <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      /> */}
                        </div>
                      </div>
                    </div>
                    {/* {
                      <span className="text-danger">
                        {errors?.skill_description?.message}
                      </span>
                    } */}
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group form-control h-100 border-0 shadow">
                    <div className="d-flex justify-content-between">
                      <div className="col-sm-12 col-md-6 pe-3">
                        <h6 className="my-3">Select Persona</h6>
                        <div className="input-container">
                          <select
                            type="text"
                            className="form-control pl-5"
                            placeholder="Org. name"
                            style={{ appearance: "auto" }}
                            {...register("persona")}
                          >
                            {personaList?.map((item, i) => (
                              <option value={item?.title} key={i}>
                                {item?.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <h6 className="my-3">Difficulty Level</h6>
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Enter Difficulty Level"
                            {...register("certification_level")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group form-control h-100 border-0 shadow">
                    <div className="d-flex justify-content-between">
                      <div className="col-sm-12 col-md-8 pe-3">
                        <h6 className="my-3">Select Visibility</h6>
                        <div className="input-container pt-2 d-flex justify-content-around">
                          <label> <span style={{position:"relative", bottom:"3px"}}>Students</span>
                            <input
                              className="ms-2 purple_checkbox"
                              style={{height:"20px", width:"20px"}}
                              type="checkbox"
                              name="student"
                              checked={checkboxesRole.student}
                              onChange={handleVisibiltyRoleCheckboxChange}
                            />
                          </label>

                          <label> <span style={{position:"relative", bottom:"3px"}}>Counsellors</span>
                            <input
                              className="ms-2 purple_checkbox"
                              style={{height:"20px", width:"20px"}}
                              type="checkbox"
                              name="counsellor"
                              checked={checkboxesRole.counsellor}
                              onChange={handleVisibiltyRoleCheckboxChange}
                            />
                          </label>

                          <label> <span style={{position:"relative", bottom:"3px"}}>Mentors</span>
                            <input
                              className="ms-2 purple_checkbox"
                              style={{height:"20px", width:"20px"}}
                              type="checkbox"
                              name="mentor"
                              checked={checkboxesRole.mentor}
                              onChange={handleVisibiltyRoleCheckboxChange}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 text-center">
                  <button type="submit" className="btn btn-save">
                    {loading ? <Loader /> : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddCertification;
