import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "./../component/loader/Loader";
import { ForgotPassword } from "../slice";
import { toast } from "react-toastify";

function ForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((store) => store.loading);

  const schema = yup.object({
    email: yup
      .string()
      .email("Kindly enter a valid email address.")
      .required("Required"),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      ForgotPassword({
        email: data.email,
      })
    ).then(async (data) => {
      console.log(data);
      if (data?.payload?.code === 1) {
        toast.success(data?.payload?.message);

        navigate("/auth/otp", {
          replace: true,
        });
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };
  console.log(errors);
  return (
    <section className="">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-7 col-lg-6">
            <div className="mb-5">
              <img
                src="assets/images/logo.png"
                className="img-fluid logo-admin"
                alt="logo"
              />
            </div>
            <div className="form-bg">
              <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-12 col-md-12 text-center mb-4">
                    <img
                      src="assets/images/forgot-password.png"
                      className="img-fluid my-3"
                      alt=""
                    />
                    <h2 className="mdl-ttl">Forgot Password</h2>
                    <p className="mdl-sbttl">
                      In order to retrieve your password, please enter
                      registered email id.
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          className="form-control pl-5"
                          placeholder="Email ID"
                          {...register("email")}
                        />
                        <img
                          src="assets/images/Email-Address.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 text-center">
                    <button type="submit" className="btn btn-save">
                      {loading ? <Loader /> : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-6">
            <div className="logo-bg text-center bg-white">
              <div className="lft-img">
                <img
                  src="assets/images/ForgotImg.png"
                  className="img-fluid"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgetPassword;
