import React, { Component, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAdminDetails } from "../slice";

function ViewSubadmin() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((store) => store.loading);
  const {
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",

    shouldFocusError: true,
  });

  const getDetails = useCallback(() => {
    dispatch(getAdminDetails({ id: id })).then((res) => {
      console.log(res);
      let data = res?.payload?.data?.admin_details;

      reset({
        id: data?.id,
        name: data?.name,
        email: data?.email,
        image: data?.image,
        manage_career_path: data?.assigned_roles?.manage_career_path,
        manage_speciality: data?.assigned_roles?.manage_speciality,
        manage_skill: data?.assigned_roles?.manage_skill,
        manage_student: data?.assigned_roles?.manage_student,
        manage_counseller: data?.assigned_roles?.manage_counseller,
        manage_mentor: data?.assigned_roles?.manage_mentor,
        manage_request: data?.assigned_roles?.manage_request,
        manage_forum: data?.assigned_roles?.manage_forum,
        manage_withdrawal: data?.assigned_roles?.manage_withdrawal,
      });
    });
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, []);
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Sub Admin Details</h4>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-sm-12">
            <div className="form-style">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="form-group text-center">
                    <div className="upload-btn-wrapper">
                      <button className="btn">
                        <img
                          src={
                            getValues("image")
                              ? getValues("image")
                              : "assets/images/upload-img.png"
                          }
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-4">
                  <label>Name</label>
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Name"
                        disabled
                      />
                      <img
                        src="assets/images/user.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                  <div className="form-group">
                    <label>Email</label>
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Enter Primary Email"
                        disabled
                      />
                      <img
                        src="assets/images/email.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <h3>Permission & Privileges</h3>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="table-responsive">
                    <table className="table storetable">
                      <thead className="bg-transparent">
                        <tr>
                          <th scope="col" className="bg-transparent">
                            Modules
                          </th>
                          <th scope="col" className="text-end bg-transparent">
                            action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="ps-3">Career Path</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_career_path"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_career_path")}
                                      disabled
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Speciality</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_speciality"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_speciality")}
                                    />
                                  )}
                                />

                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Skill</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_skill"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_skill")}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Student</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_student"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_student")}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Counseller</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_counseller"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_counseller")}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Mentor</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_mentor"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_mentor")}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Request</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_request"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_request")}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Forum</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_forum"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="checkbox"
                                      checked={getValues("manage_forum")}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-3">Withdrawal</td>
                          <td>
                            <div className="d-flex justify-content-end action">
                              <label className="switch">
                                <Controller
                                  name="manage_withdrawal"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      disabled
                                      type="checkbox"
                                      checked={getValues("manage_withdrawal")}
                                      onChange={(e) => {
                                        onChange(e.target.checked);
                                      }}
                                    />
                                  )}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default ViewSubadmin;
