import React, { useCallback, useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "./../component/loader/Loader";
import { addSkill, getAllCareerList, getAllSpecialityList } from "../slice";
import { toast } from "react-toastify";

function AddSkill() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState(1);
  const loading = useSelector((store) => store.loading);
  const CareerPathdata = useSelector((state) => state.getAllCareerList);

  const schema = yup.object({
    skill_name: yup.string().required("Required"),
    career_ids: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().required("Required"),
          skill_type: yup.string().required("Required"),
        })
      )
      .min(1, "Career Path needs to be added for the skill."),
  });

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      career_ids: [{ id: "", skill_type: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: "career_ids",
    control,
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      addSkill({
        title: data.skill_name,
        career_ids: JSON.stringify(data.career_ids),
      })
    ).then(async (data) => {
      console.log(data);
      if (data?.payload?.code === 1) {
        toast.success(data?.payload?.message);

        navigate("/manage-skill", {
          replace: true,
        });
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };

  const getlistCareerPath = useCallback(async () => {
    dispatch(
      getAllCareerList({
        limit: "",
        status: status,
      })
    );
  }, [dispatch, status]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlistCareerPath();
    }
    return () => {
      mounted = false;
    };
  }, [getlistCareerPath]);

  const watchCareerId = watch("career_ids");

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Add New Skill</h4>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-12">
            <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Skill Name"
                        {...register("skill_name")}
                      />
                      <img
                        src="assets/images/skills.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {errors.skill_name && (
                    <span className="text-danger">
                      {errors.skill_name.message}
                    </span>
                  )}
                </div>
                {CareerPathdata && (
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    {fields.map((item, i) => (
                      <div className="row mb-5" key={i}>
                        <div className="col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <div className="input-container">
                              <Controller
                                name={`career_ids[${i}].id`}
                                control={control}
                                defaultValue={null}
                                render={({ field: { onChange, value } }) => (
                                  <select
                                    className="form-control pl-5 form-select"
                                    onChange={(e) => {
                                      onChange(e.target.value);
                                    }}
                                  >
                                    <option value="">Career Paths</option>
                                    {CareerPathdata?.map((item, i) => (
                                      <option value={item?.id} key={i}>
                                        {item?.title}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              />
                              <img
                                src="assets/images/skills.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                          </div>
                          {errors.career_ids && (
                            <span className="text-danger">
                              {errors?.career_ids[i]?.id?.message}
                            </span>
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <div className="input-container">
                              <Controller
                                name={`career_ids[${i}].skill_type`}
                                control={control}
                                defaultValue={null}
                                render={({ field: { onChange, value } }) => (
                                  <select
                                    className="form-control pl-5 form-select"
                                    placeholder="Skill Type"
                                    onChange={(e) => {
                                      onChange(e.target.value);
                                    }}
                                  >
                                    <option value="">Select Skill Type</option>
                                    <option value="core">Core Skill</option>
                                    <option value="booster">
                                      Booster Skill
                                    </option>
                                  </select>
                                )}
                              />
                              <img
                                src="assets/images/skills.png"
                                className="input-img"
                                alt=""
                              />
                            </div>
                          </div>
                          {errors?.career_ids && (
                            <span className="text-danger">
                              {errors?.career_ids[i]?.skill_type?.message}
                            </span>
                          )}
                        </div>
                        {fields.length > 1 && (
                          <div className="position-relative">
                            <img
                              src="assets/images/menu_close.png"
                              alt=""
                              className="delIcon"
                              onClick={() => {
                                remove(i);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {/* <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <select
                        className="form-control pl-5 form-select"
                        {...register("specialization_id")}
                      >
                        <option value="">specialization</option>
                        {data?.map((item, i) => (
                          <option value={item?.id} key={i}>
                            {item?.title}
                          </option>
                        ))}
                      </select>
                      <img
                        src="assets/images/skills.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {errors.specialization_id && (
                    <span className="text-danger">
                      {errors.specialization_id.message}
                    </span>
                  )}
                </div> */}

                <div className="col-sm-12 col-md-12 mb-4">
                  <div
                    className="addMore"
                    onClick={() => {
                      append({ id: "", skill_type: "" });
                    }}
                  >
                    Add More
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 text-center">
                  <button type="submit" className="btn btn-save">
                    {loading ? <Loader /> : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddSkill;
