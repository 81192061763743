import React from "react";
import { Modal } from "react-bootstrap";

function DeleteModal({ show, handleShow, DelSkillName, confirmDelete }) {
  console.log(DelSkillName);
  return (
    <Modal show={show} onHide={handleShow}>
      <Modal.Body>
        <div className="modal-header border-0">
          <button
            type="button"
            className="btn-close"
            onClick={handleShow}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body text-center mb-4">
          <h3>Delete {DelSkillName}?</h3>
          <p>Are you sure, you want to delete {DelSkillName}?</p>
          <div className="view-btns">
            <button
              className="btn btn-reject btn-view mt-2"
              onClick={handleShow}
            >
              No
            </button>
            <button
              className="btn btn-accept btn-view mt-2"
              onClick={() => {
                confirmDelete();
                handleShow();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteModal;
