import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteData,
  deleteDataStringify,
  getData,
  getDataNoToken,
  getDataStringify,
  patchFormData,
  postFormData,
  postRawData,
  putFormData,
} from "../services/httphandler";

const initialState = {
  isLoggedIn: false,
  loading: false,
  total_career: 0,
  total_active_career: 0,
  total_inactive_career: 0,
  getAllCareerList: [],
  getAllCertificationsList: [],
  getCareerDetails: {},
  total_inactive_certification: 0,
  total_active_certification: 0,
  total_certification: 0,
  total_skill: 0,
  total_active_skill: 0,
  total_inactive_skill: 0,
  getAllSkillList: [],
  total_speciality: 0,
  total_active_speciality: 0,
  total_inactive_speciality: 0,
  getAllSpecialityList: [],
  activeUser: 0,
  inactiveUser: 0,
  requestLists: [],
  total_active_request: 0,
  total_inactive_request: 0,
  questionLists: [],
  total_active_question: 0,
  total_inactive_question: 0,
  opportunityListData: [],
  total_active_opportunity: 0,
  total_inactive_opportunity: 0,
  personasLists: [],
  total_active_personas: 0,
  total_inactive_personas: 0,
  collaborationsLists: [],
  total_active_collaborations: 0,
  total_inactive_collaborations: 0,
  total_request: 0,
  questionDetail: null,
  opportunityDetail: null,
  requestDetail: null,
  permission: null,
};

export const login = createAsyncThunk("login", async (obj, { dispatch }) => {
  try {
    dispatch(isLoading(true));

    const response = await getDataNoToken("admin/login", obj);

    dispatch(isLoading(false));
    console.log("admin ", response?.data?.adminId);
    localStorage.setItem("token", response?.data?.token);
    localStorage.setItem("adminId", response?.data?.adminId);

    return response;
  } catch (error) {
    //console.log(error.message);
  }
});

export const ForgotPassword = createAsyncThunk(
  "ForgotPassword",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await getDataNoToken("admin/forgotpassword", obj);

      dispatch(isLoading(false));

      if (response.code === 1) {
        localStorage.setItem("email", obj.email);
      }
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await getDataNoToken("admin/verifyOtp", obj);

      dispatch(isLoading(false));
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("user_manage", JSON.stringify(response?.data));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await putFormData("admin/resetPassword", obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// career path section apis

export const getAllCareerList = createAsyncThunk(
  "getAllCareerList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      //console.log(obj);
      const response = await getData("user/getAllCareerList", obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

//GET FESTIVALS DATA
export const getAllMeetingDetailsOfRunning = createAsyncThunk(
  "getAllMeetingDetailsOfRunning",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      //console.log(obj);
      const response = await postFormData("request/getMeetingInfo", obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);
//GET FESTIVALS DATA
export const getAllFestivals = createAsyncThunk(
  "getAllFestivals",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      //console.log(obj);
      const response = await getData("request/getMeetingDetailsInfo", obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// FESTIVAL POPUP POST FORM DATA
export const addFestivalFormData = createAsyncThunk(
  "addFestivalFormData",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData("request/createNewFestival", obj);
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const deleteFestivalSession = createAsyncThunk(
  "deleteFestivalSession",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await deleteData(
        "request/deleteUserDetailsMeeting",
        obj.objec
      );

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// change Meeting Link
export const changeMeetingLink = createAsyncThunk(
  "changeMeetingLink",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData("request/changeMeetingLink", obj);
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// PUBLISH FESTIVAL
export const publishFestivalEvent = createAsyncThunk(
  "publishFestivalEvent",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData(
        "request/updateFestivalPublished",
        obj
      );
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);
// End Meeting which started FESTIVAL session
export const endMeetingOfBbb = createAsyncThunk(
  "endMeetingOfBbb",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData("request/endMeetingByAdmin", obj);
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const getRecordingMeetingLink = createAsyncThunk(
  "getRecordingMeetingLink",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await getData("request/recordingMeetingStatus", obj);
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// certification module apis start
export const addAndUpdateCertifications = createAsyncThunk(
  "addAndUpdateCertifications",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData(
        "admin/addAndUpdateCertifications",
        obj
      );
      dispatch(isLoading(false));
      dispatch(
        getAllCareerList({
          search: "",
          page: 1,
          limit: 10,
          status: "1",
        })
      );
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const getAllCertificationsList = createAsyncThunk(
  "getAllCertificationsList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      //console.log(obj);
      const response = await getData("admin/certificationsList", obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const updateStatusOfCertifications = createAsyncThunk(
  "updateStatusOfCertifications",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await patchFormData(
        "admin/updateStatusOfCertifications",
        obj.objec
      );

      dispatch(isLoading(false));
      dispatch(getAllCertificationsList(obj.param));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const deleteCertification = createAsyncThunk(
  "deleteCertification",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await deleteData("admin/deleteCertification", obj.objec);

      dispatch(isLoading(false));
      dispatch(getAllCertificationsList(obj.param));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const getCertificationDetails = createAsyncThunk(
  "getCertificationDetails",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await getData("admin/getCertificationDetails", obj);
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// certification module apis end
export const addCareer = createAsyncThunk(
  "addCareer",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData("admin/addCareer", obj);
      dispatch(isLoading(false));
      dispatch(
        getAllCareerList({
          search: "",
          page: 1,
          limit: 12,
          status: 1,
        })
      );
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const addCareerPersonas = createAsyncThunk(
  "addCareer",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData("admin/addCareerPersonas", obj);
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);
export const addCareerCollaboration = createAsyncThunk(
  "addCareer",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData("admin/addCareerCollaboration", obj);
      dispatch(isLoading(false));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const getCareerDetails = createAsyncThunk(
  "getCareerDetails",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await getData("admin/getCareerDetails", obj);
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const changeCareerStatus = createAsyncThunk(
  "changeCareerStatus",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await patchFormData(
        "admin/changeCareerStatus",
        obj.objec
      );

      dispatch(isLoading(false));
      dispatch(getAllCareerList(obj.param));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const deleteCareer = createAsyncThunk(
  "deleteCareer",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await deleteData("admin/deleteCareer", obj.objec);

      dispatch(isLoading(false));
      dispatch(getAllCareerList(obj.param));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);
// skill section api

export const getAllSkillList = createAsyncThunk(
  "getAllSkillList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await getData("admin/getAllSkillList", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const getSkillList = createAsyncThunk(
  "getSkillList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await getData("user/getAllSkillList", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const getSkillDetails = createAsyncThunk(
  "getSkillDetails",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await getData("admin/getSkillDetails", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const addSkill = createAsyncThunk(
  "addSkill",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData("admin/addSkill", obj);

      dispatch(isLoading(false));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const changeSkillStatus = createAsyncThunk(
  "changeSkillStatus",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await patchFormData("admin/changeSkillStatus", obj.data);

      dispatch(isLoading(false));
      dispatch(getAllSkillList(obj.params));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const deleteSkill = createAsyncThunk(
  "deleteSkill",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await deleteData("admin/deleteSkill", obj.data);

      dispatch(isLoading(false));
      dispatch(getAllSkillList(obj.params));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// speciality section api

export const getAllSpecialityList = createAsyncThunk(
  "getAllSpecialityList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getData("user/getAllSpecialityList", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);
export const getSpecialityDetails = createAsyncThunk(
  "getSpecialityDetails",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getData("admin/getSpecialityDetails", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const addSpeciality = createAsyncThunk(
  "addSpeciality",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData("admin/addSpeciality", obj);

      dispatch(isLoading(false));
      dispatch(
        getAllSpecialityList({
          page: 1,
          limit: 12,
        })
      );
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const deleteSpeciality = createAsyncThunk(
  "deleteSpeciality",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await deleteData("admin/deleteSpeciality", obj.data);

      dispatch(isLoading(false));
      dispatch(getAllSpecialityList(obj.params));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const changeSpecialityStatus = createAsyncThunk(
  "changeSpecialityStatus",
  async (obj, { dispatch }) => {
    try {
      const response = await patchFormData(
        "admin/changeSpecialityStatus",
        obj.data
      );
      dispatch(getAllSpecialityList(obj.params));

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// settings

export const changePassword = createAsyncThunk(
  "changePassword",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await putFormData("admin/changePassword", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// other user details
export const userList = createAsyncThunk(
  "userList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getData("admin/userList", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "getUserDetails",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getData("admin/getUserDetails", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);
//  userId, status 1 active 0 inactive
export const changeUserStatus = createAsyncThunk(
  "changeUserStatus",
  async (obj, { dispatch }) => {
    try {
      // dispatch(isLoading(true));
      const response = await patchFormData("admin/changeUserStatus", obj.data);
      dispatch(userList(obj.params));
      // dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "deleteUser",
  async (obj, { dispatch }) => {
    try {
      // dispatch(isLoading(true));
      const response = await deleteData("admin/deleteUser", obj.data);

      // dispatch(isLoading(false));
      dispatch(userList(obj.params));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// student apis

// params:
// first_name, last_name, email, password, gender, country, state, city, state_isocode, city_isocode, image, about, userId (in case of update)

export const addStudentBasicInfo = createAsyncThunk(
  "addStudentBasicInfo",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData("student/addStudentBasicInfo", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// {
// 	"degree_data":[
// 		{
// 			"college_name":"college new",
// 			"degree":"degree new",
// 			"field_of_study":"field of study new",
// 			"start_year":"2015",
// 			"end_year":"2017",
// 			"college_country":"India",
// 			"college_state":"Delhi",
// 			"college_city":"Noida"
// 		}],
// 		"userId":"3"
// }
export const addStudentDegree = createAsyncThunk(
  "addStudentDegree",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postRawData("student/addStudentDegree", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// {
// 	"career_data":[{
// 		"career_id":"12",
// 		"specializations":["9"]
// 	}],
// 	"userId":"3"
// }
export const addStudentCareerInfo = createAsyncThunk(
  "addStudentCareerInfo",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postRawData("student/addStudentCareerInfo", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// {
// 	"skill_data":[{
// 		"skill_id":"1",
// 		"skill_name":"",
// 		"level":"3",
// 		"skill_type":"core"
// 	},
// 	{
// 		"skill_id":"3",
// 		"skill_name":"",
// 		"level":"3",
// 		"skill_type":"booster"
// 	}],
// 	"userId":"3"
// }

export const addStudentSkill = createAsyncThunk(
  "addStudentSkill",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postRawData("student/addStudentSkill", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

// counseller api

// first_name , last_name, email, password, gender (Male, Female, Other),
// mobile, countryCode, secondary_mobile, secoundary_countryCode, designation,
// company_name, secondary_email, about, country, state, city, state_isocode, city_isocode, image, experience, career_data [{career_id: 19}], service_data [{service_id: 1, fee: 3000}, {service_id: 2, fee 5000}], userId incase of update

export const addCounseller = createAsyncThunk(
  "addCounseller",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData("user/addCounseller", obj);
      dispatch(
        userList({
          page: 1,
          search: "",
          limit: 12,
          user_type: "counseller",
          status: 1,
        })
      );

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const addMentor = createAsyncThunk(
  "addMentor",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await postFormData("user/addMentor", obj);

      dispatch(isLoading(false));
      dispatch(
        userList({
          page: 1,
          search: "",
          limit: 12,
          user_type: "mentor",
          status: 1,
        })
      );
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const getAllServiceList = createAsyncThunk(
  "getAllServiceList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getData("user/getAllServiceList", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

// get country city state

export const getAllCountryList = createAsyncThunk(
  "getAllCountryList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getDataNoToken("user/getAllCountryList", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);
// countryIsoCode

export const getAllStateList = createAsyncThunk(
  "getAllStateList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getDataNoToken("user/getAllStateList", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

// state_code

export const getAllCityList = createAsyncThunk(
  "getAllCityList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getDataNoToken("user/getAllCityList", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

// questions api

export const questionLIst = createAsyncThunk(
  "questionLIst",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getData("admin/questionLIst", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const getQuestionDetails = createAsyncThunk(
  "getQuestionDetails",
  async (obj, { dispatch }) => {
    try {
      const response = await getData("admin/getQuestionDetails", obj);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const addAdminResponse = createAsyncThunk(
  "addAdminResponse",
  async (obj, { dispatch }) => {
    try {
      // dispatch(isLoading(true));
      const response = await patchFormData("admin/addAdminResponse", obj);

      // dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

//Get OPPORTUNITY LIST
export const opportunityList = createAsyncThunk(
  "opportunityList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getData("admin/opportunityList", obj);
      console.log(response);
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const getOpportunityDetails = createAsyncThunk(
  "getOpportunityDetails",
  async (obj, { dispatch }) => {
    try {
      const response = await getData("admin/getOpportunity", obj);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const activateDeactivateOpportunity = createAsyncThunk(
  "activateDeactivateOpportunity",
  async (obj, { dispatch }) => {
    try {
      // dispatch(isLoading(true));
      const response = await patchFormData(
        "admin/activateDeactivateOpportunity",
        obj.data
      );
      dispatch(opportunityList(obj.params));
      // dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);
export const AddOpportunity = createAsyncThunk(
  "addOpportunity",
  async (obj, { dispatch }) => {
    try {
      // dispatch(isLoading(true));
      const response = await postFormData("opportunity/addOpportunity", obj);

      // dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);
//GET OPTION LIST OF PERSONA
export const getAllPersonaList = createAsyncThunk(
  "getPersonaOptionList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getData("admin/getPersonasList", obj);
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const getPersonaByIdDetails = createAsyncThunk(
  "getPersonaByIdDetails",
  async (obj, { dispatch }) => {
    try {
      const response = await getData("admin/getPersonaDetails", obj);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

//GET OPTION LIST OF collaboration
export const getAllCollaborationList = createAsyncThunk(
  "getAllCollaborationList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getDataNoToken("admin/getCollaborationList", obj);
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const getCollaborationByIdDetails = createAsyncThunk(
  "getCollaborationByIdDetails",
  async (obj, { dispatch }) => {
    try {
      const response = await getData("admin/getCollaborationDetails", obj);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const requestList = createAsyncThunk(
  "requestList",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      const response = await getData("admin/requestList", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const joinMeeting = createAsyncThunk(
  "joinMeeting",
  async (obj, { dispatch }) => {
    try {
      const response = await postFormData("request/joinMeeting", obj);
      // dispatch(getOtherUserDetails(obj.params));
      console.log("join meeting", response);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const requestDetails = createAsyncThunk(
  "requestDetails",
  async (obj, { dispatch }) => {
    try {
      const response = await getData("admin/requestDetails", obj);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const removeRequest = createAsyncThunk(
  "removeRequest",
  async (obj, { dispatch }) => {
    try {
      // dispatch(isLoading(true));
      const response = await deleteData("admin/removeRequest", obj);
      dispatch(requestList({}));
      // dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const removeComment = createAsyncThunk(
  "removeComment",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await deleteData("question/removeComment", obj);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const removeOpportunity = createAsyncThunk(
  "removeOpportunity",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));
      console.log("obj.objec ", obj.objec);
      const response = await deleteData("opportunity/removeOpportunity", obj.objec);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);
export const removeOpportunityComment = createAsyncThunk(
  "removeOpportunityComment",
  async (obj, { dispatch }) => {
    try {
      dispatch(isLoading(true));

      const response = await deleteData("opportunity/removeCommentOpportunity", obj.objec);

      dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const addQuestion = createAsyncThunk(
  "addQuestion",
  async (obj, { dispatch }) => {
    try {
      // dispatch(isLoading(true));
      const response = await postFormData("question/addQuestion", obj);

      // dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const activateDeactivateQuestion = createAsyncThunk(
  "activateDeactivateQuestion",
  async (obj, { dispatch }) => {
    try {
      // dispatch(isLoading(true));
      const response = await patchFormData(
        "question/activateDeactivateQuestion",
        obj.data
      );
      dispatch(questionLIst(obj.params));
      // dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const userListExcelDownload = createAsyncThunk(
  "subAdminList",
  async (obj, { dispatch }) => {
    try {
      const response = await getData("admin/getAllUserInExcel", obj);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const subAdminList = createAsyncThunk(
  "subAdminList",
  async (obj, { dispatch }) => {
    try {
      const response = await getData("admin/subAdminList", obj);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const getAdminDetails = createAsyncThunk(
  "getAdminDetails",
  async (obj, { dispatch }) => {
    try {
      const response = await getDataStringify("admin/getAdminDetails", obj.id);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const addSubAdminReducer = createAsyncThunk(
  "addSubAdminreducer",
  async (obj, { dispatch }) => {
    try {
      // dispatch(isLoading(true));
      const response = await postFormData("admin/addSubAdmin", obj);

      // dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const deleteSubAdmin = createAsyncThunk(
  "deleteSubAdmin",
  async (obj, { dispatch }) => {
    try {
      // dispatch(isLoading(true));

      const response = await deleteDataStringify(
        "admin/deleteSubAdmin",
        obj.id
      );
      dispatch(subAdminList(obj.params));

      // dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);
// sub_admin_id, status 1/0
export const changeSubAdminStatus = createAsyncThunk(
  "changeSubAdminStatus",
  async (obj, { dispatch }) => {
    try {
      // dispatch(isLoading(true));
      const response = await patchFormData(
        "admin/changeSubAdminStatus",
        obj.data
      );
      dispatch(subAdminList(obj.params));
      // dispatch(isLoading(false));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const FetchWithdrawalRequestList = createAsyncThunk(
  "FetchWithdrawalRequestList",
  async (obj, { dispatch }) => {
    try {
      const response = await getData("admin/withdrawalRequestList", obj);
      console.log(response, "Withdrawal list");
      dispatch(withDrawalListing(response?.data?.request_list));
      dispatch(withDrawalTotal(response?.data?.total_request));
      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const DeleteWithdrawalRequestList = createAsyncThunk(
  "DeleteWithdrawalRequestList",
  async (obj, { dispatch }) => {
    try {
      const response = await deleteDataStringify(
        "admin/deleteWithdrawalRequest",
        obj
      );

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);

export const changeWithdrawalRequestStatus = createAsyncThunk(
  "changeWithdrawalRequestStatus",
  async (obj, { dispatch }) => {
    try {
      const response = await patchFormData(
        "admin/changeWithdrawalRequestStatus",
        obj
      );

      return response;
    } catch (error) {
      //console.log(error.message);
    }
  }
);
export const Slice = createSlice({
  name: "slice",
  initialState,
  reducers: {
    withDrawalListing: (state, action) => {
      state.withDrawalList = action.payload;
    },
    withDrawalTotal: (state, action) => {
      state.totalWithdrawalRequest = action.payload;
    },
    isLoading: (state, action) => {
      state.loading = action.payload;
    },
    loggedInUserType: (state, action) => {
      state.loggedInUser = action.payload;
    },
    removeData: (state, action) => {
      console.log(state[`${state.key}`]);
      state[`${state.key}`] = null;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      console.log(action, "Login detail");
      state.adminDetail = action?.payload?.data;
    },
    [getAllCareerList.fulfilled]: (state, action) => {
      //console.log(action);
      state.getAllCareerList = action?.payload?.data?.career_list;
      state.total_career = action?.payload?.data?.total_career;
      state.total_active_career = action?.payload?.data?.total_active_career;
      state.total_inactive_career =
        action?.payload?.data?.total_inactive_career;
    },
    [getAllCertificationsList.fulfilled]: (state, action) => {
      //console.log(action);
      state.getAllCertificationsList = action?.payload?.data?.certifications;
      state.total_certification = action?.payload?.data?.certifications?.length;
      state.total_active_certification =
        action?.payload?.data?.total_active_certification;
      state.total_inactive_certification =
        action?.payload?.data?.total_inactive_certification;
    },
    [getCareerDetails.fulfilled]: (state, action) => {
      //console.log(action);
      state.getCareerDetails = action?.payload?.data?.career_details;
    },
    [getAllSkillList.fulfilled]: (state, action) => {
      console.log(action);
      state.getAllSkillList = action?.payload?.data?.skill_list;
      state.total_active_skill = action?.payload?.data?.total_active_skill;
      state.total_inactive_skill = action?.payload?.data?.total_inactive_skill;
      state.total_skill = action?.payload?.data?.total_skill;
    },
    [getAllSpecialityList.fulfilled]: (state, action) => {
      //console.log(action);
      state.getAllSpecialityList = action?.payload?.data?.speciality_list;
      state.total_speciality = action?.payload?.data?.total_speciality;
      state.total_active_speciality =
        action?.payload?.data?.total_active_speciality;
      state.total_inactive_speciality =
        action?.payload?.data?.total_inactive_speciality;
    },
    [userList.fulfilled]: (state, action) => {
      console.log(action);
      state.otherUserList = action?.payload?.data?.user_list;
      state.activeUser = action?.payload?.data?.total_active_user;
      state.inactiveUser = action?.payload?.data?.total_inactive_user;
    },
    [getAllCountryList.fulfilled]: (state, action) => {
      // state.tnc = action?.payload?.data?.term_and_condition;
      state.country = action?.payload?.data?.country_list;
    },
    [getUserDetails.fulfilled]: (state, action) => {
      console.log(action);
      state.userDetail = action?.payload?.data?.user_details;
    },
    [getQuestionDetails.fulfilled]: (state, action) => {
      console.log(action);
      state.questionDetail = action?.payload?.data?.question;
    },
    [getOpportunityDetails.fulfilled]: (state, action) => {
      console.log(action);
      state.opportunityDetail = action?.payload?.data?.opportunity;
    },
    [requestList.fulfilled]: (state, action) => {
      console.log(action);
      state.requestLists = action?.payload?.data?.request;
      state.total_request = action?.payload?.data?.total_request;
      state.total_active_request = action?.payload?.data?.total_active_request;
      state.total_inactive_request =
        action?.payload?.data?.total_inactive_request;
    },
    [questionLIst.fulfilled]: (state, action) => {
      console.log(action);
      state.questionLists = action?.payload?.data?.question_list;
      state.total_active_question =
        action?.payload?.data?.total_active_question;
      state.total_inactive_question =
        action?.payload?.data?.total_inactive_question;
    },
    [opportunityList.fulfilled]: (state, action) => {
      console.log(action);
      state.opportunityListsData = action?.payload?.data?.opportunity_list;
      state.total_active_opportunity =
        action?.payload?.data?.total_active_opportunity;
      state.total_inactive_opportunity =
        action?.payload?.data?.total_inactive_opportunity;
    },
    [getAllPersonaList.fulfilled]: (state, action) => {
      console.log(action);
      state.personasLists = action?.payload?.data?.career_personas_list;
      state.total_active_personas =
        action?.payload?.data?.total_active_personas;
      state.total_inactive_personas =
        action?.payload?.data?.total_inactive_personas;
    },
    [getAllCollaborationList.fulfilled]: (state, action) => {
      console.log(action);
      state.collaborationsLists =
        action?.payload?.data?.career_collaboration_list;
      state.total_active_collaborations =
        action?.payload?.data?.total_active_collaborations;
      state.total_inactive_collaborations =
        action?.payload?.data?.total_inactive_collaborations;
    },
    [requestDetails.fulfilled]: (state, action) => {
      console.log(action);
      state.requestDetail = action?.payload?.data?.request_details;
    },
    [subAdminList.fulfilled]: (state, action) => {
      console.log(action);
      state.sub_admin_list = action?.payload?.data?.sub_admin_list;
      state.total_active_sub_admin =
        action?.payload?.data?.total_active_sub_admin;
      state.total_inactive_sub_admin =
        action?.payload?.data?.total_inactive_sub_admin;
    },
  },
});

export const {
  isLoading,
  loggedInUserType,
  removeData,
  withDrawalListing,
  withDrawalTotal,
} = Slice.actions;

export default Slice.reducer;
