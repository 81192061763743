import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getOpportunityDetails, removeOpportunityComment, removeData } from "../slice";
import moment from "moment";
import DeleteModal from "../component/modal/DeleteModal";
function ForumDetail({ show, handleShow }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((store) => store.opportunityDetail);
  const [commentDelshow, setCommentDelshow] = useState(false);
  const [delOpportunityId, setDdelOpportunityId] = useState("");
  const [delCommentId, setDdelCommentId] = useState("");
  const [delName, setDelName] = useState("");

  const getDetails = useCallback(() => {
    dispatch(getOpportunityDetails({ opportunityId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    getDetails();

    return () => {
      dispatch(removeData({ key: "opportunityDetail" }));
    };
  }, [getDetails]);

  // const handleDeleteComment = (data) => {
  //   dispatch(removeOpportunityComment(data)).then((res) => {
  //     if (res?.payload?.code === 1) {
  //       toast.success(res?.payload?.message);
  //       getDetails();
  //     } else {
  //       toast.error(res?.payload?.message);
  //     }
  //   });
  // };

  const confirmDelete = () => {
    dispatch(
      removeOpportunityComment({
        objec: { opportunityId : id, commentId: delCommentId },
        // param: {
        //   search: searchValue,
        //   page: page,
        //   limit: 12,
        //   status: status,
        // },
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        setDdelCommentId("")
        getDetails();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="bg-white rounded p-4 skills mt-2">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h5>{data?.title}</h5>
                    <div className="bg-span">
                      <span className="opp-tag">{data?.collaborationTag}</span>{" "}
                      <span className="opp-tag">{data?.personaTag}</span>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white rounded p-4 skills mt-2">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    {/* <span className="flag-icon book-com">
                      <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                    </span> */}
                    <p className="text-capitalize">
                      <img
                        src={data?.user_few_details?.image}
                        className="opp-user-image"
                        alt=""
                      />{" "}
                      <span>
                        {data?.user_few_details?.first_name}{" "}
                        {data?.user_few_details?.last_name}{" "}
                      </span>
                      <small className="text-muted">
                        on {moment(data?.createdAt).format("DD MMM YYYY")}
                      </small>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div className="com-view-like position-static">
                      <span>
                        <img src="assets/images/comments.png" alt="" />{" "}
                        {data?.total_comment}
                      </span>
                      <span>
                        <img src="assets/images/Share.png" alt="" />{" "}
                        {data?.total_share}
                      </span>
                      <span>
                        <img src="assets/images/upvote.png" alt="" />{" "}
                        {data?.total_like}
                      </span>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <p>{data?.description}</p>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h6>
                      <b>{data?.total_comment} Comment</b>
                    </h6>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 bol-lft position-relative">
                    {data?.comments_list?.map((item, i) => (
                      <div className="comment-format" key={i}>
                        <img
                          src={
                            item?.user_details
                              ? item?.user_details?.image
                              : "assets/images/counselo1.png"
                          }
                          className="comme-img"
                          alt=""
                        />
                        <div className="comme-text">
                          <p className="mb-1">
                            <span className="text-capitalize com-col">
                              {item?.user_details
                                ? `${item?.user_details?.first_name} ${item?.user_details?.last_name}`
                                : ""}
                            </span>{" "}
                            <small className="text-muted">
                              {moment(item?.createdAt).fromNow()}
                            </small>
                          </p>
                          <p className="mb-1">{item?.message}</p>
                          {/* <p className="text-muted mb-0">
                            <span className="mr-2">
                              <i className="fa fa-thumbs-up"></i> Like
                            </span>
                            <span>
                              <i className="fa fa-flag"></i> Flag
                            </span>
                          </p> */}

                          <img
                            src="assets/images/delete-sm.png"
                            alt=""
                            width="18px"
                            className="cursor-pointer"
                            onClick={() => {
                              setDdelCommentId(item?.id);
                              setCommentDelshow(!commentDelshow);
                              setDelName(item?.message);
                              // handleDeleteComment({
                              //   commentId: item?.id,
                              //   opportunityId: id,
                              // });
                            }}
                          />
                        </div>
                      </div>
                    ))}

                    {/* <div className="comment-format">
                      <img
                        src="assets/images/profile1.png"
                        className="comme-img"
                        alt=""
                      />
                      <div className="commentInputBtn">
                        <div className="p-0 comme-text w-100">
                          <input
                            type="text"
                            className="form-control pl-3"
                            placeholder="Type here to reply..."
                          />
                        </div>
                        <button className="btn add-btn">Add Comment</button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <DeleteModal
        show={commentDelshow}
        handleShow={() => {
          setCommentDelshow(!commentDelshow);
          setDelName("");
        }}
        DelSkillName={delName}
        confirmDelete={confirmDelete}
      />
    </>
  );
}

export default ForumDetail;
