import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { afterRoutes, authRoutes } from "./routes";
import Login from "./feature/login/Login";
import NotFound from "./feature/notFound/NotFound";
import { useSelector } from "react-redux";
import PrivateRoute from "./routes/PrivateRoute";
import Sidebar from "./feature/component/Sidebar";

function App() {
  const isLoggedIn = useSelector((store) => store.isLoggedIn);

  return (
    <>
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/auth/login" replace />}
          ></Route>
          {!isLoggedIn &&
            authRoutes.length > 0 &&
            authRoutes.map((props, index) => {
              return (
                <Route
                  key={index}
                  path={props.path}
                  element={<props.element />}
                />
              );
            })}

          {afterRoutes.length > 0 &&
            afterRoutes.map((props, index) => {
              return (
                <Route key={index} path={"/"} element={<PrivateRoute />}>
                  <Route
                    key={index}
                    path={props.path}
                    element={
                      <>
                        <div className="admin-panel">
                          <div className="container-fluid">
                            <div className="row no-gutters">
                              <Sidebar />
                              <props.element />
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  />
                </Route>
              );
            })}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
