import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../component/loader/Loader";
import { toast } from "react-toastify";
import Paginations from "../component/pagination/Pagination";
import {
  deleteFestivalSession,
  endMeetingOfBbb,
  getAllFestivals,
  getAllMeetingDetailsOfRunning,
  getRecordingMeetingLink,
  publishFestivalEvent,
} from "../slice";
import "./festival.css";
import CreateFestivalForm from "./festivalPopupForm";
import DeleteModal from "../component/modal/DeleteModal";
import ChangeMeetingLinks from "./ChangeMeetingLinks";

function ManageFestivals() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [festivalList, setFestivalList] = useState([]);
  const [totalFestivalLen, setTotalFestivalLen] = useState(0);
  // const total_request = useSelector((store) => store.total_request);
  const [page, setPage] = useState(1);
  const [showChangeLinkPopup, setShowChangeLinkPopup] = useState(false);
  const [showChangeLinkId, setShowChangeLinkId] = useState(false);
  const [show, setShow] = useState(false);
  const [delFestivalId, setDdelFestivalId] = useState("");
  const [delFestivalName, setDelFestivalName] = useState("");
  const [showFestivalPopup, setShowFestivalPopup] = useState(false);
  const handleCloseFestivalPopup = () => setShowFestivalPopup(false);
  const handleShowFestivalPopup = () => setShowFestivalPopup(true);
  console.log("page ", page);
  const getList = useCallback(() => {
    setLoading(true);
    dispatch(getAllFestivals({
      page: page,
      limit: 10,
    })).then((res) => {
      setFestivalList(res?.payload?.data?.get_meeting_info);
      setTotalFestivalLen(res?.payload?.data?.total_festival)
      setLoading(false);
    });
  }, [dispatch, page]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleStudentLinkCopied = (data) => {
    console.log(data, "ffff");
    // `https://xaphal.com/festival?festival_name="${data?.meetingName}"&meetingId=${data?.meetingId}&session=${data.studentPW}&role=student`
    data.isChanged
      ? navigator.clipboard.writeText(data?.meeting_link)
      : navigator.clipboard.writeText(
          `https://xaphal.com/festival/${data?.meetingName.trim()
          .replace(/\s+/g, "-")}/${data?.meetingId}/${data.studentPW}/student`
        );
    toast.success("Link copied");
  };

  const handleMentorLinkCopied = (data) => {
    // `https://xaphal.com/festival?festival_name="${data.meetingName}"&meetingId=${data.meetingId}&session=${data.moderatorPW}&role=mentor`
    data.isChanged
      ? navigator.clipboard.writeText(data?.meeting_link)
      : navigator.clipboard.writeText(
          `https://xaphal.com/festival/${data.meetingName.trim()
          .replace(/\s+/g, "-")}/${data.meetingId}/${data.moderatorPW}/mentor`
        );
    toast.success("Link copied");
  };
  const handleRecordedLinkCopied = (data) => {
    navigator.clipboard.writeText(data);
    toast.success("Link copied");
  };

  const handlePublishFestivalEvent = (meetingId) => {
    dispatch(publishFestivalEvent({ meetingId })).then((res) => {
      console.log("res", res);
      getList();
    });
  };

  const confirmDelete = () => {
    dispatch(
      deleteFestivalSession({
        objec: { id: delFestivalId },
        // param: {
        //   search: searchValue,
        //   page: page,
        //   limit: 12,
        //   status: status,
        // },
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        setDdelFestivalId("")
        getList();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const handleRecordingMeetingLink = (time) => {
    let momentDate = moment(time?.date);
    var hourToCheck = moment(time?.endTime, "hh:mm:ss").hours();
    var MinuteToCheck = moment(time?.endTime, "hh:mm:ss").minutes();
    var dateToCheck = momentDate.hour(hourToCheck).minute(MinuteToCheck + 60);
    // return moment().isAfter(dateToCheck);
    if (moment().isAfter(dateToCheck)) {
      console.log("meeting link available");
      dispatch(getRecordingMeetingLink({ meetingId: time?.meetingId })).then(
        (res) => {
          if (res?.payload?.code === 1) {
            console.log("llll", res?.payload?.data);
            let recorded_links = res?.payload?.data?.recordedLinks;
            recorded_links?.isRecordedMeetingActive
              ? handleRecordedLinkCopied(recorded_links?.recorded_meeting_link)
              : toast.error("There is no recorded link available");
          } else {
            toast.error("There is no recorded link available")
          }
        }
      );
    } else {
      console.log("recording meeting link not available");
      toast.error("There is no recorded link available");
    }
  };
  console.log(festivalList, "festival listttt");

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="bg-white pt-3 px-3 ">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h4>Festivals</h4>
              </div>
              <div className="create-festival-btn">
                <button onClick={() => handleShowFestivalPopup()}>
                  Create new Festival
                </button>
              </div>
            </div>
          </div>
          <CreateFestivalForm
            show={showFestivalPopup}
            handleClose={handleCloseFestivalPopup}
            handleShow={handleShowFestivalPopup}
            getList={getList}
          />

          <div className="row mt-3">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-12">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="tab-content" id="pills-tabContent">
                      <div>
                        <div className="table-responsive text-center">
                          <table className="table storetable">
                            <thead className="text-center">
                              <tr>
                                <th scope="col">Speaker Name</th>
                                <th scope="col">Festival Name</th>
                                <th scope="col">Copy Link</th>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>
                                <th scope="col">Status</th>
                                <th scope="col">Actions</th>
                              </tr>
                              <tr className="border-0 bg-none invisible">
                                <td className="border-0 bg-none"></td>
                              </tr>
                            </thead>

                            <tbody>
                              {festivalList && festivalList?.length > 0 ? (
                                festivalList?.map((item, i) => (
                                  <>
                                    <tr key={i}>
                                      <td>{item?.speaker}</td>
                                      <td>{item?.meetingName}</td>
                                      <td>
                                        <button
                                          className="join-student-btn"
                                          onClick={() => {
                                            handleStudentLinkCopied(item);
                                          }}
                                        >
                                          Join as student
                                        </button>
                                        <button
                                          className="join-mentor-btn"
                                          onClick={() => {
                                            handleMentorLinkCopied(item);
                                          }}
                                        >
                                          Join as mentor
                                        </button>
                                        <button
                                          className="join-mentor-btn "
                                          style={{ paddingLeft: "10px" }}
                                          onClick={() => {
                                            handleRecordingMeetingLink(item);
                                            // item?.recorded_meeting_link
                                            //   ? handleRecordedLinkCopied(
                                            //       item?.recorded_meeting_link
                                            //     )
                                            //   : toast(
                                            //       "There is no recorded link available"
                                            //     );
                                          }}
                                        >
                                          Recorded Link
                                        </button>
                                      </td>

                                      <td>{item.date}</td>

                                      <td>
                                        {/* {item.startTime} */}
                                        {item?.startTime
                                          ? moment(
                                              item?.startTime,
                                              "hh:mm:ss"
                                            ).format("h:mm")
                                          : ""}{" "}
                                        -{" "}
                                        {item?.endTime
                                          ? moment(
                                              item?.endTime,
                                              "hh:mm:ss"
                                            ).format("h:mm A")
                                          : ""}
                                      </td>
                                      <td>
                                        {item?.isMeetingRunning === "4"
                                          ? "Completed"
                                          : item?.isMeetingRunning === "1"
                                          ? "In Progress"
                                          : "Pending"}
                                      </td>
                                      <td>
                                        {/* <button 
                                          className="view-btn"
                                          onClick={() => {
                                            console.log("uuuu");
                                            dispatch(getAllMeetingDetailsOfRunning({meetingId:item?.meetingId})).then((res) => {
                                              if (res?.payload?.code === 1) {
                                                console.log("ress", res);
                                                toast.success(res?.payload?.message);
                                              } else {
                                                toast.error(res?.payload?.message);
                                              }
                                            })
                                          }}
                                        >
                                          View
                                        </button> */}
                                        <button
                                          className="view-btn"
                                          onClick={() => {
                                            console.log("uuuu");
                                            setShowChangeLinkPopup(
                                              !showChangeLinkPopup
                                            );
                                            setShowChangeLinkId(item?.id);
                                          }}
                                        >
                                          Change Link
                                        </button>
                                        <button
                                          className={
                                            item?.isPublished
                                              ? "published-btn Published_active"
                                              : "published-btn"
                                          }
                                          onClick={() => {
                                            handlePublishFestivalEvent(
                                              item?.id
                                            );
                                          }}
                                        >
                                          {item?.isPublished
                                            ? "Published"
                                            : "Publish"}
                                        </button>
                                        <button
                                          className="end-meet-btn"
                                          onClick={() => {
                                            dispatch(
                                              endMeetingOfBbb({
                                                meetingId: item?.meetingId,
                                                password: item?.moderatorPW,
                                              })
                                            ).then((res) => {
                                              if (res?.payload?.code === 1) {
                                                toast.success(
                                                  res?.payload?.message
                                                );
                                                getList();
                                                console.log("reeee", res);
                                              } else {
                                                console.log("uuuuu", res);
                                                toast.error(
                                                  res?.payload?.data
                                                    ?.end_meet_response?.message
                                                );
                                              }
                                            });
                                          }}
                                        >
                                          End meeting
                                        </button>
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                          height={"20px"}
                                          className="cursor-pointer mx-2"
                                          onClick={() => {
                                            setDdelFestivalId(item?.id);
                                            setDelFestivalName(
                                              item?.meetingName
                                            );
                                            setShow(!show);
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr className="border-0 bg-none invisible">
                                      <td className="border-0 bg-none"></td>
                                    </tr>
                                  </>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={3}>No Data Found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {festivalList?.length > 0 && (
                            <div className="col-sm-12">
                              <Paginations
                                page={page}
                                total={totalFestivalLen && totalFestivalLen}
                                handlePage={(val) => {
                                  setPage(val);
                                }}
                                limit={10}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <DeleteModal
          show={show}
          handleShow={() => {
            setShow(!show);
            setDelFestivalName("");
          }}
          confirmDelete={confirmDelete}
          DelSkillName={delFestivalName}
        />

        <ChangeMeetingLinks
          showChangeLinkPopup={showChangeLinkPopup}
          handleMeetingPopupShow={() => {
            setShowChangeLinkPopup(!showChangeLinkPopup);
            setShowChangeLinkId("");
          }}
          handleMeetingPopupClose={() => {
            setShowChangeLinkPopup(!showChangeLinkPopup);
            setShowChangeLinkId("");
          }}
          showChangeLinkId={showChangeLinkId}
        />
      </article>
    </>
  );
}

export default ManageFestivals;
