import React, { useCallback, useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "./../component/loader/Loader";
import {
  addSkill,
  getAllCareerList,
  getAllSpecialityList,
  getSkillDetails,
} from "../slice";
import { toast } from "react-toastify";

function EditSkill() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState(1);
  const loading = useSelector((store) => store.loading);
  const CareerPathdata = useSelector((state) => state.getAllCareerList);

  const schema = yup.object({
    skill_name: yup.string().required("Required"),
    career_ids: yup.array().of(
      yup.object().shape({
        id: yup.string().required("Required"),
        skill_type: yup.string().required("Required"),
      })
    ),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    control,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      career_ids: [{ id: "", skill_type: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: "career_ids",
    control,
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      addSkill({
        title: data.skill_name,
        career_ids: JSON.stringify(data.career_ids),
        skillId: id,
      })
    ).then(async (data) => {
      console.log(data);
      if (data?.payload?.code === 1) {
        toast.success(data?.payload?.message);

        navigate("/manage-skill", {
          replace: true,
        });
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };

  const getlistCareerPath = useCallback(async () => {
    dispatch(
      getAllCareerList({
        limit: "",
        status: status,
      })
    );
  }, [dispatch, status]);

  const getDetail = () => {
    dispatch(getSkillDetails({ skillId: id })).then((res) => {
      if (res?.payload?.code === 1) {
        console.log(res);
        let careerLst = res?.payload?.data?.skill_details?.career_list?.map(
          (it) => {
            return {
              id: it?.career_details?.id,
              skill_type: it?.skill_type,
            };
          }
        );

        reset({
          skill_name: res?.payload?.data?.skill_details?.title,
          career_ids: careerLst,
        });
      }
    });
  };

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlistCareerPath();
      getDetail();
    }
    return () => {
      mounted = false;
    };
  }, [getlistCareerPath]);

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Edit Skill</h4>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-12">
            <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Skill Name"
                        {...register("skill_name")}
                      />
                      <img
                        src="assets/images/skills.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {errors.skill_name && (
                    <span className="text-danger">
                      {errors.skill_name.message}
                    </span>
                  )}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                  {fields.map((item, i) => (
                    <div className="row mb-5" key={i}>
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group">
                          <div className="input-container">
                            <Controller
                              name={`career_ids[${i}].id`}
                              control={control}
                              defaultValue={null}
                              render={({ field: { onChange, value } }) => (
                                <select
                                  className="form-control pl-5 form-select"
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                  }}
                                  value={value}
                                >
                                  <option value="">Career Paths</option>
                                  {CareerPathdata?.map((item, i) => (
                                    <option value={item?.id} key={i}>
                                      {item?.title}
                                    </option>
                                  ))}
                                </select>
                              )}
                            />
                            <img
                              src="assets/images/skills.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                        {errors.career_ids && (
                          <span className="text-danger">
                            {errors?.career_ids[i]?.id?.message}
                          </span>
                        )}
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="form-group">
                          <div className="input-container">
                            <Controller
                              name={`career_ids[${i}].skill_type`}
                              control={control}
                              defaultValue={null}
                              render={({ field: { onChange, value } }) => (
                                <select
                                  className="form-control pl-5 form-select"
                                  placeholder="Skill Type"
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                  }}
                                  value={value}
                                >
                                  <option value="">Select Skill Type</option>
                                  <option value="core">Core Skill</option>
                                  <option value="booster">Booster Skill</option>
                                </select>
                              )}
                            />
                            <img
                              src="assets/images/skills.png"
                              className="input-img"
                              alt=""
                            />
                          </div>
                        </div>
                        {errors.skill_type && (
                          <span className="text-danger">
                            {errors.skill_type.message}
                          </span>
                        )}
                      </div>
                      <div className="position-relative">
                        <img
                          src="assets/images/menu_close.png"
                          alt=""
                          className="delIcon"
                          onClick={() => {
                            remove(i);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {/* <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <select
                        className="form-control pl-5 form-select"
                        {...register("specialization_id")}
                      >
                        <option value="">specialization</option>
                        {data?.map((item, i) => (
                          <option value={item?.id} key={i}>
                            {item?.title}
                          </option>
                        ))}
                      </select>
                      <img
                        src="assets/images/skills.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  {errors.specialization_id && (
                    <span className="text-danger">
                      {errors.specialization_id.message}
                    </span>
                  )}
                </div> */}

                <div className="col-sm-12 col-md-12 mb-4">
                  <div
                    className="addMore"
                    onClick={() => {
                      append({ id: "", skill_type: "" });
                    }}
                  >
                    Add More
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 text-center">
                  <button type="submit" className="btn btn-save">
                    {loading ? <Loader /> : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
}

export default EditSkill;
