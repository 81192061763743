import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../component/loader/Loader";
import DeleteModal from "../component/modal/DeleteModal";
import Pagination from "../component/pagination/Pagination";
import {
  changeCareerStatus,
  deleteCareer,
  getAllCareerList,
  removeData,
} from "../slice";

import '../../assets/css/styles.css'

function ManageCareerPath() {
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState("active");
  const total = useSelector((store) => store.total_career);
  const total_active_career = useSelector((store) => store.total_active_career);
  const total_inactive_career = useSelector(
    (store) => store.total_inactive_career
  );
  const data = useSelector((state) => state.getAllCareerList);
  // const loading = useSelector((state) => state.loading);
  const [status, setStatus] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [delId, setDdelId] = useState("");
  const [delName, setDelName] = useState("");
  const [loading, setLoading] = useState(false);
  const getlist = useCallback(() => {
    setLoading(true);
    dispatch(
      getAllCareerList({
        search: searchValue,
        page: page,
        limit: 12,
        status: status,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, searchValue, page, status]);

  useEffect(() => {
    let mounted = false;
    if (!mounted) {
      getlist();
    }
    return () => {
      mounted = false;
    };
  }, [getlist, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(removeData({ key: "getAllCareerList" }));
    };
  }, [dispatch]);

  const toggleCareerPath = (id, stat) => {
    dispatch(
      changeCareerStatus({
        objec: {
          careerId: id,
          status: stat,
        },
        param: {
          search: searchValue,
          page: page,
          limit: 12,
          status: status,
        },
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const confirmDelete = () => {
    dispatch(
      deleteCareer({
        objec: { careerId: delId },
        param: {
          search: searchValue,
          page: page,
          limit: 12,
          status: status,
        },
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  console.log("data", data);
  console.log("searchValue", searchValue);
  console.log("total_inactive_career", total_inactive_career);
  console.log("total_active_career", total_active_career);

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Career Paths </h4>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by title.."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </form>
                <Link to="/add-career-path" className="btn add-new">
                  +
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ul
              className="nav nav-pills group-nav mb-3 mt-3"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={
                    tabName === "active" ? "nav-link active" : "nav-link"
                  }
                  id="pills-active-career-tab"
                  type="button"
                  role="tab"
                  aria-controls="pills-active-career"
                  aria-selected="true"
                  onClick={() => {
                    dispatch(removeData({ key: "getAllCareerList" }));
                    setStatus(1);
                    setPage(1);
                    setTabName("active");
                  }}
                >
                  active
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    tabName === "inactive" ? "nav-link active" : "nav-link"
                  }
                  id="pills-inactive-career-tab"
                  type="button"
                  role="tab"
                  aria-controls="pills-inactive-career"
                  aria-selected="false"
                  onClick={() => {
                    dispatch(removeData({ key: "getAllCareerList" }));
                    setStatus(0);
                    setPage(1);
                    setTabName("inactive");
                  }}
                >
                  inactive
                </button>
              </li>
            </ul>

            
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="col-sm-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className={
                    tabName === "active"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="pills-active-career"
                  role="tabpanel"
                  aria-labelledby="pills-active-career-tab"
                >
                  <div className="row">
                    {data && data?.length > 0 ? (
                      data?.map((item, i) => {
                        return (
                          <div
                            className="col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-3"
                            key={i}
                          >
                            <div className="bg-white p-2 rounded bgHeight mb-0 position-relative">
                              <img
                                src={
                                  item?.image?.includes("http")
                                    ? item?.image
                                    : "assets/images/img1.png"
                                }
                                className="career-icon"
                                alt=""
                              />
                              <h5 className="my-2 text-capitalize">
                                {item?.title || "NA"}
                              </h5>
                              <p className="fs-6">
                                <span 
                                  className="ellips ellips-1"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                >
                                  {/* {item?.description} */}
                                </span>
                              </p>

                              <div
                                className="career-btn-icon mb-0 mr-2"
                                style={{
                                  position: "absolute",
                                  bottom: 5,
                                  justifyContent: "flex-start",
                                  gap: 10,
                                }}
                              >
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={item?.isActive}
                                    onChange={() => {
                                      toggleCareerPath(
                                        item?.id,
                                        !item.isActive
                                      );
                                    }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <Link to={`/edit-career-path/${item?.id}`}>
                                  <img
                                    src="assets/images/edit.png"
                                    alt=""
                                    width="20px"
                                  ></img>
                                </Link>
                                <Link to={`/career-paths-detail/${item?.id}`}>
                                  <img
                                    src="assets/images/view.png"
                                    className="sha-up"
                                    alt=""
                                  />
                                </Link>
                                <img
                                  src="assets/images/delete-sm.png"
                                  alt=""
                                  height={"20px"}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setDdelId(item?.id);
                                    setDelName(item?.title);
                                    setShow(!show);
                                  }}
                                />

                              <Link to={`/add-personas/${item?.id}`} className="add_personas_new">
                                    + Personas
                              </Link>
                              <Link to={`/add-collaboration/${item?.id}`} className="add_personas_new">
                                    + Collaboration
                              </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center">
                        {loading ? <Loader /> : "No Data Found"}
                      </div>
                    )}

                    {data?.length > 0 && (
                      <div className="col-sm-12">
                        <Pagination
                          page={page}
                          total={
                            searchValue?.length > 0
                              ? data?.length
                              : total_active_career
                          }
                          limit={12}
                          handlePage={(val) => {
                            setPage(val);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    tabName === "inactive"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="pills-inactive-career"
                  role="tabpanel"
                  aria-labelledby="pills-inactive-career-tab"
                >
                  <div className="row">
                    {data && data?.length > 0 ? (
                      data?.map((item, i) => {
                        return (
                          <div
                            className="col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-3"
                            key={i}
                          >
                            <div className="bg-white p-2 rounded bgHeight mb-0 position-relative">
                              <img
                                src={
                                  item?.image?.includes("http")
                                    ? item?.image
                                    : "assets/images/img1.png"
                                }
                                className="career-icon"
                                alt=""
                              />
                              <h5 className="my-2 text-capitalize">
                                {item?.title || "NA"}
                              </h5>
                              <p className="fs-6">
                                <span 
                                  className="ellips ellips-1"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                >
                                  {/* {item?.description} */}
                                </span>
                              </p>

                              <div
                                className="career-btn-icon mb-0 mr-2"
                                style={{
                                  position: "absolute",
                                  bottom: 5,
                                  justifyContent: "flex-start",
                                  gap: 10,
                                }}
                              >
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={item?.isActive}
                                    onChange={() => {
                                      toggleCareerPath(
                                        item?.id,
                                        !item.isActive
                                      );
                                    }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <Link to={`/edit-career-path/${item?.id}`}>
                                  <img
                                    src="assets/images/edit.png"
                                    alt=""
                                    width="20px"
                                  ></img>
                                </Link>
                                <Link to={`/career-paths-detail/${item?.id}`}>
                                  <img
                                    src="assets/images/view.png"
                                    className="sha-up"
                                    alt=""
                                  />
                                </Link>
                                <img
                                  src="assets/images/delete-sm.png"
                                  alt=""
                                  height={"20px"}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setDdelId(item?.id);
                                    setDelName(item?.title);
                                    setShow(!show);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center">
                        {loading ? <Loader /> : "No Data Found"}
                      </div>
                    )}

                    {data?.length > 0 && (
                      <div className="col-sm-12">
                        <Pagination
                          page={page}
                          total={
                            searchValue?.length > 0
                              ? data?.length
                              : total_inactive_career
                          }
                          limit={12}
                          handlePage={(val) => {
                            setPage(val);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <DeleteModal
        show={show}
        handleShow={() => {
          setShow(!show);
          setDelName("");
        }}
        confirmDelete={confirmDelete}
        DelSkillName={delName}
      />
    </article>
  );
}

export default ManageCareerPath;
