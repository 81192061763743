import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Loader from "../component/loader/Loader";
import {
  activateDeactivateQuestion,
  questionLIst,
  removeData,
  requestList,
} from "../slice";
import Paginations from "./../component/pagination/Pagination";
import moment from "moment";
import ForumDetail from "./ForumDetail";
import {getQuestionDetails} from "./../slice";
import {Drawer} from "antd";

function Forum() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabName, setTabName] = useState("active");
  const [searchValue, setSearchValue] = useState("");
  const list = useSelector((store) => store.questionLists);
  const total_active_question = useSelector(
    (store) => store.total_active_question
  );
  const total_inactive_question = useSelector(
    (store) => store.total_inactive_question
  );
  const questionDetail = useSelector((store) => store.questionDetail);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(1);
  const [show, setShow] = useState(false);

  const getList = useCallback(() => {
    setLoading(true);

    dispatch(
      questionLIst({page: page, limit: 10, status, search: searchValue})
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, page, status, searchValue]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleToggle = (data) => {
    dispatch(
      activateDeactivateQuestion({
        data: data,
        params: {
          page: page,
          limit: 10,
          status,
          search: searchValue,
        },
      })
    ).then((res) => {
      if(res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        getList();
      } else {
        toast.success(res?.payload?.message);
      }
    });
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Forums</h4>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Student Name and Forum Title.."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </form>
                <Link to="/add-forum" className="btn add-new">
                  +
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-9">
                <ul
                  className="nav nav-pills group-nav mb-3 mt-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "active" ? "nav-link active" : "nav-link"
                      }
                      id="pills-active-skills-tab"
                      type="button"
                      role="tab"
                      aria-controls="pills-active-skills"
                      aria-selected="true"
                      onClick={() => {
                        setStatus(1);
                        setPage(1);
                        setTabName("active");
                      }}
                    >
                      active
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "inactive" ? "nav-link active" : "nav-link"
                      }
                      id="pills-inactive-skills-tab"
                      onClick={() => {
                        setStatus(0);
                        setPage(1);
                        setTabName("inactive");
                      }}
                      type="button"
                      role="tab"
                      aria-controls="pills-inactive-skills"
                      aria-selected="false"
                    >
                      inactive
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-12">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={
                      tabName === "active"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-active-skills"
                    role="tabpanel"
                    aria-labelledby="pills-active-skills-tab"
                  >
                    <div className="table-responsive text-center">
                      <table className="table storetable">
                        <thead className="text-center">
                          <tr>
                            <th scope="col">Profile</th>
                            <th scope="col">Name</th>
                            <th scope="col">Title</th>
                            {/* <th scope="col">Phone Number</th> */}
                            <th scope="col">Description</th>
                            <th scope="col">Tags</th>
                            <th scope="col">Replies</th>
                            <th scope="col">Views</th>
                            <th scope="col">Upvotes</th>
                            <th scope="col">Date</th>
                            <th scope="col">Actions</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>

                        <tbody>
                          {list && list?.length > 0 ? (
                            list?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={
                                      item?.user_details?.image?.includes(
                                        "http"
                                      )
                                        ? item?.user_details?.image
                                        : item?.image
                                          ? item?.image
                                          : "assets/images/profile1.png"
                                    }
                                    alt=""
                                    className="user-image"
                                  />
                                </td>
                                <td>
                                  <b>
                                    {item?.user_details
                                      ? `${item?.user_details?.first_name} ${item?.user_details?.last_name}`
                                      : item?.adminId
                                        ? `${item?.first_name} ${item?.last_name}`
                                        : "NA"}
                                  </b>
                                </td>
                                <td>
                                  <span className="ellips-2">
                                    {item?.title || "NA"}
                                  </span>
                                </td>
                                {/* <td>
                                {item?.user_other_details?.mobile
                                  ? `${item?.user_other_details?.countryCode}${item?.user_other_details?.mobile}`
                                  : "NA"}
                              </td> */}
                                <td>
                                  {item?.description ? item?.description : "NA"}
                                </td>

                                <td>
                                  <span className="ellips-2">
                                    {" "}
                                    {item?.tagged_skills?.length > 0
                                      ? item?.tagged_skills
                                        ?.map(
                                          (it) => it?.skill_details?.title
                                        )
                                        .join(", ")
                                      : "NA"}
                                  </span>
                                </td>
                                <td>{item?.total_comment || "0"}</td>
                                <td>{item?.total_like || "0"}</td>
                                <td>{item?.total_share || "0"}</td>
                                <td>
                                  {item?.createdAt
                                    ? moment(item?.createdAt).format(
                                      "DD MMM YYYY"
                                    )
                                    : "0"}
                                </td>

                                <td>
                                  <div
                                    className="d-flex justify-content-center action"
                                    style={{
                                      alignItems: "center",
                                    }}
                                  >
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={item?.isActive}
                                        onChange={() => {
                                          handleToggle({
                                            questionId: item.id,
                                            status: !item.isActive,
                                          });
                                        }}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <Link
                                      to={`/edit-forum/${item?.id}`}
                                      className="btn EditApointment"
                                    >
                                      <img
                                        src="assets/images/edit.png"
                                        alt=""
                                        width="20px"
                                      />
                                    </Link>
                                    <button
                                      className="btn"
                                      onClick={() => {
                                        dispatch(
                                          removeData({
                                            key: "questionDetail",
                                          })
                                        );
                                        navigate(`/forum/${item?.id}`);
                                      }}
                                    >
                                      <img
                                        src="assets/images/view.png"
                                        // className="sha-up"
                                        alt=""
                                      />
                                    </button>

                                    {/* <button
                                        className="btn"
                                        onClick={() => {
                                          //   setDelId(item.id);
                                          //   setShow(!show);
                                          //   setDelName(item?.first_name);
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                        />
                                      </button> */}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10}>No Data Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {list?.length > 0 && searchValue === "" && (
                        <div className="col-sm-12">
                          <Paginations
                            page={page}
                            total={
                              total_active_question && total_active_question
                            }
                            limit={10}
                            handlePage={(val) => {
                              setPage(val);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      tabName === "inactive"
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id="pills-inactive-skills"
                    role="tabpanel"
                    aria-labelledby="pills-inactive-skills-tab"
                  >
                    <div className="table-responsive text-center">
                      <table className="table storetable">
                        <thead className="text-center">
                          <tr>
                            <th scope="col">Profile</th>
                            <th scope="col">Name</th>
                            <th scope="col">Title</th>
                            {/* <th scope="col">Phone Number</th> */}
                            <th scope="col">Description</th>
                            <th scope="col">Tags</th>
                            <th scope="col">Replies</th>
                            <th scope="col">Views</th>
                            <th scope="col">Upvotes</th>
                            <th scope="col">Date</th>
                            <th scope="col">Actions</th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>

                        <tbody>
                          {list && list?.length > 0 ? (
                            list?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={
                                      item?.user_details?.image?.includes(
                                        "http"
                                      )
                                        ? item?.user_details?.image
                                        : item?.image
                                          ? item?.image
                                          : "assets/images/profile1.png"
                                    }
                                    alt=""
                                    className="user-image"
                                  />
                                </td>
                                <td>
                                  <b>
                                    {item?.user_details
                                      ? `${item?.user_details?.first_name} ${item?.user_details?.last_name}`
                                      : "NA"}
                                  </b>
                                </td>
                                <td>
                                  <span className="ellips-2">
                                    {item?.title || "NA"}
                                  </span>
                                </td>
                                {/* <td>
                                {item?.user_other_details?.mobile
                                  ? `${item?.user_other_details?.countryCode}${item?.user_other_details?.mobile}`
                                  : "NA"}
                              </td> */}
                                <td>
                                  {item?.description ? item?.description : "NA"}
                                </td>

                                <td>
                                  <span className="ellips-2">
                                    {" "}
                                    {item?.tagged_skills?.length > 0
                                      ? item?.tagged_skills
                                        ?.map(
                                          (it) => it?.skill_details?.title
                                        )
                                        .join(", ")
                                      : "NA"}
                                  </span>
                                </td>
                                <td>{item?.total_comment || "0"}</td>
                                <td>{item?.total_like || "0"}</td>
                                <td>{item?.total_share || "0"}</td>
                                <td>
                                  {item?.createdAt
                                    ? moment(item?.createdAt).format(
                                      "DD MMM YYYY"
                                    )
                                    : "0"}
                                </td>

                                <td>
                                  <div
                                    className="d-flex justify-content-center action"
                                    style={{
                                      alignItems: "baseline",
                                    }}
                                  >
                                    {/* <button className="btn">
                              <img
                                src="assets/images/application-rejected.png"
                                alt=""
                              />
                            </button> */}
                                    <span>
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          checked={item?.isActive}
                                          onChange={() => {
                                            handleToggle({
                                              questionId: item.id,
                                              status: !item.isActive,
                                            });
                                          }}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </span>
                                    <Link
                                      to={`/edit-forum/${item?.id}`}
                                      className="btn EditApointment"
                                    >
                                      <img
                                        src="assets/images/edit.png"
                                        alt=""
                                        width="20px"
                                      />
                                    </Link>
                                    <button
                                      className="btn"
                                      onClick={() => {
                                        dispatch(
                                          removeData({
                                            key: "questionDetail",
                                          })
                                        );
                                        navigate(`/forum/${item?.id}`);
                                      }}
                                    >
                                      <img
                                        src="assets/images/view.png"
                                        // className="sha-up"
                                        alt=""
                                      />
                                    </button>

                                    {/* <button
                                        className="btn"
                                        onClick={() => {
                                          //   setDelId(item.id);
                                          //   setShow(!show);
                                          //   setDelName(item?.first_name);
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                        />
                                      </button> */}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10}>No Data Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {list?.length > 0 && searchValue === "" && (
                        <div className="col-sm-12">
                          <Paginations
                            page={page}
                            total={
                              total_inactive_question && total_inactive_question
                            }
                            limit={10}
                            handlePage={(val) => {
                              setPage(val);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DeleteModal
    show={show}
    handleShow={handleShow}
    DelSkillName={delName}
    confirmDelete={confirmDelete}
  /> */}
    </article>
  );
}

export default Forum;
