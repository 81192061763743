import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addStudentSkillsSchema } from "../../utils/schema";
import {
  addStudentSkill,
  getAllSkillList,
  getSkillList,
  getUserDetails,
} from "../slice";
import Loader from "../component/loader/Loader";
import DeleteItems from "../component/modal/DeleteItems";

function AddStudentSkills() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalName, setModalName] = useState("");
  const [delIndex, setDelIndex] = useState("");
  const [show, setShow] = useState(false);
  const loading = useSelector((state) => state.loading);
  const specIds = useSelector((store) => store.specIds);
  console.log(specIds);
  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      // reset form with user data
      dispatch(getUserDetails({ userId: id, user_type: "student" }))
        .then((res) => {
          if (res?.payload?.code === 1) {
            let us = res?.payload?.data?.user_details;
            let newArr = us?.student_skill_details?.map((item, i) => {
              return {
                id: item?.skill_details?.id,
                name: item?.skill_details?.title,
                star: parseInt(item?.level),
                skill_type: item?.skill_type,
              };
            });

            reset({ skills: newArr });
            return us;
          }
        })
        .then((data) => {
          const careerIds = data?.student_career_details.map((item) =>
            item?.career_id.toString()
          );
          dispatch(getSkillList({ career_id: JSON.stringify(careerIds) })).then(
            (res) => {
              if (res?.payload?.code === 1) {
                let newArr = res?.payload?.data?.core_skill_list?.map(
                  (item) => {
                    return {
                      name: item?.skill_details?.title,
                      star: "",
                      id: item.skill_details?.id,
                      skill_type: item?.skill_type,
                    };
                  }
                );
                let newArr2 = res?.payload?.data?.booster_skill_list?.map(
                  (item) => {
                    return {
                      name: item?.skill_details?.title,
                      star: "",
                      id: item.skill_details?.id,
                      skill_type: item?.skill_type,
                    };
                  }
                );

                let arr = [
                  ...getValues("skills"),
                  ...newArr,
                  ...newArr2,
                ].filter(
                  (v, i, a) => a.findIndex((t) => t.name === v.name) === i
                );
                setValue("skills", arr);
              }
            }
          );
        });
    }

    return () => {
      isRendered = false;
    };
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onTouched",
    shouldFocusError: "true",
    reValidateMode: "onSubmit",
    resolver: yupResolver(addStudentSkillsSchema),
  });
  const watchSkills = watch("skills");

  console.log(watch("skills"));

  const { append, fields, remove } = useFieldArray({
    control: control,
    name: "skills",
  });
  const onSubmit = (data) => {
    // console.log(data);

    let newArr = data.skills.map((item) => {
      return {
        skill_id: item.id,
        skill_name: item?.id ? "" : item.name,
        level: item.star,
        skill_type: item?.skill_type ? item?.skill_type : "core",
      };
    });

    dispatch(addStudentSkill({ skill_data: newArr, userId: id })).then(
      (res) => {
        if (res?.payload?.code === 1) {
          toast.success(res?.payload?.message);
          navigate("/manage-student", { replace: true });
        } else {
          toast.error(res?.payload?.message);
        }
      }
    );
  };
  const handleChange = (ind, val, name) => {
    let arr = [...getValues("skills")];

    arr[ind][name] = val;
    setValue("skills", arr);
  };

  const handleDelete = () => {
    remove(delIndex);
  };

  const removeDefault = () => {
    const arr = watchSkills?.filter((it) => {
      return it?.star !== "";
    });

    setValue("skills", [...arr]);
  };
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Skills</h4>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-12 col-md-12 col-lg-12 text-end">
            <p
              className="theme-color fw-bold cursor-pointer"
              onClick={() => {
                removeDefault();
              }}
            >
              Clear Defaults
            </p>
          </div>
          <div className="col-sm-12">
            <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
              <div className="row align-items-center">
                {fields?.map((item, index) => (
                  <>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control rounded"
                          placeholder="English (Spoken)"
                          onChange={(e) => {
                            const val = e.target.value;
                            handleChange(index, val, "name");
                          }}
                          // value={getValues(`skills[${index}].name`)}
                          {...register(`skills[${index}].name`)}
                        />
                      </div>
                      <span className="text-danger">
                        {errors.skills && errors?.skills[index]?.name?.message}
                      </span>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <div className="starIcon">
                        <span className="text-center">
                          <img
                            src={
                              item.star === 1
                                ? "assets/images/Starorange.png"
                                : "assets/images/Stargray.png"
                            }
                            onClick={() => {
                              handleChange(index, 1, "star");
                            }}
                            alt=""
                          />
                          <small>Begineer</small>
                        </span>
                        <span className="text-center">
                          <img
                            src={
                              item.star === 2
                                ? "assets/images/Starorange.png"
                                : "assets/images/Stargray.png"
                            }
                            onClick={() => {
                              handleChange(index, 2, "star");
                            }}
                            alt=""
                          />
                          <small>Competent</small>
                        </span>
                        <span className="text-center">
                          <img
                            src={
                              item.star === 3
                                ? "assets/images/Starorange.png"
                                : "assets/images/Stargray.png"
                            }
                            onClick={() => {
                              handleChange(index, 3, "star");
                            }}
                            alt=""
                          />
                          <small>Proficient</small>
                        </span>
                        <span className="text-center">
                          <img
                            src={
                              item.star === 4
                                ? "assets/images/Starorange.png"
                                : "assets/images/Stargray.png"
                            }
                            onClick={() => {
                              handleChange(index, 4, "star");
                            }}
                            alt=""
                          />
                          <small>Expert</small>
                        </span>
                      </div>
                      <span className="text-danger">
                        {errors.skills && errors?.skills[index]?.star?.message}
                      </span>
                    </div>
                    {fields.length > 1 && (
                      <div className="form-group col-sm-12 col-md-12 col-lg-12">
                        <span
                          id="remove_2"
                          className="remove"
                          onClick={() => {
                            setModalName("delete");
                            handleShow();
                            setDelIndex(index);
                          }}
                        >
                          <img src="assets/images/menu_close.png" alt="" />
                        </span>
                      </div>
                    )}
                  </>
                ))}
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4"></div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <span
                    className="AddtoList btn"
                    onClick={() => {
                      append({
                        name: "",
                        star: "",
                      });
                    }}
                  >
                    {getValues("skills")?.length > 0 ? "Add More" : "Add Skill"}
                  </span>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5 mt-4 text-center">
                  <button type="submit" className="btn add-btn sign-btn">
                    {loading ? <Loader /> : "Update"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {modalName === "delete" && (
        <DeleteItems
          show={show}
          handleShow={handleShow}
          handleDelete={handleDelete}
        />
      )}
    </article>
  );
}

export default AddStudentSkills;
