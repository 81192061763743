import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../component/loader/Loader";
import { getCareerDetails } from "../slice";

function CareerPathsDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const detail = useSelector((store) => store.getCareerDetails);
  const loading = useSelector((store) => store.loading);

  const getdetail = useCallback(() => {
    dispatch(
      getCareerDetails({
        careerId: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    let isrander = false;
    if (!isrander) {
      getdetail();
    }

    return () => {
      isrander = false;
    };
  }, [getdetail]);

  if (loading) {
    return (
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          {" "}
          <Loader />
        </div>
      </article>
    );
  } else {
    return (
      <>
        <article className="col-lg-9">
          <div className="article-body store-bd-gy">
            <div className="bg-white pt-3 px-3 rad">
              <div className="row align-items-center col-rever">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <h4>{detail?.title}</h4>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                  <div className="d-flex align-items-center lg-justify-content-end">
                    <Link to="/add-career-path" className="btn add-new">
                      +
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-sm-12">
                <div className="bg-white p-3 shadow rad">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <img
                        src={
                          detail?.image?.includes("http")
                            ? detail?.image
                            : "assets/images/digital-marketing.png"
                        }
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <p className="">{detail?.description}</p>
                    </div>
                  </div>
                </div>

                {(detail?.core_skills || detail?.booster_skills) && (
                  <div className="bg-white shadow rad p-4 tags mt-5">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h5>Skills you can pursue</h5>
                        <p className="font-weight-normal">
                          Now that we know a bit about what is{" "}
                          {detail?.title
                            ?.replace("Career", "")
                            ?.replace("in", "")}
                          , let us see what are the skills that will make you a
                          successful in{" "}
                          {detail?.title
                            ?.replace("Career", "")
                            ?.replace("in", "")}
                          .
                        </p>
                        {detail?.core_skills && (
                          <>
                            <p className="mb-0">CORE SKILLS</p>
                            <div className="bg-span career-bgSpan">
                              {detail?.core_skills?.map((item, i) => {
                                return (
                                  <span className="mx-1" key={i}>
                                    {item?.skill_details?.title}
                                  </span>
                                );
                              })}
                            </div>
                          </>
                        )}
                        {detail?.booster_skills && (
                          <>
                            <p className="mb-0">BOOSTER SKILLS</p>
                            <div className="bg-span career-bgSpan">
                              {detail?.booster_skills?.map((item, i) => {
                                return (
                                  <span className="mx-1" key={i}>
                                    {item?.skill_details?.title}
                                  </span>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {detail?.effective_tools && (
                  <div className="bg-white shadow rad p-4 tags mt-5">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h5>Effective Tools</h5>

                        <p className="mb-0">
                          {detail?.effective_tools_description}
                        </p>
                        {detail?.effective_tools && (
                          <>
                            <div className="bg-span career-bgSpan">
                              {detail?.effective_tools?.map((item, i) => {
                                return (
                                  <span className="mx-1" key={i}>
                                    {item?.tool_details?.title}
                                  </span>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {detail?.certifications && (
                  <div className="bg-white shadow rad p-4 skills mt-5">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h3 className="">Popular certifications</h3>
                        <div
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html: detail?.certifications,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )}

                {detail?.career_options && (
                  <div className="bg-white shadow rad p-4 skills mt-5">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h3 className="">
                          Popular Career Paths in{" "}
                          {detail?.title
                            ?.replace("Career", "")
                            ?.replace("in", "")}
                           
                        </h3>
                        <div
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html: detail?.career_options,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </article>
      </>
    );
  }
}

export default CareerPathsDetail;
