import moment from "moment";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../component/loader/Loader";
import DeleteModal from "../component/modal/DeleteModal";
import Paginations from "../component/pagination/Pagination";
import {
  changeWithdrawalRequestStatus,
  DeleteWithdrawalRequestList,
  FetchWithdrawalRequestList,
} from "../slice";

function WithDrawal() {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [tabName, setTabName] = useState("pending");
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(0);
  const [show, setShow] = useState(false);
  const [showFor, setShowFor] = useState("");
  const [delName, setDelName] = useState("");
  const list = useSelector((store) => store.withDrawalList);
  const total_request = useSelector((store) => store.totalWithdrawalRequest);
  const [delId, setDelId] = useState("");
  const [loading, setLoading] = useState(false);

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(
      FetchWithdrawalRequestList({
        page,
        limit: 10,
        search: searchValue,
        status: status,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, page, searchValue, status]);

  useEffect(() => {
    getList();
  }, [getList]);
  const confirmDelete = () => {
    console.log(delId);
    dispatch(DeleteWithdrawalRequestList(delId)).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        setShow(!show);
        setDelId("");
        setDelName("");
        setShowFor("");
        getList();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  const handleAcceptReject = (data) => {
    dispatch(changeWithdrawalRequestStatus(data)).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        getList();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Manage Withdrawal</h4>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Name.."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </form>
                {/* <Link to="/add-student-basic-info" className="btn add-new">
                  +
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-9">
                <ul
                  className="nav nav-pills group-nav mb-3 mt-3"
                  id="pills-tab"
                >
                  <li className="nav-item">
                    <button
                      className={
                        tabName === "pending" ? "nav-link active" : "nav-link"
                      }
                      id="pills-active-skills-tab"
                      type="button"
                      onClick={() => {
                        setStatus(0);

                        setPage(1);
                        setTabName("pending");
                      }}
                    >
                      Pending
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={
                        tabName === "accepted" ? "nav-link active" : "nav-link"
                      }
                      id="pills-inactive-skills-tab"
                      onClick={() => {
                        setStatus(1);
                        setPage(1);
                        setTabName("accepted");
                      }}
                      type="button"
                    >
                      Accepted
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={
                        tabName === "rejected" ? "nav-link active" : "nav-link"
                      }
                      id="pills-inactive-skills-tab"
                      onClick={() => {
                        setStatus(2);
                        setPage(1);
                        setTabName("rejected");
                      }}
                      type="button"
                    >
                      Rejected
                    </button>
                  </li>
                  {/* <li className="nav-item">
                    <button
                      className={
                        tabName === "completed" ? "nav-link active" : "nav-link"
                      }
                      id="pills-inactive-skills-tab"
                      onClick={() => {
                        setStatus(0);
                        setPage(1);
                        setTabName("completed");
                      }}
                      type="button"
                    >
                      Completed
                    </button>
                  </li> */}
                </ul>
              </div>
              <div className="col-sm-12">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className={"tab-pane fade show active"}
                      id="pills-active-skills"
                      aria-labelledby="pills-active-skills-tab"
                    >
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">Profile</th>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Username</th>
                              <th scope="col">Price</th>
                              <th scope="col">Wallet Amount</th>

                              <th scope="col">Actions</th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>

                          <tbody>
                            {list && list?.length > 0 ? (
                              list?.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <img
                                      src={
                                        item?.user_details?.image?.includes(
                                          "http"
                                        )
                                          ? item?.user_details?.image
                                          : "assets/images/profile1.png"
                                      }
                                      alt=""
                                      className="user-image"
                                    />
                                  </td>
                                  <td>
                                    <b>
                                      {item?.user_details
                                        ? `${item?.user_details?.first_name} ${item?.user_details?.last_name}`
                                        : "NA"}
                                    </b>
                                  </td>
                                  <td>{item?.user_details?.email || "NA"}</td>
                                  <td>
                                    {" "}
                                    {item?.user_details
                                      ? `${item?.user_details?.username}`
                                      : "NA"}
                                  </td>
                                  {/* <td>
                                {item?.user_details?.mobile
                                  ? `${item?.user_details?.countryCode}${item?.user_details?.mobile}`
                                  : "NA"}
                              </td> */}
                                  <td>
                                    &#8377;
                                    {item?.amount ? "" + item?.amount : "0"}
                                  </td>
                                  <td>
                                    &#8377;
                                    {item?.user_details?.wallet_amount || "NA"}
                                  </td>

                                  <td>
                                    <div
                                      className="d-flex action"
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "baseline",
                                      }}
                                    >
                                      {/* <label className="switch ">
                                        <input
                                          type="checkbox"
                                          checked={item?.isActive}
                                          onChange={() => {
                                            handleToggle(
                                              item.id,
                                              !item.isActive
                                            );
                                          }}
                                        />
                                        <span className="slider round"></span>
                                      </label> */}
                                      {/* <Link
                                        to={`/add-student-basic-info/${item?.id}`}
                                        className="btn EditApointment"
                                      >
                                        <img
                                          src="assets/images/edit.png"
                                          alt=""
                                          width="20px"
                                        />
                                      </Link> */}
                                      {/* <button
                                        className="btn"
                                        onClick={() => {
                                            getDetails(item?.id);
                                        }}
                                      >
                                        <img
                                          src="assets/images/view.png"
                                          className="sha-up"
                                          alt=""
                                        />
                                      </button> */}
                                      {status !== 1 && (
                                        <button
                                          className="btn"
                                          onClick={() => {
                                            // getDetails(item?.id);
                                            handleAcceptReject({
                                              request_id: item?.id,
                                              status: "1",
                                            });
                                          }}
                                        >
                                          <img
                                            src="assets/images/application-viewed.png"
                                            className="sha-up"
                                            alt=""
                                          />
                                        </button>
                                      )}
                                      {status !== 2 && (
                                        <button
                                          className="btn"
                                          onClick={() => {
                                            // getDetails(item?.id);
                                            handleAcceptReject({
                                              request_id: item?.id,
                                              status: "2",
                                            });
                                          }}
                                        >
                                          <img
                                            src="assets/images/application-rejected.png"
                                            className="sha-up"
                                            alt=""
                                          />
                                        </button>
                                      )}
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          setDelId(item.id);
                                          setShow(!show);
                                          setDelName("Withdrawal Request");
                                          setShowFor("delete");
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={7}>No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {list?.length > 0 && (
                          <div className="col-sm-12">
                            <Paginations
                              page={page}
                              total={total_request && total_request}
                              handlePage={(val) => {
                                setPage(val);
                              }}
                              limit={10}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {delName && showFor === "delete" && (
        <DeleteModal
          show={show}
          handleShow={() => {
            setShow(!show);
            setDelId("");
            setDelName("");
            setShowFor("");
          }}
          DelSkillName={delName}
          confirmDelete={confirmDelete}
        />
      )}
      {/* {showFor === "detail" && (
        <RequestDetail
          show={show}
          handleShow={() => {
            setShow(!show);
          }}
          data={requestDetail}
        />
      )} */}
    </article>
  );
}

export default WithDrawal;
