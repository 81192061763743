import AddCareerPath from "../feature/careerPath/AddCareerPath";
import AddCareerPersonas from "../feature/careerPath/AddCareerPersonas";
import AddCollaboration from "../feature/careerPath/AddCollaboration";
import CareerPathsDetail from "../feature/careerPath/CareerPathsDetail";
import EditCareerPersonas from "../feature/careerPath/EditCareerPersonas";
import EditCollaboration from "../feature/careerPath/EditCollaboration";
import ManageCareerPath from "../feature/careerPath/ManageCareerPath";
import ManageCollaborations from "../feature/careerPath/ManageCollaborations";
import ManagePersonas from "../feature/careerPath/ManagePersonas";
import AddSpeciality from "../feature/component/Speciality/AddSpeciality";
import EditSpeciality from "../feature/component/Speciality/EditSpeciality";
import ManageSpeciality from "../feature/component/Speciality/ManageSpeciality";
import AddCounseller from "../feature/counseller/AddCounseller";
import ManageCounseller from "../feature/counseller/ManageCounseller";
import ManageFestivals from "../feature/festivals/festival";
import AddForum from "../feature/forum/AddForum";
import Forum from "../feature/forum/Forum";
import ForumDetail from "../feature/forum/ForumDetail";
import Opportunity from "../feature/opportunity/Opportunity";
import OpportunityDetail from "../feature/opportunity/OpportunityDetail";
import AddOpportunity from "../feature/opportunity/AddOpportunity";
import ForgetPassword from "../feature/login/ForgetPassword";
import Login from "../feature/login/Login";
import Otp from "../feature/login/Otp";
import ResetPassword from "../feature/login/ResetPassword";
import ManageMeeting from "../feature/meetings/MangeMeeting";
import AddMentor from "../feature/mentor/AddMentor";
import ManageMentor from "../feature/mentor/ManageMentor";
import WithDrawal from "../feature/refund/Withdrawal";
import ManageRequest from "../feature/request/ManageRequest";
import ChangePassword from "../feature/Setting/ChangePassword";
import AddSkill from "../feature/skills/AddSkill";
import EditSkill from "../feature/skills/EditSkill";
import ManageSkills from "../feature/skills/ManageSkills";
import AddBasicInfo from "../feature/student/AddBasicInfo";
import AddStudentCareerPath from "../feature/student/AddStudentCareerPath";
import ManageStudent from "../feature/student/ManageStudent";
import AddSubadmin from "../feature/subadmin/AddSubadmin";
import ManageSubAdmin from "../feature/subadmin/ManageSubAdmin";
import ViewSubadmin from "../feature/subadmin/ViewSubadmin";
import EditCareerPath from "./../feature/careerPath/EditCareerPaths";
import AddStudentDegree from "./../feature/student/AddStudentDegree";
import AddStudentSkills from "./../feature/student/AddStudentSkills";
import ManageCertifications from "../feature/certification/ManageCertifications";
import AddCertification from "../feature/certification/AddCertification";
import EditCertification from "../feature/certification/EditCertification";
import ViewCertification from "../feature/certification/ViewCertification";

export const authRoutes = [
  {
    path: "/auth/login",
    element: Login,
  },
  {
    path: "/auth/forget-password",
    element: ForgetPassword,
  },
  {
    path: "/auth/otp",
    element: Otp,
  },
  {
    path: "/auth/reset-password",
    element: ResetPassword,
  },
];

export const afterRoutes = [
  {
    path: "/manage-career-path",
    name: "Manage Career Paths",
    subName: "career",
    element: ManageCareerPath,
    hidden: false,
    imgInactive: "assets/images/Careerpath-balck.png",
    imgActive: "assets/images/Careerpath-blue.png",
  },
  {
    path: "/manage-certifications",
    name: "Manage Certifications",
    subName: "certifications",
    element: ManageCertifications,
    hidden: false,
    imgInactive: "assets/images/Careerpath-balck.png",
    imgActive: "assets/images/Careerpath-blue.png",
  },
  {
    path: "/manage-personas",
    name: "Manage Personas",
    subName: "careerpersons",
    element: ManagePersonas,
    imgInactive: "assets/images/Careerpath-balck.png",
    imgActive: "assets/images/Careerpath-blue.png",
    hidden: false,
  },
  {
    path: "/manage-collaborations",
    name: "Manage Collaborations",
    subName: "careercollaboration",
    element: ManageCollaborations,
    imgInactive: "assets/images/Careerpath-balck.png",
    imgActive: "assets/images/Careerpath-blue.png",
    hidden: false,
  },
  {
    path: "/manage-speciality",
    name: "Manage Speciality",
    subName: "speciality",
    element: ManageSpeciality,
    imgInactive: "assets/images/Speciality-black.png",
    imgActive: "assets/images/Speciality-blue.png",
    hidden: false,
  },
  {
    path: "/manage-meetings",
    name: "Manage Meetings",
    subName: "meetings",
    element: ManageMeeting,
    imgInactive: "assets/images/Counselor-black.png",
    imgActive: "assets/images/Counselor-blue.png",
    hidden: false,
  },

  {
    path: "/manage-skill",
    name: "Manage Skill",
    subName: "skill",
    element: ManageSkills,
    imgInactive: "assets/images/Skills-Black.png",
    imgActive: "assets/images/Skills-blue.png",
    hidden: false,
  },
  {
    path: "/manage-student",
    name: "Manage Student",
    subName: "student",
    element: ManageStudent,
    imgInactive: "assets/images/Student-black.png",
    imgActive: "assets/images/Student.png",
    hidden: false,
  },
  {
    path: "/manage-counseller",
    name: "Manage Counseller",
    subName: "counseller",
    element: ManageCounseller,
    imgInactive: "assets/images/Counselor-black.png",
    imgActive: "assets/images/Counselor-blue.png",
    hidden: false,
  },
  {
    path: "/manage-mentor",
    name: "Manage Mentors",
    subName: "mentor",
    element: ManageMentor,
    imgInactive: "assets/images/mentor-Black.png",
    imgActive: "assets/images/mentor-blue.png",
    hidden: false,
  },
  {
    path: "/manage-requests",
    name: "Manage Requests",
    subName: "requests",
    element: ManageRequest,
    imgInactive: "assets/images/Request-unselected.png",
    imgActive: "assets/images/Request.png",
    hidden: false,
  },
  {
    path: "/manage-festivals",
    name: "Manage Festivals",
    subName: "requests",
    element: ManageFestivals,
    imgInactive: "assets/images/Request-unselected.png",
    imgActive: "assets/images/Request.png",
    hidden: false,
  },

  {
    path: "/manage-forum",
    name: "Forums",
    subName: "forum",
    element: Forum,
    imgInactive: "assets/images/forum_Selected.png",
    imgActive: "assets/images/forum_Unselected.png",
    hidden: false,
  },
  {
    path: "/manage-subadmin",
    name: "Manage Sub Admins",
    subName: "subadmin",
    element: ManageSubAdmin,
    imgInactive: "assets/images/admin_unselected.png",
    imgActive: "assets/images/admin_selected.png",
    hidden: false,
  },
  {
    path: "/manage-opportunity",
    name: "Opportunities",
    subName: "opportunity",
    element: Opportunity,
    imgInactive: "assets/images/forum_Selected.png",
    imgActive: "assets/images/forum_Unselected.png",
    hidden: false,
  },
  {
    path: "/add-subadmin",
    name: "Add Sub Admins",
    subName: "subadmin",
    element: AddSubadmin,
    imgInactive: "assets/images/Forum.png",
    imgActive: "assets/images/Forum-active.png",
    hidden: true,
  },
  {
    path: "/subadmin/:id",
    name: "edit Sub Admins",
    subName: "subadmin",
    element: AddSubadmin,
    imgInactive: "assets/images/Forum.png",
    imgActive: "assets/images/Forum-active.png",
    hidden: true,
  },
  {
    path: "/view/subadmin/:id",
    name: "View Sub Admins",
    subName: "subadmin",
    element: ViewSubadmin,
    imgInactive: "assets/images/Forum.png",
    imgActive: "assets/images/Forum-active.png",
    hidden: true,
  },
  {
    path: "/career-paths-detail/:id",
    name: "Manage Career Paths Detail",
    subName: "career",
    element: CareerPathsDetail,
    hidden: true,
    imgInactive: "assets/images/Careerpath-balck.png",
    imgActive: "assets/images/Careerpath-blue.png",
  },
  {
    path: "/add-career-path",
    name: "Add Career path",
    subName: "career",
    element: AddCareerPath,
    hidden: true,
  },
  {
    path: "/add-certification",
    name: "Add Certification",
    subName: "certifications",
    element: AddCertification,
    hidden: true,
  },
  {
    path: "/certification-detail/:id",
    name: "View Certification",
    subName: "certifications",
    element: ViewCertification,
    hidden: true,
  },
  {
    path: "/edit-certification/:id",
    name: "Edit Certification",
    subName: "certifications",
    element: EditCertification,
    hidden: true,
  },
  {
    path: "/add-personas/:id",
    name: "Add Personas",
    subName: "career",
    element: AddCareerPersonas,
    hidden: true,
  },
  {
    path: "/add-collaboration/:id",
    name: "Add Collaboration",
    subName: "career",
    element: AddCollaboration,
    hidden: true,
  },
  {
    path: "/edit-personas/:id",
    name: "Edit Personas",
    subName: "career",
    element: EditCareerPersonas,
    hidden: true,
  },
  {
    path: "/edit-collaboration/:id",
    name: "Edit Collaboration",
    subName: "career",
    element: EditCollaboration,
    hidden: true,
  },
  {
    path: "/edit-career-path/:id",
    name: "Add Career path",
    subName: "career",
    element: EditCareerPath,
    hidden: true,
  },

  {
    path: "/add-speciality",
    name: "Add Speciality",
    subName: "add speciality",
    element: AddSpeciality,
    // imgInactive: "assets/images/Skills-Black.png",
    // imgActive: "assets/images/Skills-blue.png",
    hidden: true,
  },
  {
    path: "/edit-speciality/:id",
    name: "Edit Speciality",
    subName: "Edit speciality",
    element: EditSpeciality,
    // imgInactive: "assets/images/Skills-Black.png",
    // imgActive: "assets/images/Skills-blue.png",
    hidden: true,
  },

  {
    path: "/add-skill",
    name: "Add Skill",
    subName: "add skill",
    element: AddSkill,
    // imgInactive: "assets/images/Skills-Black.png",
    // imgActive: "assets/images/Skills-blue.png",
    hidden: true,
  },
  {
    path: "/edit-skill/:id",
    name: "Edit Skill",
    subName: "edit skill",
    element: EditSkill,
    // imgInactive: "assets/images/Skills-Black.png",
    // imgActive: "assets/images/Skills-blue.png",
    hidden: true,
  },

  {
    path: "/add-student-basic-info",
    name: "Add Student",
    subName: "student",
    element: AddBasicInfo,
    imgInactive: "assets/images/Student-black.png",
    imgActive: "assets/images/Student.png",
    hidden: true,
  },
  {
    path: "/add-student-basic-info/:id",
    name: "Edit Student",
    subName: "student",
    element: AddBasicInfo,
    imgInactive: "assets/images/Student-black.png",
    imgActive: "assets/images/Student.png",
    hidden: true,
  },

  {
    path: "/add-student-degree/:id",
    name: "Add Student",
    subName: "student",
    element: AddStudentDegree,
    imgInactive: "assets/images/Student-black.png",
    imgActive: "assets/images/Student.png",
    hidden: true,
  },
  {
    path: "/add-student-career-path/:id",
    name: "Add Student",
    subName: "student",
    element: AddStudentCareerPath,
    imgInactive: "assets/images/Student-black.png",
    imgActive: "assets/images/Student.png",
    hidden: true,
  },
  {
    path: "/add-student-skills/:id",
    name: "Add Student",
    subName: "student",
    element: AddStudentSkills,
    imgInactive: "assets/images/Student-black.png",
    imgActive: "assets/images/Student.png",
    hidden: true,
  },

  {
    path: "/add-counseller",
    name: "Add Counseller",
    subName: "counseller",
    element: AddCounseller,
    imgInactive: "assets/images/Counselor-black.png",
    imgActive: "assets/images/Counselor-blue.png",
    hidden: true,
  },
  {
    path: "/add-counseller/:id",
    name: "Add Counseller",
    subName: "counseller",
    element: AddCounseller,
    imgInactive: "assets/images/Counselor-black.png",
    imgActive: "assets/images/Counselor-blue.png",
    hidden: true,
  },

  {
    path: "/add-mentor",
    name: "Add Mentor",
    subName: "mentor",
    element: AddMentor,
    imgInactive: "assets/images/mentor-Black.png",
    imgActive: "assets/images/mentor-blue.png",
    hidden: true,
  },
  {
    path: "/add-mentor/:id",
    name: "Add Mentor",
    subName: "mentor",
    element: AddMentor,
    imgInactive: "assets/images/mentor-Black.png",
    imgActive: "assets/images/mentor-blue.png",
    hidden: true,
  },

  {
    path: "/change-password",
    name: "Change Password",
    subName: "change password",
    element: ChangePassword,
    // imgInactive: "assets/images/Skills-Black.png",
    // imgActive: "assets/images/Skills-blue.png",
    hidden: true,
  },

  {
    path: "/forum/:id",
    name: "Forums",
    subName: "forum",
    element: ForumDetail,
    imgInactive: "assets/images/forum_Selected.png",
    imgActive: "assets/images/forum_Unselected.png",
    hidden: true,
  },
  {
    path: "/opportunity/:id",
    name: "Opportunities",
    subName: "opportunity",
    element: OpportunityDetail,
    imgInactive: "assets/images/forum_Selected.png",
    imgActive: "assets/images/forum_Unselected.png",
    hidden: true,
  },
  {
    path: "/withdrawal",
    name: "Manage Withdrawal",
    subName: "withdrawal",
    element: WithDrawal,
    imgInactive: "assets/images/withdrawalUnSelected.png",
    imgActive: "assets/images/withdrawalSelected.png",
    hidden: false,
  },
  {
    path: "/add-forum",
    name: "Forums",
    subName: "forum",
    element: AddForum,

    hidden: true,
  },

  {
    path: "/edit-forum/:id",
    name: "Forums",
    subName: "forum",
    element: AddForum,

    hidden: true,
  },
  {
    path: "/add-opportunity",
    name: "Opportunities",
    subName: "opportunity",
    element: AddOpportunity,

    hidden: true,
  },
  {
    path: "/edit-opportunity/:id",
    name: "Opportunities",
    subName: "opportunity",
    element: AddOpportunity,

    hidden: true,
  },
];
