import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { uploadFile } from "react-s3";
import { Progress } from "react-sweet-progress";
import { toast } from "react-toastify";
import {
  addStudentBasicInfo,
  getAllCityList,
  getAllCountryList,
  getAllStateList,
  getUserDetails,
} from "../slice";
import { config } from "../../utils";
import { studentBasicInfoSchema } from "../../utils/schema";
import Loader from "../component/loader/Loader";
function AddBasicInfo() {
  const { id } = useParams();
  console.log(id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const loading = useSelector((state) => state.loading);
  const [loading, setLoading] = useState(false);
  const country = useSelector((state) => state.country);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    getValues,
    setValue,
    reset,
    watch,
    control,
  } = useForm({
    mode: "onTouched",
    shouldFocusError: "true",
    reValidateMode: "onSubmit",
    resolver: yupResolver(studentBasicInfoSchema),
  });

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      // reset form with user data
      if (id) {
        setLoading(true);
        dispatch(getUserDetails({ userId: id, user_type: "student" })).then(
          (res) => {
            if (res?.payload?.code === 1) {
              setLoading(false);
              let data = res?.payload?.data?.user_details;
              reset({
                first_name: data.first_name,
                last_name: data.last_name,
                gender: data.gender,
                email: data?.email,
                country: data.country,
                state: data.state,
                city: data.city,
                image: data.image,
                about: data.about,
                city_isocode: data.city_isocode,
                state_isocode: data.state_isocode,
              });
              getState(country?.isoCode);
            }
          }
        );
      }
    }

    return () => {
      isRendered = false;
    };
  }, [country, id, dispatch, reset]);

  const onSubmit = (data) => {
    console.log(data);
    // navigate("/student/edit-profile/2");
    dispatch(
      addStudentBasicInfo({
        first_name: data.first_name,
        last_name: data.last_name,
        gender: data.gender,
        email: data?.email,
        country: data.country,
        state: data.state,
        city: data.city,
        image: data.image,
        about: data.about,
        password: data.password,
        city_isocode: data.city_isocode,
        state_isocode: data.state_isocode,
        userId: id ? id : "",
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        console.log(res);
        toast.success(res?.payload?.message);
        // navigate("/", { replace: true });

        navigate(
          `/add-student-degree/${id ? id : res?.payload?.data?.user?.id}`
        );
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const getState = useCallback(
    (val) => {
      dispatch(getAllStateList({ countryIsoCode: val })).then((res) => {
        if (res?.payload?.code === 1) {
          let statess = res?.payload?.data?.state_list;
          setStates(res?.payload?.data?.state_list);
          let newArr = statess?.find(
            (item) => item.name === getValues("state")
          );
          console.log(newArr);
          getCity(newArr.isoCode);
        }
      });
    },
    [dispatch]
  );

  const getCity = useCallback(
    (val) => {
      dispatch(getAllCityList({ state_code: val })).then((res) => {
        if (res?.payload?.code === 1) {
          console.log(res);
          setCity(res?.payload?.data?.city_list);
        }
      });
    },
    [dispatch]
  );

  const handleImage = (e) => {
    const { files } = e.target;
    setIsActive(true);

    uploadFile(files[0], config)
      .then((data) => {
        setValue("image", data?.location);
        setTime(100);
        setIsActive(true);
        setTimeout(() => {
          setTime(0);
          setIsActive(false);
        }, 2000);
      })
      .catch((err) => console.error(err));
  };
  const handleCountry = (name, value) => {
    setValue(name, value);
  };
  useEffect(() => {
    dispatch(getAllCountryList({}));
  }, [dispatch]);

  console.log(errors);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="bg-white pt-3 px-3 rad">
            <div className="row align-items-center col-rever">
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <h4>Add Student Basic Info</h4>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-12">
              <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <div className="upload-btn-wrapper text-center">
                        <button className="btn">
                          {time > 0 && isActive ? (
                            <Progress type="circle" width={50} percent={time} />
                          ) : (
                            <img
                              src={
                                getValues("image")
                                  ? getValues("image")
                                  : "assets/images/upload-img.png"
                              }
                              alt=""
                            />
                          )}

                          {time > 0 && isActive ? null : (
                            <div className="camera-icon">
                              <i className="fa fa-camera"></i>
                            </div>
                          )}
                        </button>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            if (e.target.files.length > 0) {
                              handleImage(e);
                            }
                          }}
                        />
                      </div>
                    </div>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="First Name"
                          {...register("first_name")}
                        />
                        <img
                          src="assets/images/user.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.first_name && (
                      <span className="text-danger">
                        {errors.first_name.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Last Name"
                          {...register("last_name")}
                        />
                        <img
                          src="assets/images/user.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.last_name && (
                      <span className="text-danger">
                        {errors.last_name.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <div className="form-group">
                      <div className="input-container">
                        <label className="mb-2">Gender</label>

                        <div className="Other-radio">
                          <span className="checkbox">
                            <input
                              type="radio"
                              id="Male"
                              name="gender"
                              value="Male"
                              {...register(`gender`)}
                            />
                            <label htmlFor="Male">Male</label>
                          </span>
                          <span className="checkbox">
                            <input
                              type="radio"
                              id="Female"
                              name="gender"
                              value="Female"
                              {...register(`gender`)}
                            />
                            <label htmlFor="Female">Female</label>
                          </span>
                          <span className="checkbox">
                            <input
                              type="radio"
                              id="Other"
                              name="gender"
                              value="Other"
                              {...register(`gender`)}
                            />
                            <label htmlFor="Other">Other</label>
                          </span>
                        </div>
                        <span className="text-danger">
                          {errors && errors?.gender?.message}
                        </span>
                      </div>
                    </div>
                    {errors.title && (
                      <span className="text-danger">
                        {errors.title.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                    <div className="form-group ">
                      <label>Country</label>
                      <div className="input-container">
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <select
                              className="form-control pl-5"
                              name="country"
                              onChange={(e) => {
                                // onChange(e);
                                if (e.target.value) {
                                  let arr = e.target.value.split(",");
                                  let val = arr[0];
                                  handleCountry(e.target.name, val);
                                  getState(arr[1]);
                                }
                              }}
                            >
                              <option value="">Select Country</option>
                              <option
                                value={country?.name + "," + country?.isoCode}
                                selected={
                                  getValues("country") === country?.name
                                }
                              >
                                {country?.name}
                              </option>
                            </select>
                          )}
                          name="country"
                          value={getValues("country")}
                          control={control}
                          defaultValue={null}
                        />

                        <img
                          src="assets/images/map.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>

                    <span className="text-danger">
                      {errors && errors?.country?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                    <label>State</label>
                    <div className="form-group">
                      <div className="input-container">
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="State"
                        /> */}
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <select
                              className="form-control pl-5"
                              name="state"
                              onChange={(e) => {
                                const arr = e.target.value.split(",");
                                const val = arr[0];
                                setValue("state_isocode", arr[1]);
                                handleCountry(e.target.name, val);
                                getCity(arr[1]);
                                // setState_isocode(arr[1]);
                              }}
                            >
                              <option value="">Select state</option>

                              {states?.map((item) => {
                                return (
                                  <option
                                    value={item.name + "," + item.isoCode}
                                    selected={getValues("state") === item?.name}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                          name="state"
                          control={control}
                          defaultValue={null}
                          value={getValues("state")}
                        />
                        <img
                          src="assets/images/map.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    <span className="text-danger">
                      {errors && errors?.state?.message}
                    </span>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                    <label>City</label>
                    <div className="form-group">
                      <div className="input-container">
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="City"
                        /> */}
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <select
                              className="form-control pl-5"
                              name="city"
                              onChange={(e) => {
                                const val = e.target.value.split(",");
                                handleCountry(e.target.name, val[0]);
                                // setCity_isocode(val[1]);
                                setValue("city_isocode", val[1]);
                              }}
                            >
                              <option value="">Select city</option>

                              {city?.map((item) => {
                                return (
                                  <option
                                    value={item.name + "," + item.countryCode}
                                    selected={getValues("city") === item?.name}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                          name="city"
                          control={control}
                          value={getValues("city")}
                          defaultValue={null}
                        />
                        <img
                          src="assets/images/map.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    <span className="text-danger">
                      {errors.city && errors?.city?.message}
                    </span>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <textarea
                          className="form-control h-auto "
                          rows="5"
                          placeholder="About yourself..."
                          {...register(`about`)}
                        ></textarea>
                      </div>
                    </div>
                    {errors.about && (
                      <span className="text-danger">
                        {errors.about.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="email"
                          {...register("email")}
                        />
                        <img
                          src="assets/images/email.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="password"
                          className="form-control pl-5"
                          placeholder="Password"
                          {...register("password")}
                        />
                        <img
                          src="assets/images/password.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.password && (
                      <span className="text-danger">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                    <div className="form-group">
                      <div className="input-container">
                        <button className="btn add-btn">
                          {isSubmitted ? <Loader /> : "Next"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default AddBasicInfo;
