import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addStudentDegree,
  getAllCityList,
  getAllCountryList,
  getAllStateList,
  getUserDetails,
} from "../slice";
import { addStudentDegreeSchema } from "../../utils/schema";
import Loader from "../component/loader/Loader";
import DeleteItems from "../component/modal/DeleteItems";

function AddStudentDegree() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [delIndex, setDelIndex] = useState("");
  const loading = useSelector((state) => state.loading);
  const country = useSelector((state) => state.country);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const handleShow = () => {
    setShow(!show);
  };
  useEffect(() => {
    dispatch(getUserDetails({ userId: id, user_type: "student" })).then(
      (res) => {
        if (res?.payload?.code === 1) {
          let us = res?.payload?.data?.user_details;
          console.log(us);
          let newArr = us?.student_degree_details?.map((item, i) => {
            getState(country?.isoCode);

            return {
              college_name: item.college_name,
              degree: item.degree,
              field_of_study: item.field_of_study,
              start_year: moment(item.start_year).toDate(),
              end_year: moment(item.end_year).toDate(),
              college_city: item.college_city,
              college_state: item.college_state,
              college_country: item.college_country,
            };
          });
          console.log(newArr);

          reset({ degree: newArr });
        }
      }
    );
  }, [country]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
    shouldFocusError: "true",
    reValidateMode: "onSubmit",
    resolver: yupResolver(addStudentDegreeSchema),
  });

  const { append, fields, remove } = useFieldArray({
    control: control,
    name: "degree",
  });

  const handleDelete = () => {
    remove(delIndex);
  };

  const onSubmit = (data) => {
    const arr = [...data.degree];
    const newArr = arr.map((item) => {
      return {
        college_name: item.college_name,
        degree: item.degree,
        field_of_study: item.field_of_study,
        start_year: moment(item.start_year).format("yyyy"),
        end_year: moment(item.end_year).format("yyyy"),
        college_state: item.college_state,
        college_city: item.college_city,
        college_country: item.college_country,
      };
    });
    console.log(newArr);
    dispatch(
      addStudentDegree({ degree_data: newArr, userId: id ? id : "" })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        navigate(`/add-student-career-path/${id}`);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  const getState = useCallback(
    (val) => {
      dispatch(getAllStateList({ countryIsoCode: val })).then((res) => {
        if (res?.payload?.code === 1) {
          setStates(res?.payload?.data?.state_list);
          let statess = res?.payload?.data?.state_list;
          let arr = getValues("degree");
          arr.map((item) => {
            let newArr = statess?.find(
              (itemm) => itemm.name === item.college_state
            );
            console.log(newArr);
            getCity(newArr.isoCode);
          });
        }
      });
    },
    [dispatch]
  );

  const getCity = useCallback(
    (val) => {
      dispatch(getAllCityList({ state_code: val })).then((res) => {
        if (res?.payload?.code === 1) {
          console.log(res);
          setCity(res?.payload?.data?.city_list);
        }
      });
    },
    [dispatch]
  );
  useEffect(() => {
    dispatch(getAllCountryList({}));
  }, []);
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Add Degree</h4>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-12">
            <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
              {fields &&
                fields.map((item, index) => (
                  <div className="row align-items-center">
                    {fields?.length > 1 && (
                      <div className="form-group col-sm-12 col-md-12 col-lg-12">
                        <span
                          id="remove_2"
                          className="remove"
                          onClick={() => {
                            handleShow();
                            setDelIndex(index);
                          }}
                        >
                          <img src="assets/images/menu_close.png" alt="" />
                        </span>
                      </div>
                    )}
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <label>College Name</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Enter College Name"
                          {...register(`degree[${index}].college_name`)}
                          defaultValue={`${item.college_name}`}
                        />
                        <img
                          src="assets/images/College.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      <span className="text-danger">
                        {errors.degree &&
                          errors?.degree[index]?.college_name?.message}
                      </span>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <label>Degree</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Enter Your Degree"
                          {...register(`degree[${index}].degree`)}
                        />
                        <img
                          src="assets/images/Degree.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      <span className="text-danger">
                        {errors.degree &&
                          errors?.degree[index]?.degree?.message}
                      </span>
                    </div>
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                      <label>Field of Study</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Enter Field of Study"
                          {...register(`degree[${index}].field_of_study`)}
                        />
                        <img
                          src="assets/images/Study.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      <span className="text-danger">
                        {errors.degree &&
                          errors?.degree[index]?.field_of_study?.message}
                      </span>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label>Start Year</label>
                      <div className="position-relative">
                        {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Year"
                      /> */}

                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              className="form-control pl-5"
                              selected={value}
                              onChange={(date) => {
                                console.log(date);
                                onChange(date);
                              }}
                              placeholderText="Start Year"
                              dateFormat="yyyy"
                              showYearPicker
                            />
                          )}
                          control={control}
                          valueName="selected"
                          name={`degree[${index}].start_year`}
                          defaultValue={null}
                        />

                        <img
                          src="assets/images/calender.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      <span className="text-danger">
                        {errors.degree &&
                          errors?.degree[index]?.start_year?.message}
                      </span>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label>End Year (Expected)</label>
                      <div className="position-relative">
                        <Controller
                          render={({ field: { onChange, value, ref } }) => (
                            <DatePicker
                              name="end_year"
                              minDate={getValues(`degree[${index}].start_year`)}
                              className="form-control pl-5"
                              selected={value}
                              onChange={(date) => {
                                onChange(date);
                              }}
                              placeholderText="Start Year"
                              dateFormat="yyyy"
                              showYearPicker
                            />
                          )}
                          control={control}
                          valueName="selected"
                          name={`degree[${index}].end_year`}
                          placeholderText="End date"
                          defaultValue={null}
                        />
                        <img
                          src="assets/images/calender.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      <span className="text-danger">
                        {errors.degree &&
                          errors?.degree[index]?.end_year?.message}
                      </span>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                      <label>Country</label>
                      <div className="position-relative">
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <select
                              className="form-control pl-5"
                              name="college_country"
                              onChange={(e) => {
                                // onChange(e);
                                if (e.target.value) {
                                  let arr = e.target.value.split(",");
                                  let val = arr[0];
                                  getState(arr[1]);
                                  onChange(val);
                                }
                              }}
                              // value={getValues("country")}
                            >
                              <option value="">Select Country</option>
                              <option
                                value={country?.name + "," + country?.isoCode}
                                selected={
                                  country?.name ===
                                  getValues(`degree[${index}].college_country`)
                                }
                              >
                                {country?.name}
                              </option>
                            </select>
                          )}
                          name={`degree[${index}].college_country`}
                          control={control}
                          value={getValues(`degree[${index}].college_country`)}
                          defaultValue={null}
                        />

                        <img
                          src="assets/images/map.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      <span className="text-danger">
                        {errors.degree &&
                          errors?.degree[index]?.college_country?.message}
                      </span>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                      <label>State</label>
                      <div className="position-relative">
                        {/* <input
                      type="text"
                      className="form-control"
                      placeholder="State"
                    /> */}
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <select
                              className="form-control pl-5"
                              name="state"
                              onChange={(e) => {
                                const arr = e.target.value.split(",");
                                const val = arr[0];
                                getCity(arr[1]);
                                onChange(val);
                              }}
                            >
                              <option value="">Select state</option>

                              {states?.map((item) => {
                                return (
                                  <option
                                    value={item.name + "," + item.isoCode}
                                    selected={
                                      item?.name ===
                                      getValues(
                                        `degree[${index}].college_state`
                                      )
                                    }
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                          name={`degree[${index}].college_state`}
                          control={control}
                          defaultValue={null}
                          value={getValues(`degree[${index}].college_state`)}
                        />
                        <img
                          src="assets/images/map.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      <span className="text-danger">
                        {errors.degree &&
                          errors?.degree[index]?.college_state?.message}
                      </span>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-4 mb-4">
                      <label>City</label>
                      <div className="position-relative">
                        {/* <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                    /> */}
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <select
                              className="form-control pl-5"
                              name="college_city"
                              onChange={(e) => {
                                onChange(e);
                              }}
                            >
                              <option value="">Select city</option>

                              {city?.map((item) => {
                                return (
                                  <option
                                    value={item.name}
                                    selected={
                                      item?.name ===
                                      getValues(`degree[${index}].college_city`)
                                    }
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                          name={`degree[${index}].college_city`}
                          control={control}
                          value={getValues(`degree[${index}].college_city`)}
                          defaultValue={null}
                        />
                        <img
                          src="assets/images/map.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      <span className="text-danger">
                        {errors.degree &&
                          errors?.degree[index]?.college_city?.message}
                      </span>
                    </div>

                    {fields?.length > 1 && (
                      <div className="form-group col-sm-12 col-md-12 col-lg-12">
                        <hr />
                      </div>
                    )}
                  </div>
                ))}
              <div className="row align-items-center justify-content-center">
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <span
                    className="AddtoList btn"
                    onClick={() => {
                      append({
                        college_name: "",
                        degree: "",
                        field_of_study: "",
                        start_year: "",
                        end_year: "",
                        college_state: "",
                        college_city: "",
                        college_country: "",
                      });
                    }}
                  >
                    Add Degree
                  </span>
                </div>
                {/* <div className="form-group col-sm-12 col-md-12 col-lg-6 mb-5">
                <div className="position-relative">
                  <ProgressBar now={50} className="animated-progress" />
                  <span className="progressTooltip">
                    Get free career “COUNSELLING”
                  </span>
                </div>
              </div> */}
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-5 mt-4 text-center">
                  <button type="submit" className="btn add-btn sign-btn">
                    {loading ? <Loader /> : "Next"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <DeleteItems
        show={show}
        handleShow={handleShow}
        handleDelete={handleDelete}
      />
    </article>
  );
}

export default AddStudentDegree;
