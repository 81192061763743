import React, { Component, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "./../component/loader/Loader";
import { toast } from "react-toastify";
import { addCareer, getCareerDetails } from "../slice";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import S3FileUpload from "react-s3";
import { getAllSkillList } from "../slice.js";
import { Buffer } from "buffer";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
window.Buffer = window.Buffer || require("buffer").Buffer;

function EditCareerPath() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((store) => store.loading);
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const [skill_list, setSkill_list] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const detail = useSelector((store) => store.getCareerDetails);

  useEffect(() => {
    dispatch(getAllSkillList({})).then((res) => {
      if (res?.payload?.code === 1) {
        let data = res?.payload?.data?.skill_list;

        let newArr = data?.map((it) => {
          return {
            skill_id: it.id,
            title: it?.title,
          };
        });

        setSkill_list(newArr);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEY,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  };

  useEffect(() => {
    dispatch(getCareerDetails({ careerId: id })).then((res) => {
      console.log(res);

      let data = res?.payload?.data?.career_details;

      reset({
        image: data?.image,
        title: data?.title,
        description: data?.description,
        skill_description: data?.skill_description,
        effective_tools_description: data?.effective_tools_description,
        certifications: data?.certifications,
        career_options: data?.career_options,
        core_skills: data?.core_skills?.map((item) => {
          return {
            skill_id: item?.skill_id,
            title: item?.skill_details?.title,
          };
        }),
        booster_skills: data?.booster_skills?.map((item) => {
          return {
            skill_id: item?.skill_id,
            title: item?.skill_details?.title,
          };
        }),
        effective_tools: data?.effective_tools?.map((item) => {
          return {
            label: item?.tool_details?.title,
            value: item?.tool_details?.id,
          };
        }),
      });
    });
  }, [dispatch, id]);

  const schema = yup.object({
    title: yup.string().required("Required"),
    description: yup.string().required("Required"),
    skill_description: yup.string().required("Required"),
    image: yup.string().required("Required"),
    effective_tools_description: yup.string().required("Required"),
    certifications: yup.string().required("Required"),
    career_options: yup.string().required("Required"),
    core_skills: yup
      .array()
      .of(yup.object().shape({ skill_id: yup.string(), title: yup.string() }))
      .min(1, "Required")
      .typeError("Required"),
    booster_skills: yup
      .array()
      .of(yup.object().shape({ skill_id: yup.string(), title: yup.string() }))
      .nullable()
      .notRequired(),
    effective_tools: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().nullable().notRequired(),
          value: yup.string().nullable().notRequired(),
          __isNew__: yup.boolean(),
        })
      )
      .min(1, "Required")
      .typeError("Required"),
  });

  const {
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
    setValue,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    console.log(data);

    let arr = [...data.effective_tools];
    let effecTools = arr.map((it) => {
      return { tool_id: "", tool_name: it.label };
    });
    let arr2 = [...data?.core_skills];

    let coreSkil = arr2.map((it) => {
      return { skill_id: it.skill_id };
    });
    let arr3 = data?.booster_skills && [...data?.booster_skills];

    let boosterSkil = arr3?.map((it) => {
      return { skill_id: it.skill_id };
    });

    // {  "title":"test career",
    //   "image":"url",
    //   "description":"test career description",
    //   "skill_description":"test career skill description",
    //   "effective_tools_description":"effective_tools_description",
    //   "certifications":"certifications",
    //   "career_options":"career_options",
    //   "core_skills":[{"skill_id":"1"}],
    //   "booster_skills":[{"skill_id":"3"}],
    //   "effective_tools":[{"tool_id":"1"},{"tool_id":"","tool_name":"new tool"}],
    //   "careerId":"1"}
    dispatch(
      addCareer({
        title: data.title,
        image: data.image,
        description: data.description,
        skill_description: data?.skill_description,
        effective_tools_description: data?.effective_tools_description,
        certifications: data?.certifications,
        career_options: data?.career_options,
        core_skills: JSON.stringify(coreSkil),
        booster_skills: boosterSkil ? JSON.stringify(boosterSkil) : [],
        effective_tools: JSON.stringify(effecTools),
        careerId: id,
      })
    ).then(async (data) => {
      console.log(data);
      if (data?.payload?.code === 1) {
        toast.success(data?.payload?.message);

        navigate("/manage-career-path", {
          replace: true,
        });
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };
  console.log(errors);
  const customStyles = {
    control: (styles) => {
      return {
        ...styles,
        paddingLeft: "30px",
        marginTop: "5px",
        border: 0,
        height: "40px",
        overflowY: "scroll",
      };
    },
    indicatorsContainer: (styles) => {
      return { ...styles, display: "none" };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: `rgba(1, 92, 146, 0.2)`,
        color: "#015c92",
      };
    },
    ValueContainer: (styles) => {
      return { lineHeight: "26px" };
    },
  };

  var toolbarOptions = {
    clipboard: { matchVisual: false },
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  var formats = [
    "background",
    "bold",
    "color",

    "code",
    "italic",
    "link",
    "size",
    "strike",
    "script",
    "underline",
    "blockquote",
    "header",
    "indent",
    "list",
    "align",
    "direction",
    "code-block",
  ];

  const watchData = watch("title");

  if (!watchData) {
    return <Loader />;
  } else {
    return (
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="bg-white pt-3 px-3 rad">
            <div className="row align-items-center col-rever">
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <h4>Edit Career Path</h4>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-12">
              <form className="form-style" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group text-center">
                      <div className="upload-btn-wrapper">
                        <button className="btn">
                          {time > 0 && isActive ? (
                            <Progress type="circle" width={80} percent={time} />
                          ) : (
                            <img
                              src={
                                getValues("image")
                                  ? getValues("image")
                                  : "assets/images/upload-img.png"
                              }
                              alt=""
                            />
                          )}

                          {time > 0 && isActive ? null : (
                            <div className="camera-icon">
                              <i className="fa fa-camera"></i>
                            </div>
                          )}
                        </button>
                        <input
                          type="file"
                          // {...register("image")}
                          onChange={(e) => {
                            console.log("hello");
                            if (e.target.files.length > 0) {
                              setIsActive(true);
                              S3FileUpload.uploadFile(e.target.files[0], config)
                                .then((data) => {
                                  console.log(data.location);
                                  setValue("image", data.location);
                                  setTime(100);
                                  setIsActive(true);
                                  setTimeout(() => {
                                    setTime(0);
                                    setIsActive(false);
                                  }, 3000);
                                })
                                .catch((data) => console.log(data));
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group">
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Title"
                          {...register("title")}
                        />
                        <img
                          src="assets/images/userName.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                    </div>
                    {errors.title && (
                      <span className="text-danger">
                        {errors.title.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group form-control border-0 shadow quillCOntainer">
                        <h6 className="my-3">Descriptions</h6>
                        <Controller
                          name="description"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <ReactQuill
                              value={value}
                              style={{
                                height: "250px",
                              }}
                              modules={toolbarOptions}
                              formats={formats}
                              onChange={(content, delta, source, editor) => {
                                onChange(content);
                              }}
                              theme="snow"
                            />
                          )}
                        />
                       {errors.description && (
                          <span className="text-danger">
                            {errors.description.message}
                          </span>
                        )}
                    </div>
                    
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group form-control h-100 border-0 shadow">
                      <h6 className="my-3">Skills you can pursue</h6>
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Skill Description"
                          {...register("skill_description")}
                        />
                        <img
                          src="assets/images/userName.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {
                        <span className="text-danger">
                          {errors?.skill_description?.message}
                        </span>
                      }
                      <h6 className="my-3">Core Skills</h6>
                      <div className="input-container reactSelectContainer">
                        <Controller
                          name="core_skills"
                          control={control}
                          defaultValue={null}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              isMulti
                              onChange={(e) => {
                                onChange(e);
                              }}
                              className="form-control"
                              styles={customStyles}
                              value={value}
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.skill_id}
                              options={
                                (skill_list &&
                                  skill_list?.filter(
                                    (value, index, arr) =>
                                      arr.indexOf(value.title) === -1
                                  )) ||
                                undefined
                              }
                            />
                          )}
                        />

                        {/* <select className="form-control pl-5 form-select">
                        <option>Select Core Skills</option>
                        <option>Business Management Skills</option>
                        <option>Finance Skills.</option>
                      </select> */}
                        <img
                          src="assets/images/skills.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {errors && (
                        <span className="text-danger">
                          {errors?.core_skills?.message}
                        </span>
                      )}

                      <h6 className="my-3">Booster Skills</h6>
                      <div className="input-container reactSelectContainer">
                        <Controller
                          name="booster_skills"
                          control={control}
                          defaultValue={null}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              isMulti
                              onChange={(e) => {
                                onChange(e);
                              }}
                              className="form-control"
                              styles={customStyles}
                              value={value}
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.skill_id}
                              options={
                                (skill_list &&
                                  skill_list?.filter(
                                    (value, index, arr) =>
                                      arr.indexOf(value.title) === -1
                                  )) ||
                                undefined
                              }
                            />
                          )}
                        />
                        <img
                          src="assets/images/skills.png"
                          className="input-img"
                          alt=""
                        />
                        {errors && (
                          <span className="text-danger">
                            {errors?.booster_skills?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group form-control h-100 border-0 shadow">
                      <h6 className="my-3">Average Remuneration </h6>
                      <div className="input-container">
                        <input
                          type="text"
                          className="form-control pl-5"
                          placeholder="Average Remuneration  Description"
                          {...register("effective_tools_description")}
                        />
                        <img
                          src="assets/images/userName.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {errors.effective_tools && (
                        <span className="text-danger">
                          {errors.effective_tools_description.message}
                        </span>
                      )}
                      <h6 className="my-3">Tools</h6>
                      <div className="input-container reactSelectContainer">
                        {/* <select className="form-control pl-5 form-select">
                        <option>Select Tools</option>
                        <option>Canva</option>
                        <option>WordPress</option>
                      </select> */}
                        <Controller
                          name="effective_tools"
                          control={control}
                          defaultValue={null}
                          render={({ field: { onChange, value } }) => (
                            <CreatableSelect
                              isMulti
                              inputValue={inputValue}
                              onChange={(e) => {
                                console.log(e);
                                onChange(e);
                                setInputValue("");
                              }}
                              isClearable
                              components={{
                                DropdownIndicator: null,
                                Menu: () => null, // Remove menu
                                MenuList: () => null,
                              }}
                              styles={customStyles}
                              className="form-control"
                              placeholder="Type something and press enter..."
                              value={watch("effective_tools")}
                              options={watch("effective_tools") || undefined}
                              onInputChange={(e) => {
                                console.log(e, "Input ");
                                if (e.length > 0) {
                                  setInputValue(e);
                                }
                              }}
                              clear
                              formatCreateLabel={() => "Add "}
                              noOptionsMessage={() => "Add "}
                            />
                          )}
                        />
                        <img
                          src="assets/images/skills.png"
                          className="input-img"
                          alt=""
                        />
                      </div>
                      {errors && (
                        <span className="text-danger">
                          {errors?.effective_tools?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group form-control border-0 shadow quillCOntainer">
                      <h6 className="my-3">Certifications</h6>
                      {/* <div className="input-container"> */}
                      {/* <textarea
                        className="textarea-control rad form-control ps-3 shadow"
                        rows="7"
                        placeholder="Certifications"
                        {...register("certifications")}
                      ></textarea> */}

                      {/* </div> */}
                      <Controller
                        name="certifications"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ReactQuill
                            value={value}
                            style={{
                              height: "250px",
                            }}
                            modules={toolbarOptions}
                            formats={formats}
                            onChange={(content, delta, source, editor) => {
                              onChange(content);
                            }}
                            theme="snow"
                          />
                        )}
                      />
                      {errors.certifications && (
                        <span className="text-danger">
                          {errors.certifications.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 mb-4">
                    <div className="form-group form-control border-0 shadow quillCOntainer">
                      <h6 className="my-3">Career Options</h6>
                      {/* <div className="input-container"> */}
                      {/* <textarea
                        className="textarea-control rad form-control ps-3 shadow"
                        rows="7"
                        placeholder="Career Options"
                        {...register("career_options")}
                      ></textarea> */}

                      {/* </div> */}
                      <Controller
                        name="career_options"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ReactQuill
                            value={value || ""}
                            style={{
                              height: "250px",
                            }}
                            modules={toolbarOptions}
                            formats={formats}
                            onChange={(content, delta, source, editor) => {
                              onChange(content);
                            }}
                            theme="snow"
                          />
                          // <Editor />
                        )}
                      />
                      {errors.career_options && (
                        <span className="text-danger">
                          {errors.career_options.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 text-center">
                    <button type="submit" className="btn btn-save">
                      {loading ? <Loader /> : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default EditCareerPath;
