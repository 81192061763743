import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./../component/loader/Loader";
import { toast } from "react-toastify";
import {  addCareerCollaboration } from "../slice";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import S3FileUpload from "react-s3";

import "react-quill/dist/quill.snow.css";
import { config,  } from "./../../utils/index";
window.Buffer = window.Buffer || require("buffer").Buffer;



function AddCollaboration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log('career id ', id);
  const loading = useSelector((store) => store.loading);
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const [collaborationTitle, setCollaborationTitle] = useState("");
  const [careerImage, setCareerImage] = useState("");
 

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);



  const handleSubmit = () => {
    console.log("addCareerCollaboration");
    if(!careerImage){
      console.log("personasTitle ", careerImage);
      toast.error("Image required")
     }else if(!collaborationTitle){
      console.log("personasTitle ", collaborationTitle);
      toast.error("Title required")
     }else{
      dispatch(
        addCareerCollaboration({
          title: collaborationTitle,
          image: careerImage,
          career_id: id
        })
      ).then(async (data) => {
        console.log(data);
        if (data?.payload?.code === 1) {
          toast.success(data?.payload?.message);
  
          navigate("/manage-career-path", {
            replace: true,
          });
        } else {
          toast.error(data?.payload?.message);
        }
      });
     }
    
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Add New Career Collaboration</h4>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-12">
            <form className="form-style" 
              // onSubmit={handleSubmit(onSubmit)}
            >
              <div className="row">
                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group text-center">
                    <div className="upload-btn-wrapper">
                      <button className="btn">
                        {time > 0 && isActive ? (
                          <Progress type="circle" width={80} percent={time} />
                        ) : (
                          <img
                            src={
                              careerImage.includes("https")
                                ? careerImage
                                : "assets/images/upload-img.png"
                            }
                            alt=""
                          />
                        )}

                        {time > 0 && isActive ? null : (
                          <div className="camera-icon">
                            <i className="fa fa-camera"></i>
                          </div>
                        )}
                      </button>
                      <input
                        type="file"
                        // {...register("image")}
                        onChange={(e) => {
                          console.log("hello");
                          if (e.target.files.length > 0) {
                            setIsActive(true);
                            S3FileUpload.uploadFile(e.target.files[0], config)
                              .then((data) => {
                                console.log(data.location);
                                setCareerImage(data.location);
                                setTime(100);
                                setIsActive(true);
                                setTimeout(() => {
                                  setTime(0);
                                  setIsActive(false);
                                }, 3000);
                              })
                              .catch((data) => console.log(data));
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Title"
                        value={collaborationTitle}
                        onChange={(e)=>{
                          setCollaborationTitle(e.target.value)
                        }}
                      />
                      <img
                        src="assets/images/userName.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                  </div>
                  
                </div>
                
                
                <div className="col-sm-12 col-md-12 text-center">
                  <button 
                    type="submit"
                    className="btn btn-save"
                    onClick={handleSubmit}
                  >
                    {loading ? <Loader /> : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </article>
  );
}

export default AddCollaboration;
