import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import Loader from "../component/loader/Loader";
import {
  addAdminResponse,
  removeRequest,
  requestDetails,
  requestList,
} from "../slice";
import Paginations from "./../component/pagination/Pagination";
import moment from "moment";
import RequestDetail from "./RequestDetail";
import DeleteModal from "../component/modal/DeleteModal";
function ManageRequest() {
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState("active");
  const [searchValue, setSearchValue] = useState("");
  const list = useSelector((store) => store.requestLists);
  const total_request = useSelector((store) => store.total_request);
  const total_active_request = useSelector((store) => store.total_active_request);
  const total_inactive_request = useSelector((store) => store.total_inactive_request);
  const requestDetail = useSelector((store) => store.requestDetail);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(1);
  const [show, setShow] = useState(false);
  const [showFor, setShowFor] = useState("");
  const [delName, setDelName] = useState("");

  const [delId, setDelId] = useState("");

  const confirmDelete = () => {
    dispatch(removeRequest({request_id: delId})).then((res) => {
      if(res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const getList = useCallback(() => {
    setLoading(true);

    dispatch(requestList({
      page: page,
      limit: 10,
      // status,
      // search: searchValue,
    })).then(() => {
      setLoading(false);
    });
  }, [dispatch, page]);

  useEffect(() => {
    getList();
  }, [getList]);

  const getDetails = (id) => {
    dispatch(requestDetails({request_id: id})).then((res) => {
      if(res?.payload?.code === 1) {
        setShowFor("detail");
        setShow(!show);
      }
    });
  };

  const handleToggle = (id, stat) => {
    dispatch(addAdminResponse({request_id: id, status: stat})).then((res) => {
      if(res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
        getList();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  console.log("total_request ", total_request);
  console.log("total_active_request ", total_active_request);
  console.log("total_inactive_request ", total_inactive_request);
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="bg-white pt-3 px-3 rad">
          <div className="row align-items-center col-rever">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <h4>Manage Requests</h4>
            </div>
            {/* <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <form className="has-search">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Student Name.."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </form>
                <Link to="/add-student-basic-info" className="btn add-new">
                  +
                </Link>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-9">
                <ul
                  className="nav nav-pills group-nav mb-3 mt-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "active" ? "nav-link active" : "nav-link"
                      }
                      id="pills-active-skills-tab"
                      type="button"
                      role="tab"
                      aria-controls="pills-active-skills"
                      aria-selected="true"
                      onClick={() => {
                        setStatus(1);
                        setPage(1);
                        setTabName("active");
                      }}
                    >
                      active
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={
                        tabName === "inactive" ? "nav-link active" : "nav-link"
                      }
                      id="pills-inactive-skills-tab"
                      onClick={() => {
                        setStatus(0);
                        setPage(1);
                        setTabName("inactive");
                      }}
                      type="button"
                      role="tab"
                      aria-controls="pills-inactive-skills"
                      aria-selected="false"
                    >
                      inactive
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-sm-12">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className={
                        tabName === "active"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="pills-active-skills"
                      role="tabpanel"
                      aria-labelledby="pills-active-skills-tab"
                    >
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">Profile</th>
                              <th scope="col">Name</th>
                              <th scope="col">Service Type</th>
                              <th scope="col">Counseller/Mentor Name</th>
                              <th scope="col">Price</th>
                              <th scope="col">Date</th>

                              <th scope="col">Actions</th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>

                          <tbody>
                            {list && list?.length > 0 ? (
                              list?.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <img
                                      src={
                                        item?.studentDetails?.image?.includes(
                                          "http"
                                        )
                                          ? item?.studentDetails?.image
                                          : "assets/images/profile1.png"
                                      }
                                      alt=""
                                      className="user-image"
                                    />
                                  </td>
                                  <td>
                                    <b>
                                      {item?.studentDetails
                                        ? `${item?.studentDetails?.first_name} ${item?.studentDetails?.last_name}`
                                        : "NA"}
                                    </b>
                                  </td>
                                  <td>{item?.serviceDetails?.title || "NA"}</td>
                                  <td>
                                    {" "}
                                    {item?.userDetails
                                      ? `${item?.userDetails?.first_name} ${item?.userDetails?.last_name}`
                                      : "NA"}
                                  </td>
                                  {/* <td>
                                {item?.user_other_details?.mobile
                                  ? `${item?.user_other_details?.countryCode}${item?.user_other_details?.mobile}`
                                  : "NA"}
                              </td> */}
                                  <td>{item?.price ? item?.price : "NA"}</td>
                                  <td>
                                    {item?.date
                                      ? moment(item?.date, "YYYY-MM-DD").format(
                                        "DD MMM YYYY"
                                      )
                                      : "NA"}
                                  </td>

                                  <td>
                                    <div
                                      className="d-flex action"
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "baseline",
                                      }}
                                    >
                                      {/* <label className="switch ">
                                        <input
                                          type="checkbox"
                                          checked={item?.isActive}
                                          onChange={() => {
                                            handleToggle(
                                              item.id,
                                              !item.isActive
                                            );
                                          }}
                                        />
                                        <span className="slider round"></span>
                                      </label> */}

                                      {/* <Link
                                        to={`/add-student-basic-info/${item?.id}`}
                                        className="btn EditApointment"
                                      >
                                        <img
                                          src="assets/images/edit.png"
                                          alt=""
                                          width="20px"
                                        />
                                      </Link> */}
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          getDetails(item?.id);
                                        }}
                                      >
                                        <img
                                          src="assets/images/view.png"
                                          className="sha-up"
                                          alt=""
                                        />
                                      </button>
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          setDelId(item.id);
                                          setShow(!show);
                                          setDelName(
                                            item?.studentDetails?.first_name +
                                            " " +
                                            item?.studentDetails?.last_name
                                          );
                                          setShowFor("delete");
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={3}>No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {list?.length > 0 && (
                          <div className="col-sm-12">
                            <Paginations
                              page={page}
                              total={total_active_request && total_active_request}
                              handlePage={(val) => {
                                console.log("inside manage request page", val);
                                setPage(val);
                              }}
                              limit={10}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        tabName === "inactive"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="pills-inactive-skills"
                      role="tabpanel"
                      aria-labelledby="pills-inactive-skills-tab"
                    >
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">Profile</th>
                              <th scope="col">Name</th>
                              <th scope="col">Service Type</th>
                              {/* <th scope="col">Phone Number</th> */}
                              <th scope="col">Price</th>
                              <th scope="col">Date</th>
                              <th scope="col">Actions</th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>

                          <tbody>
                            {list && list?.length > 0 ? (
                              list?.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <img
                                      src={
                                        item?.studentDetails?.image?.includes(
                                          "http"
                                        )
                                          ? item?.studentDetails?.image
                                          : "assets/images/profile1.png"
                                      }
                                      alt=""
                                      className="user-image"
                                    />
                                  </td>
                                  <td>
                                    <b>
                                      {item?.studentDetails
                                        ? `${item?.studentDetails?.first_name} ${item?.studentDetails?.last_name}`
                                        : "NA"}
                                    </b>
                                  </td>
                                  <td>{item?.serviceDetails?.title || "NA"}</td>
                                  {/* <td>
                                {item?.user_other_details?.mobile
                                  ? `${item?.user_other_details?.countryCode}${item?.user_other_details?.mobile}`
                                  : "NA"}
                              </td> */}
                                  <td>{item?.price ? item?.price : "NA"}</td>
                                  <td>
                                    {item?.date
                                      ? moment(item?.date, "YYYY-MM-DD").format(
                                        "DD MMM YYYY"
                                      )
                                      : "NA"}
                                  </td>

                                  <td>
                                    <div
                                      className="d-flex justify-content-center action"
                                      style={{
                                        alignItems: "baseline",
                                      }}
                                    >
                                      {/* <button className="btn">
                              <img
                                src="assets/images/application-rejected.png"
                                alt=""
                              />
                            </button> */}
                                      {/* <span>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={item?.isActive}
                                            onChange={() => {
                                              //   handleToggle(
                                              //     item.id,
                                              //     !item.isActive
                                              //   );
                                            }}
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      </span> */}
                                      {/* <Link
                                        to={`/add-student-basic-info/${item?.id}`}
                                        className="btn EditApointment"
                                      >
                                        <img
                                          src="assets/images/edit.png"
                                          alt=""
                                          width="20px"
                                        />
                                      </Link> */}
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          //   setDelId(item.id);
                                          //   setShow(!show);
                                          //   setDelName(item?.first_name);
                                        }}
                                      >
                                        <img
                                          src="assets/images/delete-sm.png"
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={3}>No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {list?.length > 0 && (
                          <div className="col-sm-12">
                            <Paginations
                              page={page}
                              total={total_inactive_request && total_inactive_request}
                              handlePage={(val) => {
                                setPage(val);
                              }}
                              limit={10}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {delName && showFor === "delete" && (
        <DeleteModal
          show={show}
          handleShow={() => {
            setShow(!show);
            setDelId("");
            setDelName("");
            setShowFor("");
          }}
          DelSkillName={delName}
          confirmDelete={confirmDelete}
        />
      )}
      {showFor === "detail" && (
        <RequestDetail
          show={show}
          handleShow={() => {
            setShow(!show);
          }}
          data={requestDetail}
        />
      )}
    </article>
  );
}

export default ManageRequest;
