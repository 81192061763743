import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useDispatch} from 'react-redux';
import {addFestivalFormData} from '../slice';
import "./festivalPopupForm.jsx";

function CreateFestivalForm({show, handleClose, handleShow, getList}) {

	const dispatch = useDispatch();
	let adminId = JSON.parse(localStorage.getItem("adminId"));
	// getuserId = JSON.parse(getuserId)

	console.log("adminId ", adminId);

	const [festivalFormData, setFestivalFormData] = useState({
		festivalName: "",
		speakerName:"",
		date: "",
		startTime: "",
		endTime: "",
		userId: adminId
	})


	const handleChangeFestiovalData = (event) => {
		const {name, value} = event.target;
		setFestivalFormData({...festivalFormData, [name]: value})
	}


	const handleSubmition = (e) => {
		e.preventDefault()
		handleClose();
		dispatch(addFestivalFormData({
			festivalName: festivalFormData?.festivalName,
			speakerName: festivalFormData?.speakerName,
			date: festivalFormData?.date,
			startTime: festivalFormData?.startTime,
			endTime: festivalFormData?.endTime,
			userId: festivalFormData?.userId
		})).then(res => {
			getList();
			console.log(res, "submit festival list")
		}).catch(err => {
			console.log(err)
		})

	}


	return (
		<>


			<Modal show={show} onHide={handleClose} centered >

				<Modal.Body className="modal-container p-4">
					<h3 style={{borderBottom: " 1px solid black"}}>
						Create Festival
					</h3>
					<form onSubmit={handleSubmition}  >

						<label htmlFor="speakerName" className='festival-name-label'>Speaker Name: </label>
						<br />
						<input type="text" name="speakerName" id="speakerName"
							value={festivalFormData.speakerName}
							onChange={(event) => {handleChangeFestiovalData(event)}}
							required />
						<br />
						<label htmlFor="festivalName" className='festival-name-label'>Festival Name: </label>
						<br />
						<input type="text" name="festivalName" id="festivalName"
							value={festivalFormData.festivalName}
							onChange={(event) => {handleChangeFestiovalData(event)}}
							required />
						<br />


						<label htmlFor="date" className='festival-date'>Date:  </label>
						<br />
						<input type="date" name="date" id="date_festival"
							value={festivalFormData.date}
							onChange={(event) => {handleChangeFestiovalData(event)}}
							required />
						<br />


						<label for="time" className='festival-time'> Start Time: </label>
						<br />
						<input className="time_festival" type="time" name="startTime" step="2"
							value={festivalFormData.startTime}
							onChange={(event) => {handleChangeFestiovalData(event)}}
							required />
						<br />

						<label for="time" className='festival-time'>End Time: </label>
						<br />
						<input className="time_festival" type="time" name="endTime" step="2"
							value={festivalFormData.endTime}
							onChange={(event) => {handleChangeFestiovalData(event)}}
							required />
						<br />

						<div className='festival-add-btn' >
							<button type='submit'>
								Add
							</button>
						</div>

					</form>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default CreateFestivalForm;